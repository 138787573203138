import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Sidebar from "../Sidebar/sidebar";
import Dashboard from "../Pages/Dashboard";
import Trade from "../Pages/Trade";
import Future from "../Pages/Future";
import FutureDemo from "../Pages/FutureDemo";
import Graph from "../Pages/Graph";
import Withdraw from "../Pages/Withdraw";
import Deposit from "../Pages/Deposit";
import FutureRanking from "../Pages/FutureRanking";
import FutureDemoRanking from "../Pages/FutureDemoRanking";
import TradingViewInfo from "../Pages/TradingViewInfo";
import Campaign50 from "../Pages/Campaign50";
import FutureDemoCompetition10000 from "../Pages/FutureDemoCompetition10000";
import Terms from "../Pages/Terms";
import Privacy from "../Pages/Privacy";
import AboutUs from "../Pages/AboutUs";
import RiskWarning from "../Pages/RiskWarning";
import SupportDisableAccount from "../Pages/SupportDisableAccount";
import Fee from "../Pages/Fee";
import ReferenceRules from "../Pages/ReferenceRules";
import ChangePhone from "../Pages/ChangePhone";
import ChangePassword from "../Pages/ChangePassword";
import DeleteAccount from "../Pages/DeleteAccount";
import Verification from "../Pages/Verification";
import Profile from "../Pages/Profile";
import Support from "../Pages/Support";
import Page404 from "../Pages/Page404";
import Login from "../Pages/Login";
import ProtectedRoute from "./ProtectedRoute";
import "@fontsource/manrope";
import "../global.css";
import GlobalTop from "../Pages/Section-GlobalTop";
import Footer from "../Pages/Footer";
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const MainRouter = () => {

  const globalTitle = "KPRICE";

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const location = useLocation();
  const query = useQuery();
  const [tradePageTitle, setTradePageTitle] = useState("Trade");
  const [futurePageTitle, setFuturePageTitle] = useState("Future");
  const [futureDemoPageTitle, setFutureDemoPageTitle] = useState("Future Demo");

  useEffect(() => {//link değişirse
    const currentMarket = query.get("market");
    if(currentMarket){
      setTradePageTitle(`${currentMarket} | Trade`);
      setFuturePageTitle(`${currentMarket} | Future`);
      setFutureDemoPageTitle(`${currentMarket} | Future Demo`);
    }
    else{
      setTradePageTitle("Trade");
      setFuturePageTitle("Future");
      setFutureDemoPageTitle("Future Demo");
    }
  }, [location.search]);

  function RouteLayout({ headTitle, pageTitle, description }) {
    return (
      <>
        <Helmet>
          <title>{headTitle != "" ? `${headTitle} - ${globalTitle}` : globalTitle}</title>
          <meta name="description" content={description} />
        </Helmet>
        <Sidebar />
        <GlobalTop title={pageTitle} />
      </>
    );
  }

  return (
    <Routes>
      <Route path="/" element={
        <>
          <RouteLayout headTitle="" pageTitle="Dashboard" description="" />
          <Dashboard />
          <Footer page="Dashboard" />
        </>
      } />
      <Route path="/trade" element={
        <>
          <RouteLayout headTitle={tradePageTitle} pageTitle="Trade" description="" />
          <Trade />
          <Footer page="Trade" />
        </>
      } />
      <Route path="/future" element={
        <ProtectedRoute>
          <RouteLayout headTitle={futurePageTitle} pageTitle="Future" description="" />
          <Future />
          <Footer page="Future" />
        </ProtectedRoute>
      } />
      <Route path="/future-demo" element={
        <ProtectedRoute>
          <RouteLayout headTitle={futureDemoPageTitle} pageTitle="Future Demo" description="" />
          <FutureDemo />
          <Footer page="Future Demo" />
        </ProtectedRoute>
      } />
      <Route path="/graph" element={
        <>
          <Graph />
        </>
      } />
      <Route path="/withdraw" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Withdraw" pageTitle="Withdraw" description="" />
          <Withdraw />
          <Footer page="Withdraw" />
        </ProtectedRoute>
      } />
      <Route path="/deposit" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Deposit" pageTitle="Deposit" description="" />
          <Deposit />
          <Footer page="Deposit" />
        </ProtectedRoute>
      } />
      <Route path="/future-ranking" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Future Ranking" pageTitle="Future Ranking" description="" />
          <FutureRanking />
          <Footer page="Future Ranking" />
        </ProtectedRoute>
      } />
      <Route path="/future-demo-ranking" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Competition Stats" pageTitle="Competition Stats" description="" />
          <FutureDemoRanking />
          <Footer page="Competition Stats" />
        </ProtectedRoute>
      } />
      <Route path="/profile/security/change-phone" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Change Phone" pageTitle="Change Phone" description="" />
          <ChangePhone />
          <Footer page="Change Phone" />
        </ProtectedRoute>
      } />
      <Route path="/change-password" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Change Password" pageTitle="Change Password" description="" />
          <ChangePassword />
          <Footer page="Change Password" />
        </ProtectedRoute>
      } />
      <Route path="/profile/security/delete-account" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Delete Account" pageTitle="Delete Account" description="" />
          <DeleteAccount />
          <Footer page="Delete Account" />
        </ProtectedRoute>
      } />
      <Route path="/verification/*" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Verification" pageTitle="Verification" description="" />
          <Verification />
          <Footer page="Verification" />
        </ProtectedRoute>
      } />
      <Route path="/tradingview" element={
        <>
          <RouteLayout headTitle="TradingView" pageTitle="TradingView" description="" />
          <TradingViewInfo />
          <Footer page="TradingView" />
        </>
      } />
      <Route path="/campaign50" element={
        <>
          <RouteLayout headTitle="Campaign FUTURE50" pageTitle="Campaign FUTURE50" description="" />
          <Campaign50 />
          <Footer page="Campaign FUTURE50" />
        </>
      } />
      <Route path="/future-demo-competition-10000" element={
        <>
          <RouteLayout headTitle="Demo Future Trading Competition" pageTitle="Demo Future Trading Competition" description="" />
          <FutureDemoCompetition10000 />
          <Footer page="Demo Future Trading Competition" />
        </>
      } />
      <Route path="/fee" element={
        <>
          <RouteLayout headTitle="Fee" pageTitle="Fee" description="" />
          <Fee />
          <Footer page="Fee" />
        </>
      } />
      <Route path="/about-us" element={
        <>
          <RouteLayout headTitle="About Us" pageTitle="About Us" description="" />
          <AboutUs />
          <Footer page="About Us" />
        </>
      } />
      <Route path="/reference-rules" element={
        <>
          <RouteLayout headTitle="Reference Rules" pageTitle="Reference Rules" description="" />
          <ReferenceRules />
          <Footer page="Reference Rules" />
        </>
      } />
      <Route path="/risk-warning" element={
        <>
          <RouteLayout headTitle="Risk Warning" pageTitle="Risk Warning" description="" />
          <RiskWarning />
          <Footer page="Risk Warning" />
        </>
      } />
      <Route path="/support/disable-account" element={
        <>
          <RouteLayout headTitle="Disable Account" pageTitle="Disable Account" description="" />
          <SupportDisableAccount />
          <Footer page="Disable Account" />
        </>
      } />
      <Route path="/terms" element={
        <>
          <RouteLayout headTitle="Terms of Use" pageTitle="Terms of Use" description="" />
          <Terms />
          <Footer page="Terms of Use" />
        </>
      } />
      <Route path="/privacy" element={
        <>
          <RouteLayout headTitle="Privacy Policy" pageTitle="Privacy Policy" description="" />
          <Privacy />
          <Footer page="Privacy Policy" />
        </>
      } />
      <Route path="/login" element={
        <>
          <Helmet>
            <title>{globalTitle}</title>
            <meta name="description" content="" />
          </Helmet>
          <Login pageType="login" />
        </>
      } />
      <Route path="/register" element={
        <>
          <Helmet>
            <title>{globalTitle}</title>
            <meta name="description" content="" />
          </Helmet>
          <Login pageType="register" />
        </>
      } />
      <Route path="/support" element={
        <>
          <RouteLayout headTitle="Support" pageTitle="Support" description="" />
          <Support />
          <Footer page="Support" />
        </>
      } />
      <Route path="/profile/*" element={
        <ProtectedRoute>
          <RouteLayout headTitle="Profile" pageTitle="Profile" description="" />
          <Profile />
          <Footer page="Profile" />
        </ProtectedRoute>
      } />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );

};

export default MainRouter;
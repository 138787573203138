import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { formatValue6, getValueWithShowPlaces, getMinAndStepWithShowPlaces } from '../Lib/calculationFunctions';
import Loading from "../components/Global/Loading";
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import Decimal from 'decimal.js';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const SectionLoadFundsPopupContent = ({ setBalanceValue, setTotalSuretyValue, setCreditValue, setDemoFutureCompetitionCodeValue, closeModal }) => {
    
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const showPlacesPrice = currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places ? currenciesData["USDT"].show_places : null;

    const userInfo = useSelector((state) => state.kprice.userInfo.data);
    const futureProcesses = useSelector((state) => state.kprice.demoFutureProcesses.data);
    const futureProfiles = useSelector((state) => state.kprice.demoFutureProfiles.data);
    const demoFutureActiveCompetition = useSelector((state) => state.kprice.demoFutureActiveCompetition.data);

    const [activeFutureProcessesCount, setActiveFutureProcessesCount] = useState(0);
    const [demoFutureCompetitionCode, setDemoFutureCompetitionCode] = useState("");
    const [balance, setBalance] = useState(0);
    const [totalSurety, setTotalSurety] = useState(0);
    const [credit, setCredit] = useState(0);

    const maxValue = 100000;
    const [amountValue, setAmountValue] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {//amount değeri değiştikçe kontrol edilecek
        if(amountValue != ""){
            if(Number(amountValue) >= Number(maxValue)){
                setAmountValue(getValueWithShowPlaces(showPlacesPrice, maxValue));
            }
            else if(amountValue == "00"){
                setAmountValue("0");
            }
            else if(Number(amountValue) < 0 || amountValue == "-0"){
                setAmountValue("");
            }
            else{
                setAmountValue(getValueWithShowPlaces(showPlacesPrice, amountValue));
            }
        }
    }, [amountValue, showPlacesPrice]);

    useEffect(() => {
        setActiveFutureProcessesCount(futureProcesses && futureProcesses.activeProcesses ? futureProcesses.activeProcesses.length : 0);
    }, [futureProcesses]);

    useEffect(() => {
        setDemoFutureCompetitionCode(demoFutureActiveCompetition && demoFutureActiveCompetition.active_competition ? demoFutureActiveCompetition.active_competition : "");
    }, [demoFutureActiveCompetition]);
    
    useEffect(() => {
        if(futureProcesses && futureProcesses.activeProcesses && futureProfiles){
            let tempTotalBalance = futureProfiles.balance ? futureProfiles.balance : 0;
            let tempTotalSurety = 0;
            let tempCredit = futureProfiles.credit ? futureProfiles.credit : 0;
            
            futureProcesses.activeProcesses.map((item, index) => {
                tempTotalSurety = new Decimal(tempTotalSurety).plus(new Decimal(item.available_surety));
            });

            setTotalSurety(tempTotalSurety);
            setBalance(tempTotalBalance);
            setCredit(tempCredit);
        }
    }, [futureProcesses, futureProfiles]);

    const loadFundsConfirm = async () => {
        if(activeFutureProcessesCount == 0 && balance == 0 && totalSurety == 0 && credit == 0 && demoFutureCompetitionCode == "" && userInfo && userInfo.future_permission && userInfo.future_permission == "True"){
            setSubmitLoading(true);

            let updateData = {
                update_type: "load_funds_demo_account",
                currency: "USDT",
                count: amountValue
            }

            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            axios.post('https://api.kpricemarket.com/demo/futuredemo', 
                JSON.stringify(updateData),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {
                if(response.status == "200"){
                    if(response && response.data && response.data.data){
                        if(response.data.data.total_surety){setTotalSuretyValue(Number(response.data.data.total_surety));}
                        if(response.data.data.balance){setBalanceValue(Number(response.data.data.balance));}
                        if(response.data.data.credit){setCreditValue(Number(response.data.data.credit));}
                        if(response.data.data.active_competition){setDemoFutureCompetitionCodeValue(response.data.data.active_competition);}
                    }
                    setAmountValue("");
                    toast.success("Loading funds successfully completed!");
                    closeModal();
                }
                else{
                    toast.error(`There was a problem during the loading funds!${response.data.message ? ` (${response.data.message})` : ""}`);
                }
                setSubmitLoading(false);
            })
            .catch(function (error) {
                toast.error(`There was a problem during the loading funds!${error.response.data.error ? ` (${error.response.data.error})` : ""}`);
                setSubmitLoading(false);
            });
        }
    }

    return(
        <div className="section-walletlist-dialog-transfer load-funds">
            <div className="transfer2">
                <label>Coin</label>
                <div className="coin-info">
                    <img src={currenciesData["USDT"] && currenciesData["USDT"].picture_url ? currenciesData["USDT"].picture_url : undefined} />
                    <span className="info1">{currenciesData["USDT"] && currenciesData["USDT"].currency ? currenciesData["USDT"].currency : "NaN"}</span>
                    <span className="info2">{currenciesData["USDT"] && currenciesData["USDT"].currency_name ? currenciesData["USDT"].currency_name : "NaN"}</span>
                </div>
                <div className="amount-title">
                    <label>Amount</label>
                </div>
                <input type="number" step={getMinAndStepWithShowPlaces(showPlacesPrice)} min={getMinAndStepWithShowPlaces(showPlacesPrice)} value={amountValue} onChange={(event) => {setAmountValue(event.target.value);}} />
                <div className={maxValue && amountValue != "" && getValueWithShowPlaces(showPlacesPrice, maxValue) == amountValue ? "amount-max-button active" : "amount-max-button"} onClick={() => setAmountValue(maxValue ? maxValue : "")}>MAX</div>
                <div className="section-tradebuysell-inner-range load-funds">
                    <Slider min={0} max={maxValue} value={amountValue} onChange={(value) => setAmountValue(value)} />
                </div>
                <div className="confirm-button">
                    <button type="button" className={amountValue != "" && amountValue != "0" && !submitLoading && activeFutureProcessesCount == 0 && balance == 0 && totalSurety == 0 && credit == 0 && demoFutureCompetitionCode == "" && userInfo && userInfo.future_permission && userInfo.future_permission == "True" ? "active" : undefined} disabled={amountValue != "" && amountValue != "0" && !submitLoading && activeFutureProcessesCount == 0 && balance == 0 && totalSurety == 0 && credit == 0 && demoFutureCompetitionCode == "" && userInfo && userInfo.future_permission && userInfo.future_permission == "True" ? false : true} onClick={() => loadFundsConfirm()}>{submitLoading ? <Loading /> : "Load Funds"}</button>
                </div>
            </div>
        </div>
    )
    
}

export default SectionLoadFundsPopupContent;
import {
  requestActions,
  createRequestSaga,
  createAppSyncSubscriptionThunk,
  createAppSyncSubscriptionThunkIAM,
  createChangeOptionSaga,
  changeOptionActions,
  unsubscribeFunctions
} from "../Lib/asyncUtil";
import { userInfoUtils, notificationsUtils, productsDataUtils, orderHistoryUtils, futureOrderHistoryUtils, futureProcessesUtils, futureProfilesUtils, demoFutureOrderHistoryUtils, demoFutureProcessesUtils, demoFutureProfilesUtils, demoFutureActiveCompetitionUtils, walletUtils, withdrawUtils, depositUtils, favoritesUtils, withdrawHistoryUtils, depositHistoryUtils, transferHistoryUtils, futureStatsUtils, futureDemoStatsUtils } from "../Lib/utils";
import { kpriceApi } from "../Api/api";
import { takeEvery, put, take, fork, call, select } from "redux-saga/effects";
import {
  activeSubscriptions,
  startFirebaseSubscription,
  stopFirebaseSubscription,
} from "../Lib/firebase";

const START_INIT = "coin/START_INIT";
const ON_USER_LOADED_INIT = "coin/ON_USER_LOADED_INIT";
const ON_USER_LOGGED_OUT = "coin/ON_USER_LOGGED_OUT";

const GET_USER_INFO = "coin/GET_USER_INFO";
const GET_USER_INFO_SUCCESS = "coin/GET_USER_INFO_SUCCESS";
const GET_USER_INFO_ERROR = "coin/GET_USER_INFO_ERROR";

const GET_NOTIFICATIONS = "coin/GET_NOTIFICATIONS";
const GET_NOTIFICATIONS_SUCCESS = "coin/GET_NOTIFICATIONS_SUCCESS";
const GET_NOTIFICATIONS_ERROR = "coin/GET_NOTIFICATIONS_ERROR";

const GET_NOTIFICATIONS_MORE = "coin/GET_NOTIFICATIONS_MORE";
const GET_NOTIFICATIONS_MORE_SUCCESS = "coin/GET_NOTIFICATIONS_MORE_SUCCESS";
const GET_NOTIFICATIONS_MORE_ERROR = "coin/GET_NOTIFICATIONS_MORE_ERROR";

const UPDATE_NOTIFICATIONS_BY_API = "coin/UPDATE_NOTIFICATIONS_BY_API";
const UPDATE_NOTIFICATIONS_BY_API_SUCCESS = "coin/UPDATE_NOTIFICATIONS_BY_API_SUCCESS";
const UPDATE_NOTIFICATIONS_BY_API_ERROR = "coin/UPDATE_NOTIFICATIONS_BY_API_ERROR";

const GET_PRODUCT_IDS = "coin/GET_PRODUCT_IDS";
const GET_PRODUCT_IDS_SUCCESS = "coin/GET_PRODUCT_IDS_SUCCESS";
const GET_PRODUCT_IDS_ERROR = "coin/GET_PRODUCT_IDS_ERROR";

const GET_PRODUCT_LAST_24H = "coin/GET_PRODUCT_LAST_24H";
const GET_PRODUCT_LAST_24H_SUCCESS = "coin/GET_PRODUCT_LAST_24H_SUCCESS";
const GET_PRODUCT_LAST_24H_ERROR = "coin/GET_PRODUCT_LAST_24H_ERROR";

const GET_ORDER_HISTORY = "coin/GET_ORDER_HISTORY";
const GET_ORDER_HISTORY_SUCCESS = "coin/GET_ORDER_HISTORY_SUCCESS";
const GET_ORDER_HISTORY_ERROR = "coin/GET_ORDER_HISTORY_ERROR";

const GET_FUTURE_ORDER_HISTORY = "coin/GET_FUTURE_ORDER_HISTORY";
const GET_FUTURE_ORDER_HISTORY_SUCCESS = "coin/GET_FUTURE_ORDER_HISTORY_SUCCESS";
const GET_FUTURE_ORDER_HISTORY_ERROR = "coin/GET_FUTURE_ORDER_HISTORY_ERROR";

const GET_FUTURE_PROCESSES = "coin/GET_FUTURE_PROCESSES";
const GET_FUTURE_PROCESSES_SUCCESS = "coin/GET_FUTURE_PROCESSES_SUCCESS";
const GET_FUTURE_PROCESSES_ERROR = "coin/GET_FUTURE_PROCESSES_ERROR";

const GET_FUTURE_PROFILES = "coin/GET_FUTURE_PROFILES";
const GET_FUTURE_PROFILES_SUCCESS = "coin/GET_FUTURE_PROFILES_SUCCESS";
const GET_FUTURE_PROFILES_ERROR = "coin/GET_FUTURE_PROFILES_ERROR";
  
const GET_DEMO_FUTURE_ORDER_HISTORY = "coin/GET_DEMO_FUTURE_ORDER_HISTORY";
const GET_DEMO_FUTURE_ORDER_HISTORY_SUCCESS = "coin/GET_DEMO_FUTURE_ORDER_HISTORY_SUCCESS";
const GET_DEMO_FUTURE_ORDER_HISTORY_ERROR = "coin/GET_DEMO_FUTURE_ORDER_HISTORY_ERROR";

const GET_DEMO_FUTURE_PROCESSES = "coin/GET_DEMO_FUTURE_PROCESSES";
const GET_DEMO_FUTURE_PROCESSES_SUCCESS = "coin/GET_DEMO_FUTURE_PROCESSES_SUCCESS";
const GET_DEMO_FUTURE_PROCESSES_ERROR = "coin/GET_DEMO_FUTURE_PROCESSES_ERROR";

const GET_DEMO_FUTURE_PROFILES = "coin/GET_DEMO_FUTURE_PROFILES";
const GET_DEMO_FUTURE_PROFILES_SUCCESS = "coin/GET_DEMO_FUTURE_PROFILES_SUCCESS";
const GET_DEMO_FUTURE_PROFILES_ERROR = "coin/GET_DEMO_FUTURE_PROFILES_ERROR";

const GET_DEMO_FUTURE_ACTIVE_COMPETITION = "coin/GET_DEMO_FUTURE_ACTIVE_COMPETITION";
const GET_DEMO_FUTURE_ACTIVE_COMPETITION_SUCCESS = "coin/GET_DEMO_FUTURE_ACTIVE_COMPETITION_SUCCESS";
const GET_DEMO_FUTURE_ACTIVE_COMPETITION_ERROR = "coin/GET_DEMO_FUTURE_ACTIVE_COMPETITION_ERROR";

const CONNECT_CUSTOMER_UPDATED = "CONNECT_CUSTOMER_UPDATED";
const CONNECT_CUSTOMER_UPDATED_SUCCESS = "CONNECT_CUSTOMER_UPDATED_SUCCESS";
const CONNECT_CUSTOMER_UPDATED_ERROR = "CONNECT_CUSTOMER_UPDATED_ERROR";

const CONNECT_MINI_TICKER_UPDATED = "CONNECT_MINI_TICKER_UPDATED";
const CONNECT_MINI_TICKER_UPDATED_SUCCESS = "CONNECT_MINI_TICKER_UPDATED_SUCCESS";
const CONNECT_MINI_TICKER_UPDATED_ERROR = "CONNECT_MINI_TICKER_UPDATED_ERROR";

const CONNECT_PRODUCT_UPDATED_FOR_PROCESSES = "CONNECT_PRODUCT_UPDATED_FOR_PROCESSES";
const CONNECT_PRODUCT_UPDATED_FOR_PROCESSES_SUCCESS = "CONNECT_PRODUCT_UPDATED_FOR_PROCESSES_SUCCESS";
const CONNECT_PRODUCT_UPDATED_FOR_PROCESSES_ERROR = "CONNECT_PRODUCT_UPDATED_FOR_PROCESSES_ERROR";
  
const CONNECT_PRODUCT_UPDATED_FOR_DEMO_PROCESSES = "CONNECT_PRODUCT_UPDATED_FOR_DEMO_PROCESSES";
const CONNECT_PRODUCT_UPDATED_FOR_DEMO_PROCESSES_SUCCESS = "CONNECT_PRODUCT_UPDATED_FOR_DEMO_PROCESSES_SUCCESS";
const CONNECT_PRODUCT_UPDATED_FOR_DEMO_PROCESSES_ERROR = "CONNECT_PRODUCT_UPDATED_FOR_DEMO_PROCESSES_ERROR";

const GET_WALLET_VALUE = "coin/GET_WALLET_VALUE";
const GET_WALLET_VALUE_SUCCESS = "coin/GET_WALLET_VALUE_SUCCESS";
const GET_WALLET_VALUE_ERROR = "coin/GET_WALLET_VALUE_ERROR";

const ON_UPDATE_PRODUCTS_SLOW_DATA = "coin/ON_UPDATE_PRODUCTS_SLOW_DATA";
const ON_CHANGE_CHART_RESOLUTION = "coin/ON_CHANGE_CHART_RESOLUTION";
const ON_ORDER_HISTORY_CHANGE_TIME_INTERVAL = "coin/ON_ORDER_HISTORY_CHANGE_TIME_INTERVAL";
const ON_FUTURE_ORDER_HISTORY_CHANGE_TIME_INTERVAL = "coin/ON_FUTURE_ORDER_HISTORY_CHANGE_TIME_INTERVAL";
const ON_FUTURE_PROCESSES_CHANGE_TIME_INTERVAL = "coin/ON_FUTURE_PROCESSES_CHANGE_TIME_INTERVAL";
const CHANGE_SELECTED_PRODUCT = "coin/CHANGE_SELECTED_PRODUCT";
//const ON_PAGE_TRADE_OPEN = "coin/ON_PAGE_TRADE_OPEN";
const ON_CHANGE_FUTURE_PROCESSES = "coin/ON_CHANGE_FUTURE_PROCESSES";
const END_ALL_CANDLE_AND_PRODUCT_FOR_PROCESSES_SUBSCRIPTIONS = "coin/END_ALL_CANDLE_AND_PRODUCT_FOR_PROCESSES_SUBSCRIPTIONS";

const ON_DEMO_FUTURE_ORDER_HISTORY_CHANGE_TIME_INTERVAL = "coin/ON_DEMO_FUTURE_ORDER_HISTORY_CHANGE_TIME_INTERVAL";
const ON_DEMO_FUTURE_PROCESSES_CHANGE_TIME_INTERVAL = "coin/ON_DEMO_FUTURE_PROCESSES_CHANGE_TIME_INTERVAL";
const ON_CHANGE_DEMO_FUTURE_PROCESSES = "coin/ON_CHANGE_DEMO_FUTURE_PROCESSES";
const END_ALL_CANDLE_AND_PRODUCT_FOR_DEMO_PROCESSES_SUBSCRIPTIONS = "coin/END_ALL_CANDLE_AND_PRODUCT_FOR_DEMO_PROCESSES_SUBSCRIPTIONS";
const ON_DEMO_FUTURE_ACTIVE_COMPETITION_UPDATE = "coin/ON_DEMO_FUTURE_ACTIVE_COMPETITION_UPDATE";

const ON_LAST_TRADES_OPEN = "coin/ON_LAST_TRADES_OPEN";
const GET_LAST_TRADES_VALUE = "coin/GET_LAST_TRADES_VALUE";
const GET_LAST_TRADES_VALUE_SUCCESS = "coin/GET_LAST_TRADES_VALUE_SUCCESS";
const GET_LAST_TRADES_VALUE_ERROR = "coin/GET_LAST_TRADES_VALUE_ERROR";

const ON_PAGE_WITHDRAW_OPEN = "coin/ON_PAGE_WITHDRAW_OPEN";
const GET_WITHDRAW_VALUE = "coin/GET_WITHDRAW_VALUE";
const GET_WITHDRAW_VALUE_SUCCESS = "coin/GET_WITHDRAW_VALUE_SUCCESS";
const GET_WITHDRAW_VALUE_ERROR = "coin/GET_WITHDRAW_VALUE_ERROR";

const ON_PAGE_DEPOSIT_OPEN = "coin/ON_PAGE_DEPOSIT_OPEN";
const GET_DEPOSIT_VALUE = "coin/GET_DEPOSIT_VALUE";
const GET_DEPOSIT_VALUE_SUCCESS = "coin/GET_DEPOSIT_VALUE_SUCCESS";
const GET_DEPOSIT_VALUE_ERROR = "coin/GET_DEPOSIT_VALUE_ERROR";

const ON_WITHDRAW_HISTORY_OPEN = "coin/ON_WITHDRAW_HISTORY_OPEN";
const GET_WITHDRAW_HISTORY_VALUE = "coin/GET_WITHDRAW_HISTORY_VALUE";
const GET_WITHDRAW_HISTORY_VALUE_SUCCESS = "coin/GET_WITHDRAW_HISTORY_VALUE_SUCCESS";
const GET_WITHDRAW_HISTORY_VALUE_ERROR = "coin/GET_WITHDRAW_HISTORY_VALUE_ERROR";

const GET_WITHDRAW_HISTORY_MORE = "coin/GET_WITHDRAW_HISTORY_MORE";
const GET_WITHDRAW_HISTORY_MORE_SUCCESS = "coin/GET_WITHDRAW_HISTORY_MORE_SUCCESS";
const GET_WITHDRAW_HISTORY_MORE_ERROR = "coin/GET_WITHDRAW_HISTORY_MORE_ERROR";

const ON_DEPOSIT_HISTORY_OPEN = "coin/ON_DEPOSIT_HISTORY_OPEN";
const GET_DEPOSIT_HISTORY_VALUE = "coin/GET_DEPOSIT_HISTORY_VALUE";
const GET_DEPOSIT_HISTORY_VALUE_SUCCESS = "coin/GET_DEPOSIT_HISTORY_VALUE_SUCCESS";
const GET_DEPOSIT_HISTORY_VALUE_ERROR = "coin/GET_DEPOSIT_HISTORY_VALUE_ERROR";

const GET_DEPOSIT_HISTORY_MORE = "coin/GET_DEPOSIT_HISTORY_MORE";
const GET_DEPOSIT_HISTORY_MORE_SUCCESS = "coin/GET_DEPOSIT_HISTORY_MORE_SUCCESS";
const GET_DEPOSIT_HISTORY_MORE_ERROR = "coin/GET_DEPOSIT_HISTORY_MORE_ERROR";

const ON_TRANSFER_HISTORY_OPEN = "coin/ON_TRANSFER_HISTORY_OPEN";
const GET_TRANSFER_HISTORY_VALUE = "coin/GET_TRANSFER_HISTORY_VALUE";
const GET_TRANSFER_HISTORY_VALUE_SUCCESS = "coin/GET_TRANSFER_HISTORY_VALUE_SUCCESS";
const GET_TRANSFER_HISTORY_VALUE_ERROR = "coin/GET_TRANSFER_HISTORY_VALUE_ERROR";

const GET_TRANSFER_HISTORY_MORE = "coin/GET_TRANSFER_HISTORY_MORE";
const GET_TRANSFER_HISTORY_MORE_SUCCESS = "coin/GET_TRANSFER_HISTORY_MORE_SUCCESS";
const GET_TRANSFER_HISTORY_MORE_ERROR = "coin/GET_TRANSFER_HISTORY_MORE_ERROR";

const ON_HISTORY_SECTION_CLOSED = "coin/ON_HISTORY_SECTION_CLOSED";

const ON_PAGE_FUTURE_STATS_OPEN = "coin/ON_PAGE_FUTURE_STATS_OPEN";
const GET_FUTURE_STATS_VALUE = "coin/GET_FUTURE_STATS_VALUE";
const GET_FUTURE_STATS_VALUE_SUCCESS = "coin/GET_FUTURE_STATS_VALUE_SUCCESS";
const GET_FUTURE_STATS_VALUE_ERROR = "coin/GET_FUTURE_STATS_VALUE_ERROR";

const ON_PAGE_FUTURE_DEMO_STATS_OPEN = "coin/ON_PAGE_FUTURE_DEMO_STATS_OPEN";
const GET_FUTURE_DEMO_STATS_VALUE = "coin/GET_FUTURE_DEMO_STATS_VALUE";
const GET_FUTURE_DEMO_STATS_VALUE_SUCCESS = "coin/GET_FUTURE_DEMO_STATS_VALUE_SUCCESS";
const GET_FUTURE_DEMO_STATS_VALUE_ERROR = "coin/GET_FUTURE_DEMO_STATS_VALUE_ERROR";

const ON_CURRENCY_STATUS_OPEN = "coin/ON_CURRENCY_STATUS_OPEN";
const GET_FAVORITES_VALUE = "coin/GET_FAVORITES_VALUE";
const GET_FAVORITES_VALUE_SUCCESS = "coin/GET_FAVORITES_VALUE_SUCCESS";
const GET_FAVORITES_VALUE_ERROR = "coin/GET_FAVORITES_VALUE_ERROR";

const UPDATE_FAVORITES_BY_API = "coin/UPDATE_FAVORITES_BY_API";
const UPDATE_FAVORITES_BY_API_SUCCESS = "coin/UPDATE_FAVORITES_BY_API_SUCCESS";
const UPDATE_FAVORITES_BY_API_ERROR = "coin/UPDATE_FAVORITES_BY_API_ERROR";

const CONNECT_CANDLE_PRODUCT_UPDATED = "CONNECT_CANDLE_PRODUCT_UPDATED";
const CONNECT_CANDLE_PRODUCT_UPDATED_SUCCESS = "CONNECT_CANDLE_PRODUCT_UPDATED_SUCCESS";
const CONNECT_CANDLE_PRODUCT_UPDATED_ERROR = "CONNECT_CANDLE_PRODUCT_UPDATED_ERROR";

const processPayloadDirectly = (action) => action.payload;
processPayloadDirectly.isDirectPayload = true;

const getUserInfoSaga = createRequestSaga(
  GET_USER_INFO,
  kpriceApi.getUserInfo,
  userInfoUtils.init
);

const getNotifications = createRequestSaga(
  GET_NOTIFICATIONS,
  kpriceApi.getNotifications,
  notificationsUtils.init
);

const getNotificationsMore = createRequestSaga(
  GET_NOTIFICATIONS_MORE,
  kpriceApi.getNotificationsMore,
  notificationsUtils.more
);

const onUpdateNotificationsByApiSaga = createRequestSaga(
  UPDATE_NOTIFICATIONS_BY_API,
  processPayloadDirectly,
  notificationsUtils.updateByApi
);

const getOrderHistorySaga = createRequestSaga(
  GET_ORDER_HISTORY,
  kpriceApi.getOrderHistory,
  orderHistoryUtils.init
);

const getFutureOrderHistorySaga = createRequestSaga(
  GET_FUTURE_ORDER_HISTORY,
  kpriceApi.getFutureOrderHistory,
  futureOrderHistoryUtils.init
);

const getFutureProcessesSaga = createRequestSaga(
  GET_FUTURE_PROCESSES,
  kpriceApi.getFutureProcesses,
  futureProcessesUtils.init
);

const getFutureProfilesSaga = createRequestSaga(
  GET_FUTURE_PROFILES,
  kpriceApi.getFutureProfiles,
  futureProfilesUtils.init
);

const getDemoFutureOrderHistorySaga = createRequestSaga(
  GET_DEMO_FUTURE_ORDER_HISTORY,
  kpriceApi.getDemoFutureOrderHistory,
  demoFutureOrderHistoryUtils.init
);

const getDemoFutureProcessesSaga = createRequestSaga(
  GET_DEMO_FUTURE_PROCESSES,
  kpriceApi.getDemoFutureProcesses,
  demoFutureProcessesUtils.init
);

const getDemoFutureProfilesSaga = createRequestSaga(
  GET_DEMO_FUTURE_PROFILES,
  kpriceApi.getDemoFutureProfiles,
  demoFutureProfilesUtils.init
);

const getDemoFutureActiveCompetitionSaga = createRequestSaga(
  GET_DEMO_FUTURE_ACTIVE_COMPETITION,
  kpriceApi.getDemoFutureActiveCompetition,
  demoFutureActiveCompetitionUtils.init
);

const connectCustomerAppSyncSubscription = createAppSyncSubscriptionThunk(
  CONNECT_CUSTOMER_UPDATED,
  //userInfoUtils.update
  [
    { matchingType: 'Verification', dataMaker: (data, currentState) => ({ key: 'userInfo', payload: userInfoUtils.update(data, currentState) }) },
    { matchingType: 'Notification', dataMaker: (data, currentState) => ({ key: 'notifications', payload: notificationsUtils.update(data, currentState) }) },
    { matchingType: 'NotificationMultipleUpdate', dataMaker: (data, currentState) => ({ key: 'notifications', payload: notificationsUtils.update(data, currentState) }) },
    { matchingType: 'WithdrawHistory', dataMaker: (data, currentState) => ({ key: 'withdrawHistory', payload: withdrawHistoryUtils.update(data, currentState) }) },
    { matchingType: 'DepositHistory', dataMaker: (data, currentState) => ({ key: 'depositHistory', payload: depositHistoryUtils.update(data, currentState) }) },
    { matchingType: 'TransferHistory', dataMaker: (data, currentState) => ({ key: 'transferHistory', payload: transferHistoryUtils.update(data, currentState) }) },
    { matchingType: 'UpdateWallet', dataMaker: (data, currentState) => ({ key: 'wallet', payload: walletUtils.update(data, currentState) }) },
    { matchingType: 'UpdateSpotOrders', dataMaker: (data, currentState) => ({ key: 'orderHistory', payload: orderHistoryUtils.update(data, currentState) }) },
    { matchingType: 'UpdateFutureProfiles', dataMaker: (data, currentState) => ({ key: 'futureProfiles', payload: futureProfilesUtils.update(data, currentState) }) },
    { matchingType: 'UpdateFutureProcesses', dataMaker: (data, currentState) => ({ key: 'futureProcesses', payload: futureProcessesUtils.update(data, currentState) }) },
    { matchingType: 'UpdateFutureOrders', dataMaker: (data, currentState) => ({ key: 'futureOrderHistory', payload: futureOrderHistoryUtils.update(data, currentState) }) },
    { matchingType: 'UpdateFutureProfilesDemo', dataMaker: (data, currentState) => ({ key: 'demoFutureProfiles', payload: demoFutureProfilesUtils.update(data, currentState) }) },
    { matchingType: 'UpdateFutureProcessesDemo', dataMaker: (data, currentState) => ({ key: 'demoFutureProcesses', payload: demoFutureProcessesUtils.update(data, currentState) }) },
    { matchingType: 'UpdateFutureOrdersDemo', dataMaker: (data, currentState) => ({ key: 'demoFutureOrderHistory', payload: demoFutureOrderHistoryUtils.update(data, currentState) }) },
    { matchingType: 'UpdateFutureActiveCompetitionDemo', dataMaker: (data, currentState) => ({ key: 'demoFutureActiveCompetition', payload: demoFutureActiveCompetitionUtils.update(data, currentState) }) },
  ]
);

const connectMiniTickerAppSyncSubscription = createAppSyncSubscriptionThunkIAM(
  CONNECT_MINI_TICKER_UPDATED,
  //productsDataUtils.miniTicker
  [
    (data, currentState) => ({ key: 'productsMiniTicker', payload: productsDataUtils.miniTicker(data, currentState) }),
    (data, currentState) => ({ key: 'productsFast', payload: productsDataUtils.miniTickerFast(data, currentState) })
  ]
);

const connectProductForFutureProcessesAppSyncSubscription = createAppSyncSubscriptionThunk(
  CONNECT_PRODUCT_UPDATED_FOR_PROCESSES,
  productsDataUtils.updateForProcesses
);

const getProductIdsSaga = createRequestSaga(
  GET_PRODUCT_IDS,
  kpriceApi.getProductIds,
  productsDataUtils.init
);

const getProductLast24hSaga = createRequestSaga(
  GET_PRODUCT_LAST_24H,
  kpriceApi.getProduct24h,
  productsDataUtils.last24hdata
);

const getLastTradesValueSaga = createRequestSaga(
  GET_LAST_TRADES_VALUE,
  kpriceApi.getProductLastTrades,
  productsDataUtils.lastTrades
);

const getWalletValueSaga = createRequestSaga(
  GET_WALLET_VALUE,
  kpriceApi.getWallet,
  walletUtils.init
);

const getWithdrawValueSaga = createRequestSaga(
  GET_WITHDRAW_VALUE,
  kpriceApi.getWithdraw,
  withdrawUtils.init
);

const getDepositValueSaga = createRequestSaga(
  GET_DEPOSIT_VALUE,
  kpriceApi.getDeposit,
  depositUtils.init
);

const getWithdrawHistoryValueSaga = createRequestSaga(
  GET_WITHDRAW_HISTORY_VALUE,
  kpriceApi.getWithdrawHistory,
  withdrawHistoryUtils.init
);

const getWithdrawHistoryMore = createRequestSaga(
  GET_WITHDRAW_HISTORY_MORE,
  kpriceApi.getWithdrawHistoryMore,
  withdrawHistoryUtils.more
);

const getDepositHistoryValueSaga = createRequestSaga(
  GET_DEPOSIT_HISTORY_VALUE,
  kpriceApi.getDepositHistory,
  depositHistoryUtils.init
);

const getDepositHistoryMore = createRequestSaga(
  GET_DEPOSIT_HISTORY_MORE,
  kpriceApi.getDepositHistoryMore,
  depositHistoryUtils.more
);

const getTransferHistoryValueSaga = createRequestSaga(
  GET_TRANSFER_HISTORY_VALUE,
  kpriceApi.getTransferHistory,
  transferHistoryUtils.init
);

const getTransferHistoryMore = createRequestSaga(
  GET_TRANSFER_HISTORY_MORE,
  kpriceApi.getTransferHistoryMore,
  transferHistoryUtils.more
);

const getFavoritesValueSaga = createRequestSaga(
  GET_FAVORITES_VALUE,
  kpriceApi.getFavorites,
  favoritesUtils.init
);

const getFutureStatsValueSaga = createRequestSaga(
  GET_FUTURE_STATS_VALUE,
  kpriceApi.getFutureStats,
  futureStatsUtils.init
);

const getFutureDemoStatsValueSaga = createRequestSaga(
  GET_FUTURE_DEMO_STATS_VALUE,
  kpriceApi.getFutureDemoStats,
  futureDemoStatsUtils.init
);

const onUpdateFavoritesByApiSaga = createRequestSaga(
  UPDATE_FAVORITES_BY_API,
  processPayloadDirectly,
  favoritesUtils.updateByApi
);

function* kpriceSaga() {
  yield takeEvery(GET_NOTIFICATIONS_MORE, onGetNotificationsMoreSaga);
  yield takeEvery(UPDATE_NOTIFICATIONS_BY_API, onUpdateNotificationsByApiSaga);
  yield takeEvery(CHANGE_SELECTED_PRODUCT, onChangeSelectedProductSaga);
  yield takeEvery(GET_PRODUCT_IDS, getProductIdsSaga);
  yield takeEvery(GET_PRODUCT_LAST_24H, getProductLast24hSaga);
  yield takeEvery(ON_UPDATE_PRODUCTS_SLOW_DATA, onUpdateProductsSlowDataSaga);
  yield takeEvery(START_INIT, startInittSaga);
  yield takeEvery(ON_USER_LOADED_INIT, onUserLoadedSaga);
  yield takeEvery(ON_USER_LOGGED_OUT, onUserLoggedOutSaga);
  yield takeEvery(GET_USER_INFO, getUserInfoSaga);
  yield takeEvery(GET_NOTIFICATIONS, getNotifications);
  //yield takeEvery(ON_PAGE_TRADE_OPEN, onPageTradeOpenSaga);
  yield takeEvery(ON_CHANGE_FUTURE_PROCESSES, onChangeFutureProcessesSaga);
  yield takeEvery(END_ALL_CANDLE_AND_PRODUCT_FOR_PROCESSES_SUBSCRIPTIONS, endAllCandleAndProductForProcessesSubscriptionsSaga);

  yield takeEvery(ON_CHANGE_DEMO_FUTURE_PROCESSES, onChangeDemoFutureProcessesSaga);
  yield takeEvery(END_ALL_CANDLE_AND_PRODUCT_FOR_DEMO_PROCESSES_SUBSCRIPTIONS, endAllCandleAndProductForDemoProcessesSubscriptionsSaga);
  
  yield takeEvery(ON_CHANGE_CHART_RESOLUTION, onChangeChartResolutionSaga);

  yield takeEvery(GET_ORDER_HISTORY, getOrderHistorySaga);
  yield takeEvery(ON_ORDER_HISTORY_CHANGE_TIME_INTERVAL, onOrderHistoryChangeTimeIntervalSaga);

  yield takeEvery(GET_FUTURE_ORDER_HISTORY, getFutureOrderHistorySaga);
  yield takeEvery(ON_FUTURE_ORDER_HISTORY_CHANGE_TIME_INTERVAL, onFutureOrderHistoryChangeTimeIntervalSaga);
  yield takeEvery(GET_FUTURE_PROCESSES, getFutureProcessesSaga);
  yield takeEvery(ON_FUTURE_PROCESSES_CHANGE_TIME_INTERVAL, onFutureProcessesChangeTimeIntervalSaga);
  yield takeEvery(GET_FUTURE_PROFILES, getFutureProfilesSaga);

  yield takeEvery(GET_DEMO_FUTURE_ORDER_HISTORY, getDemoFutureOrderHistorySaga);
  yield takeEvery(ON_DEMO_FUTURE_ORDER_HISTORY_CHANGE_TIME_INTERVAL, onDemoFutureOrderHistoryChangeTimeIntervalSaga);
  yield takeEvery(GET_DEMO_FUTURE_PROCESSES, getDemoFutureProcessesSaga);
  yield takeEvery(ON_DEMO_FUTURE_PROCESSES_CHANGE_TIME_INTERVAL, onDemoFutureProcessesChangeTimeIntervalSaga);
  yield takeEvery(GET_DEMO_FUTURE_PROFILES, getDemoFutureProfilesSaga);
  yield takeEvery(GET_DEMO_FUTURE_ACTIVE_COMPETITION, getDemoFutureActiveCompetitionSaga);
  yield takeEvery(ON_DEMO_FUTURE_ACTIVE_COMPETITION_UPDATE, onDemoFutureActiveCompetitionUpdateSaga);

  yield takeEvery(GET_WALLET_VALUE, getWalletValueSaga);

  yield takeEvery(ON_PAGE_WITHDRAW_OPEN, onPageWithdrawOpenSaga);
  yield takeEvery(GET_WITHDRAW_VALUE, getWithdrawValueSaga);

  yield takeEvery(ON_PAGE_DEPOSIT_OPEN, onPageDepositOpenSaga);
  yield takeEvery(GET_DEPOSIT_VALUE, getDepositValueSaga);

  yield takeEvery(ON_LAST_TRADES_OPEN, onLastTradesOpenSaga);
  yield takeEvery(GET_LAST_TRADES_VALUE, getLastTradesValueSaga);

  yield takeEvery(ON_WITHDRAW_HISTORY_OPEN, onWithdrawHistoryOpenSaga);
  yield takeEvery(GET_WITHDRAW_HISTORY_VALUE, getWithdrawHistoryValueSaga);
  yield takeEvery(GET_WITHDRAW_HISTORY_MORE, onGetWithdrawHistoryMoreSaga);

  yield takeEvery(ON_DEPOSIT_HISTORY_OPEN, onDepositHistoryOpenSaga);
  yield takeEvery(GET_DEPOSIT_HISTORY_VALUE, getDepositHistoryValueSaga);
  yield takeEvery(GET_DEPOSIT_HISTORY_MORE, onGetDepositHistoryMoreSaga);

  yield takeEvery(ON_TRANSFER_HISTORY_OPEN, onTransferHistoryOpenSaga);
  yield takeEvery(GET_TRANSFER_HISTORY_VALUE, getTransferHistoryValueSaga);
  yield takeEvery(GET_TRANSFER_HISTORY_MORE, onGetTransferHistoryMoreSaga);

  yield takeEvery(ON_HISTORY_SECTION_CLOSED, onHistorySectionClosedSaga);

  yield takeEvery(ON_CURRENCY_STATUS_OPEN, onCurrencyStatusOpenSaga);
  yield takeEvery(GET_FAVORITES_VALUE, getFavoritesValueSaga);
  yield takeEvery(UPDATE_FAVORITES_BY_API, onUpdateFavoritesByApiSaga);

  yield takeEvery(ON_PAGE_FUTURE_STATS_OPEN, onPageFutureStatsOpenSaga);
  yield takeEvery(GET_FUTURE_STATS_VALUE, getFutureStatsValueSaga);
  
  yield takeEvery(ON_PAGE_FUTURE_DEMO_STATS_OPEN, onPageFutureDemoStatsOpenSaga);
  yield takeEvery(GET_FUTURE_DEMO_STATS_VALUE, getFutureDemoStatsValueSaga);
}

const initialState = {
  selectedProduct: null,
  selectedChartResolution: "60",
  selectedOrderHistoryTimeInterval: "last1w",
  selectedFutureOrderHistoryTimeInterval: "last1w",
  selectedFutureProcessesTimeInterval: "last1w",
  selectedDemoFutureOrderHistoryTimeInterval: "last1w",
  selectedDemoFutureProcessesTimeInterval: "last1w",
  currencyMaxBeforePlaces: null,
  userInfo: {
    error: false,
    data: {
    },
  },
  notifications: {
    error: false,
    data: {
    },
  },
  products: {
    error: false,
    data: {
    },
  },
  productsFast: {
    error: false,
    data: {
    },
  },
  productsSlow: {
    error: false,
    data: {
    },
  },
  productsMiniTicker: {
    error: false,
    data: {
    },
  },
  lastTrades: {
    error: false,
    data: {
    },
  },
  currencies: {
    error: false,
    data: {
    },
  },
  orderHistory: {
    error: false,
    data: {
    },
  },
  futureOrderHistory: {
    error: false,
    data: {
    },
  },
  futureProcesses: {
    error: false,
    data: {
    },
  },
  futureProfiles: {
    error: false,
    data: {
    },
  },
  demoFutureOrderHistory: {
    error: false,
    data: {
    },
  },
  demoFutureProcesses: {
    error: false,
    data: {
    },
  },
  demoFutureProfiles: {
    error: false,
    data: {
    },
  },
  demoFutureActiveCompetition: {
    error: false,
    data: {
    },
  },
  wallet: {
    error: false,
    data: {
    },
  },
  withdraw: {
    error: false,
    data: {
    },
  },
  deposit: {
    error: false,
    data: {
    },
  },
  withdrawHistory: {
    error: false,
    data: {
    },
  },
  depositHistory: {
    error: false,
    data: {
    },
  },
  transferHistory: {
    error: false,
    data: {
    },
  },
  favorites: {
    error: false,
    data: {
    },
  },
  futureStats: {
    error: false,
    data: {
    },
  },
  futureDemoStats: {
    error: false,
    data: {
    },
  },
};

function endSubscription(key, productId = null) {
  if (key === 'candle') {
    if (productId) {//candle için belirli bir aboneliği sonlandırma
      if (unsubscribeFunctions.candle[productId]) {
        //console.log(`${key} için ${productId} aboneliği sonlandırılıyor...`);
        unsubscribeFunctions.candle[productId].unsubscribe();
        delete unsubscribeFunctions.candle[productId];
        //console.log(`${key} için ${productId} aboneliği sonlandırıldı.`);
      } else {
        //console.log(`${key} için ${productId} aboneliği bulunamadı.`);
      }
    }
    else {//candle için tüm abonelikleri sonlandırma
      Object.keys(unsubscribeFunctions.candle).forEach(id => {
        //console.log(`${key} için ${id} aboneliği sonlandırılıyor...`);
        unsubscribeFunctions.candle[id].unsubscribe();
      });
      unsubscribeFunctions.candle = {};
      //console.log(`tüm ${key} abonelikleri sonlandırıldı.`);
    }
  }
  else if (key === 'productForFutureProcesses') {
    if (productId) {//belirli bir aboneliği sonlandırma
      disconnectFirebaseSubscription('CONNECT_PRODUCT_UPDATED_FOR_PROCESSES', productId);
    }
    else {//productForFutureProcesses için tüm abonelikleri sonlandırma
      for (const subsInfo of Object.keys(activeSubscriptions)) {
        if(subsInfo.includes("CONNECT_PRODUCT_UPDATED_FOR_PROCESSES")){
          disconnectFirebaseSubscription(subsInfo, null);
        }
      }
      //console.log(`tüm ${key} abonelikleri sonlandırıldı.`);
    }
  }
  else if (key === 'productForDemoFutureProcesses') {
    if (productId) {//belirli bir aboneliği sonlandırma
      disconnectFirebaseSubscription('CONNECT_PRODUCT_UPDATED_FOR_DEMO_PROCESSES', productId);
    }
    else {//productForDemoFutureProcesses için tüm abonelikleri sonlandırma
      for (const subsInfo of Object.keys(activeSubscriptions)) {
        if(subsInfo.includes("CONNECT_PRODUCT_UPDATED_FOR_DEMO_PROCESSES")){
          disconnectFirebaseSubscription(subsInfo, null);
        }
      }
      //console.log(`tüm ${key} abonelikleri sonlandırıldı.`);
    }
  }
  else {//diğer abonelik türleri için genel sonlandırma işlemi
    if (unsubscribeFunctions[key]) {
      //console.log(`${key} aboneliği sonlandırılıyor...`);
      unsubscribeFunctions[key].unsubscribe();
      unsubscribeFunctions[key] = null;
      //console.log(`${key} aboneliği sonlandırıldı.`);
    }
  }
}

function isCandleSubscriptionActive(productId) {
  return !!unsubscribeFunctions.candle[productId];
}

function isProductForFutureProcessesSubscriptionActive(productId) {
  return !!unsubscribeFunctions.productForFutureProcesses[productId];
}

/*
function* updateCandleSubscriptions(action) {
  //açık olması gereken ama kapalı olan abonelikleri başlatıyoruz
  for (const productId of action.payload.activeProductIds) {
    if (!isCandleSubscriptionActive(productId)) {
      //console.log(productId+"-----------------------------------------------------")
      yield put(connectCandleAppSyncSubscription({ payload: { product_id: productId } }));
    }
  }

  //açık olması gereken aboneliklerin dışındakileri sonlandırıyoruz
  for (const productId of Object.keys(unsubscribeFunctions.candle)) {
    if (!action.payload.activeProductIds.includes(productId)) {
      endSubscription('candle', productId);
    }
  }
}
*/

function* updateProductForFutureProcessesSubscriptions(action) {
  //açık olması gereken ama kapalı olan abonelikleri başlatıyoruz
  for (const productId of action.payload.activeProductIds) {
    //if (!isProductForFutureProcessesSubscriptionActive(productId)) {
      //console.log(productId+"-----------------------------------------------------")
      //yield put(connectProductForFutureProcessesAppSyncSubscription({ payload: { product_id: productId } }));
      yield put(connectFirebaseSubscription("CONNECT_PRODUCT_UPDATED_FOR_PROCESSES", productId, productsDataUtils.updateForProcesses));
    //}
  }

  //açık olması gereken aboneliklerin dışındakileri sonlandırıyoruz
  for (const subsInfo of Object.keys(activeSubscriptions)) {
    if(subsInfo.includes("CONNECT_PRODUCT_UPDATED_FOR_PROCESSES_")){
      const productId = subsInfo.replace("CONNECT_PRODUCT_UPDATED_FOR_PROCESSES_", "");
      if (!action.payload.activeProductIds.includes(productId)) {
        disconnectFirebaseSubscription('CONNECT_PRODUCT_UPDATED_FOR_PROCESSES', productId);
      }
    }
  }
}

function* updateProductForDemoFutureProcessesSubscriptions(action) {
  //açık olması gereken ama kapalı olan abonelikleri başlatıyoruz
  for (const productId of action.payload.activeProductIds) {
    yield put(connectFirebaseSubscription("CONNECT_PRODUCT_UPDATED_FOR_DEMO_PROCESSES", productId, productsDataUtils.updateForProcesses));
  }

  //açık olması gereken aboneliklerin dışındakileri sonlandırıyoruz
  for (const subsInfo of Object.keys(activeSubscriptions)) {
    if(subsInfo.includes("CONNECT_PRODUCT_UPDATED_FOR_DEMO_PROCESSES_")){
      const productId = subsInfo.replace("CONNECT_PRODUCT_UPDATED_FOR_DEMO_PROCESSES_", "");
      if (!action.payload.activeProductIds.includes(productId)) {
        disconnectFirebaseSubscription('CONNECT_PRODUCT_UPDATED_FOR_DEMO_PROCESSES', productId);
      }
    }
  }
}

const connectFirebaseSubscription = (type, params, utilsFunction) => {
  return (dispatch) => {
    startFirebaseSubscription(type, params, (error, data) => {
      if (error) {
        dispatch({ type: `${type}_ERROR`, payload: error.message });
      } else {
        dispatch({
          type: `${type}_SUCCESS`,
          payload: data, // Ham veri
          utilsFunction, // Utils fonksiyonu
        });
      }
    });
  };
};

const disconnectFirebaseSubscription = (type, params) => {
  stopFirebaseSubscription(type, params);
};

const startInit = () => ({ 
  type: START_INIT
});
function* startInittSaga() {//uygulama ilk yüklendiği anda çalışan fonksiyon
  //console.log("startInittSaga - uygulama çalıştığında çalışıyor, şimdilik içinde hiçbir işlem yapılmıyor");
  const state = yield select();

  try {
    yield call(getProductIdsSaga);
  } catch (error) {}

  yield put(connectFirebaseSubscription("CONNECT_MINI_TICKER_UPDATED", null, productsDataUtils.miniTicker));

  try {
    yield call(getProductLast24hSaga);
  } catch (error) {}
}

const onUserLoadedInit = (username) => ({
  type: ON_USER_LOADED_INIT,
  payload: username,
});
function* onUserLoadedSaga(action) {//kullanıcı giriş yaptığında - ilk bilgiler
  //console.log("onUserLoadedSaga - ilk bilgiler yüklendi");
  //console.log(action.payload.username);
  const state = yield select();
  
  try {
    yield call(getUserInfoSaga);
  } catch (error) {}

  try {
    yield call(getNotifications);
  } catch (error) {}

  try {
    yield call(getWalletValueSaga, action);
  } catch (error) {}
  
  try {
    yield call(getOrderHistorySaga, {
      payload: {
        timeInterval: state.kprice.selectedOrderHistoryTimeInterval,
      },
    });
  } catch (error) {}

  try {
    yield call(getFutureOrderHistorySaga, {
      payload: {
        timeInterval: state.kprice.selectedFutureOrderHistoryTimeInterval,
      },
    });
  } catch (error) {}
  
  try {
    yield call(getFutureProcessesSaga, {
      payload: {
        timeInterval: state.kprice.selectedFutureProcessesTimeInterval,
      },
    });
  } catch (error) {}

  try {
    yield call(getFutureProfilesSaga, action);
  } catch (error) {}

  try {
    yield call(getDemoFutureOrderHistorySaga, {
      payload: {
        timeInterval: state.kprice.selectedDemoFutureOrderHistoryTimeInterval,
      },
    });
  } catch (error) {}
  
  try {
    yield call(getDemoFutureProcessesSaga, {
      payload: {
        timeInterval: state.kprice.selectedDemoFutureProcessesTimeInterval,
      },
    });
  } catch (error) {}

  try {
    yield call(getDemoFutureProfilesSaga, action);
  } catch (error) {}

  try {
    yield call(getDemoFutureActiveCompetitionSaga, action);
  } catch (error) {}

  yield put(connectCustomerAppSyncSubscription({ payload: {customer_id : action.payload.username}}));
}

const onUserLoggedOut = () => ({
  type: ON_USER_LOGGED_OUT,
});
function* onUserLoggedOutSaga() {//kullanıcı çıkış yaptığında - bilgilerin temizlenmesi
  const state = yield select();

  state.kprice.selectedOrderHistoryTimeInterval = "last1w";
  state.kprice.selectedFutureOrderHistoryTimeInterval = "last1w";
  state.kprice.selectedFutureProcessesTimeInterval = "last1w";
  state.kprice.selectedDemoFutureOrderHistoryTimeInterval = "last1w";
  state.kprice.selectedDemoFutureProcessesTimeInterval = "last1w";
  state.kprice.userInfo.data = {};
  state.kprice.notifications.data = {};
  state.kprice.orderHistory.data = {};
  state.kprice.futureOrderHistory.data = {};
  state.kprice.futureProcesses.data = {};
  state.kprice.futureProfiles.data = {};
  state.kprice.demoFutureOrderHistory.data = {};
  state.kprice.demoFutureProcesses.data = {};
  state.kprice.demoFutureProfiles.data = {};
  state.kprice.demoFutureActiveCompetition.data = {};
  state.kprice.wallet.data = {};
  state.kprice.withdraw.data = {};
  state.kprice.deposit.data = {};
  state.kprice.withdrawHistory.data = {};
  state.kprice.depositHistory.data = {};
  state.kprice.transferHistory.data = {};
  state.kprice.favorites.data = {};

  endSubscription("candle");
  endSubscription("customer");
  endSubscription("productForFutureProcesses");
  endSubscription("productForDemoFutureProcesses");
}

const onUpdateProductsSlowData = (productsFastData) => ({
  type: ON_UPDATE_PRODUCTS_SLOW_DATA,
  payload: productsFastData,
});
function* onUpdateProductsSlowDataSaga(action) {//products slow data güncelleme
  const state = yield select();
  state.kprice.productsSlow.data = action.payload;
}

const onGetNotificationsMore = () => ({
  type: GET_NOTIFICATIONS_MORE
});
function* onGetNotificationsMoreSaga() {
  const state = yield select();
  const log_id = state.kprice.notifications.data.last_evaluated_key.log_id;
  const event_timestamp = state.kprice.notifications.data.last_evaluated_key.event_timestamp;

  try {
    yield call(getNotificationsMore, {
      payload: {
        log_id: log_id,
        event_timestamp: event_timestamp,
      },
    });
  } catch (error) {}
}

const onUpdateNotificationsByApi = (newNotification) => ({
  type: UPDATE_NOTIFICATIONS_BY_API,
  payload: newNotification
});

const onChangeSelectedProduct = (newSelectedProduct) => ({
  type: CHANGE_SELECTED_PRODUCT,
  payload: newSelectedProduct,
});
function* onChangeSelectedProductSaga(action) {//selected product güncellendiğinde bilgilerin çekilmesi
  //console.log(action.payload.newSelectedProduct);

  const newProduct = action.payload.newSelectedProduct;
  const state = yield select();
  if(newProduct != state.kprice.selectedProduct){
    state.kprice.selectedProduct = newProduct;
  }
}

const onChangeChartResolution = (newResolution) => ({
  type: ON_CHANGE_CHART_RESOLUTION,
  payload: newResolution,
});
function* onChangeChartResolutionSaga(action) {//tv chart resolution güncellendiğinde
  const state = yield select();
  state.kprice.selectedChartResolution = action.payload.newResolution;
}

const onOrderHistoryChangeTimeInterval = (newTimeInterval) => ({
  type: ON_ORDER_HISTORY_CHANGE_TIME_INTERVAL,
  payload: newTimeInterval,
});
function* onOrderHistoryChangeTimeIntervalSaga(action) {//closed orders tablosunun zaman bilgisi güncellendiğinde
  const state = yield select();
  state.kprice.selectedOrderHistoryTimeInterval = action.payload.newTimeInterval;

  try {
    yield call(getOrderHistorySaga, {
      payload: {
        timeInterval: action.payload.newTimeInterval,
      },
    });
  } catch (error) {}
}

const onFutureOrderHistoryChangeTimeInterval = (newTimeInterval) => ({
  type: ON_FUTURE_ORDER_HISTORY_CHANGE_TIME_INTERVAL,
  payload: newTimeInterval,
});
function* onFutureOrderHistoryChangeTimeIntervalSaga(action) {//future order history tablosunun zaman bilgisi güncellendiğinde
  const state = yield select();
  state.kprice.selectedFutureOrderHistoryTimeInterval = action.payload.newTimeInterval;

  try {
    yield call(getFutureOrderHistorySaga, {
      payload: {
        timeInterval: action.payload.newTimeInterval,
      },
    });
  } catch (error) {}
}

const onFutureProcessesChangeTimeInterval = (newTimeInterval) => ({
  type: ON_FUTURE_PROCESSES_CHANGE_TIME_INTERVAL,
  payload: newTimeInterval,
});
function* onFutureProcessesChangeTimeIntervalSaga(action) {//future processes tablosunun zaman bilgisi güncellendiğinde
  const state = yield select();
  state.kprice.selectedFutureProcessesTimeInterval = action.payload.newTimeInterval;

  try {
    yield call(getFutureProcessesSaga, {
      payload: {
        timeInterval: action.payload.newTimeInterval,
      },
    });
  } catch (error) {}
}

const onDemoFutureOrderHistoryChangeTimeInterval = (newTimeInterval) => ({
  type: ON_DEMO_FUTURE_ORDER_HISTORY_CHANGE_TIME_INTERVAL,
  payload: newTimeInterval,
});
function* onDemoFutureOrderHistoryChangeTimeIntervalSaga(action) {//demo future order history tablosunun zaman bilgisi güncellendiğinde
  const state = yield select();
  state.kprice.selectedDemoFutureOrderHistoryTimeInterval = action.payload.newTimeInterval;

  try {
    yield call(getDemoFutureOrderHistorySaga, {
      payload: {
        timeInterval: action.payload.newTimeInterval,
      },
    });
  } catch (error) {}
}

const onDemoFutureProcessesChangeTimeInterval = (newTimeInterval) => ({
  type: ON_DEMO_FUTURE_PROCESSES_CHANGE_TIME_INTERVAL,
  payload: newTimeInterval,
});
function* onDemoFutureProcessesChangeTimeIntervalSaga(action) {//demo future processes tablosunun zaman bilgisi güncellendiğinde
  const state = yield select();
  state.kprice.selectedDemoFutureProcessesTimeInterval = action.payload.newTimeInterval;

  try {
    yield call(getDemoFutureProcessesSaga, {
      payload: {
        timeInterval: action.payload.newTimeInterval,
      },
    });
  } catch (error) {}
}

const onDemoFutureActiveCompetitionUpdate = (newActiveCompetition) => ({
  type: ON_DEMO_FUTURE_ACTIVE_COMPETITION_UPDATE,
  payload: newActiveCompetition,
});
function* onDemoFutureActiveCompetitionUpdateSaga(action) {
  const state = yield select();

  const oldState = state.kprice.demoFutureActiveCompetition.data;
  const newState = { ...oldState };
  
  newState.active_competition = action.payload.newActiveCompetition;
  state.kprice.demoFutureActiveCompetition.data = newState;
}

/*
function delayForOnPageTradeOpen(ms) {//pollUntilProductIsNotNullForOnPageTradeOpen içindeki döngüyü saniyede 1 kere çalıştırmak için
  return new Promise(resolve => setTimeout(resolve, ms));
}

function* pollUntilProductIsNotNullForOnPageTradeOpen(action) {//onPageTradeOpenSaga içinden tetiklenen bir fonksiyon
  while (true) {
    const state = yield select();

    if (state.kprice.selectedProduct != null) {
      if(action.payload.fromPage == "future"){
        const activeProductIds = state.kprice.futureProcesses.data && state.kprice.futureProcesses.data.activeProcesses ? Array.from(new Set(state.kprice.futureProcesses.data.activeProcesses.map(process => process.product_id))) : [];
        try {
          yield call(updateProductForFutureProcessesSubscriptions, {
            payload: {
              activeProductIds: activeProductIds
            },
          });
        } catch (error) {}
      }
      
      //döngüyü kesmeliyiz çünkü artık null değil
      return;
    }

    //döngü hızını yavaşlatmak için 1 saniye sonra kontrol ediyoruz
    try {
      yield call(delayForOnPageTradeOpen, 1000);
    } catch (error) {}
  }
}

const onPageTradeOpen = (username) => ({ 
  type: ON_PAGE_TRADE_OPEN,
  payload: username,
});
function* onPageTradeOpenSaga(action) {//future sayfası açıldığında
  //console.log("future sayfası açıldı")
  const state = yield select();
  
  if(action.payload.fromPage == "future"){
    const activeProductIds = state.kprice.futureProcesses.data && state.kprice.futureProcesses.data.activeProcesses ? Array.from(new Set(state.kprice.futureProcesses.data.activeProcesses.map(process => process.product_id))) : [];
    try {
      yield call(updateProductForFutureProcessesSubscriptions, {
        payload: {
          activeProductIds: activeProductIds
        },
      });
    } catch (error) {}
  }
}
*/

const onLastTradesOpen = (product) => ({ 
  type: ON_LAST_TRADES_OPEN,
  payload: product
});
function* onLastTradesOpenSaga(action) {//last trades açıldığında
  //console.log("last trades açıldı")
  try {
    yield call(getLastTradesValueSaga, {
      payload: {
        product: action.payload.product,
      },
    });
  } catch (error) {}
}

const onPageWithdrawOpen = () => ({ 
  type: ON_PAGE_WITHDRAW_OPEN
});
function* onPageWithdrawOpenSaga(action) {//withdraw sayfası açıldığında
  //console.log("withdraw sayfası açıldı")
  try {
    yield call(getWithdrawValueSaga, action);
  } catch (error) {}
}

const onPageDepositOpen = () => ({ 
  type: ON_PAGE_DEPOSIT_OPEN
});
function* onPageDepositOpenSaga(action) {//deposit sayfası açıldığında
  //console.log("deposit sayfası açıldı")

  try {
    // Burada `yield call` kullanarak saga'yı çağırıyoruz.
    yield call(getDepositValueSaga, action);
  } catch (error) {
    // Hata oluşursa bu blok çalışacak
    //console.error("onPageDepositOpenSaga'da hata oluştu:", error);
    // Hata için bir action dispatch edilebilir
    // yield put({ type: 'FETCH_DEPOSIT_ERROR', error });
  }
}

const onWithdrawHistoryOpen = (currency) => ({ 
  type: ON_WITHDRAW_HISTORY_OPEN,
  payload: currency
});
function* onWithdrawHistoryOpenSaga(action) {//withdraw history açıldığında
  //console.log("withdraw history açıldı")
  try {
    yield call(getWithdrawHistoryValueSaga, {
      payload: {
        currency: action.payload.currency,
      },
    });
  } catch (error) {}
}

const onGetWithdrawHistoryMore = (currency) => ({
  type: GET_WITHDRAW_HISTORY_MORE,
  payload: currency
});
function* onGetWithdrawHistoryMoreSaga(action) {
  const state = yield select();
  const log_id = state.kprice.withdrawHistory.data.last_evaluated_key.log_id;
  const event_timestamp = state.kprice.withdrawHistory.data.last_evaluated_key.event_timestamp;

  try {
    yield call(getWithdrawHistoryMore, {
      payload: {
        currency: action.payload.currency,
        log_id: log_id,
        event_timestamp: event_timestamp,
      },
    });
  } catch (error) {}
}

const onDepositHistoryOpen = (currency) => ({ 
  type: ON_DEPOSIT_HISTORY_OPEN,
  payload: currency
});
function* onDepositHistoryOpenSaga(action) {//deposit history açıldığında
  //console.log("deposit history açıldı")
  try {
    yield call(getDepositHistoryValueSaga, {
      payload: {
        currency: action.payload.currency,
      },
    });
  } catch (error) {}
}

const onGetDepositHistoryMore = (currency) => ({
  type: GET_DEPOSIT_HISTORY_MORE,
  payload: currency
});
function* onGetDepositHistoryMoreSaga(action) {
  const state = yield select();
  const log_id = state.kprice.depositHistory.data.last_evaluated_key.log_id;
  const event_timestamp = state.kprice.depositHistory.data.last_evaluated_key.event_timestamp;

  try {
    yield call(getDepositHistoryMore, {
      payload: {
        currency: action.payload.currency,
        log_id: log_id,
        event_timestamp: event_timestamp,
      },
    });
  } catch (error) {}
}

const onTransferHistoryOpen = (currency) => ({ 
  type: ON_TRANSFER_HISTORY_OPEN,
  payload: currency
});
function* onTransferHistoryOpenSaga(action) {//transfer history açıldığında
  //console.log("transfer history açıldı")
  try {
    yield call(getTransferHistoryValueSaga, {
      payload: {
        currency: action.payload.currency,
      },
    });
  } catch (error) {}
}

const onGetTransferHistoryMore = (currency) => ({
  type: GET_TRANSFER_HISTORY_MORE,
  payload: currency
});
function* onGetTransferHistoryMoreSaga(action) {
  const state = yield select();
  const log_id = state.kprice.transferHistory.data.last_evaluated_key.log_id;
  const event_timestamp = state.kprice.transferHistory.data.last_evaluated_key.event_timestamp;

  try {
    yield call(getTransferHistoryMore, {
      payload: {
        currency: action.payload.currency,
        log_id: log_id,
        event_timestamp: event_timestamp,
      },
    });
  } catch (error) {}
}

const onHistorySectionClosed = () => ({ 
  type: ON_HISTORY_SECTION_CLOSED
});
function* onHistorySectionClosedSaga(action) {//withdraw-deposit-transfer history alanı kapatıldığında
  //console.log("history alanı kapatıldı")

  const state = yield select();
  state.kprice.withdrawHistory.data = {};
  state.kprice.depositHistory.data = {};
  state.kprice.transferHistory.data = {};
}

const onCurrencyStatusOpen = () => ({ 
  type: ON_CURRENCY_STATUS_OPEN
});
function* onCurrencyStatusOpenSaga(action) {//currecy status açıldığında
  //console.log("currency status açıldı");
  try {
    yield call(getFavoritesValueSaga, action);
  } catch (error) {}
}

const onPageFutureStatsOpen = (code) => ({ 
  type: ON_PAGE_FUTURE_STATS_OPEN,
  payload: code,
});
function* onPageFutureStatsOpenSaga(action) {
  try {
    yield call(getFutureStatsValueSaga, {
      payload: {
        code: action.payload.code,
      },
    });
  } catch (error) {}
}

const onPageFutureDemoStatsOpen = (code) => ({ 
  type: ON_PAGE_FUTURE_DEMO_STATS_OPEN,
  payload: code,
});
function* onPageFutureDemoStatsOpenSaga(action) {
  try {
    yield call(getFutureDemoStatsValueSaga, {
      payload: {
        code: action.payload.code,
      },
    });
  } catch (error) {}
}

const onUpdateFavoritesByApi = (newFavorite) => ({
  type: UPDATE_FAVORITES_BY_API,
  payload: newFavorite
});

const onChangeFutureProcesses = (withSelectedProduct) => ({ 
  type: ON_CHANGE_FUTURE_PROCESSES,
  payload: withSelectedProduct,
});
function* onChangeFutureProcessesSaga(action) {//future processes bilgileri güncellenince
  //console.log("future processes güncellendi, abonelikler kontrol ediliyor")
  const state = yield select();
  
  const activeProductIds = state.kprice.futureProcesses.data && state.kprice.futureProcesses.data.activeProcesses ? Array.from(new Set(state.kprice.futureProcesses.data.activeProcesses.map(process => process.product_id))) : [];
  try {
    yield call(updateProductForFutureProcessesSubscriptions, {
      payload: {
        activeProductIds: activeProductIds
      },
    });
  } catch (error) {}
}

const endAllCandleAndProductForProcesses = () => ({ 
  type: END_ALL_CANDLE_AND_PRODUCT_FOR_PROCESSES_SUBSCRIPTIONS
});
function* endAllCandleAndProductForProcessesSubscriptionsSaga(action) {//tüm candle ve productForFutureProcesses aboneliklerini sonlandırmak için
  //console.log("tüm candle abonelikleri sonlandırılıyor");
  endSubscription("candle");
  endSubscription("productForFutureProcesses");
}

const onChangeDemoFutureProcesses = (withSelectedProduct) => ({ 
  type: ON_CHANGE_DEMO_FUTURE_PROCESSES,
  payload: withSelectedProduct,
});
function* onChangeDemoFutureProcessesSaga(action) {//demo future processes bilgileri güncellenince
  const state = yield select();
  
  const activeProductIds = state.kprice.demoFutureProcesses.data && state.kprice.demoFutureProcesses.data.activeProcesses ? Array.from(new Set(state.kprice.demoFutureProcesses.data.activeProcesses.map(process => process.product_id))) : [];
  try {
    yield call(updateProductForDemoFutureProcessesSubscriptions, {
      payload: {
        activeProductIds: activeProductIds
      },
    });
  } catch (error) {}
}

const endAllCandleAndProductForDemoProcesses = () => ({ 
  type: END_ALL_CANDLE_AND_PRODUCT_FOR_DEMO_PROCESSES_SUBSCRIPTIONS
});
function* endAllCandleAndProductForDemoProcessesSubscriptionsSaga(action) {//tüm candle ve productForDemoFutureProcesses aboneliklerini sonlandırmak için
  //console.log("tüm candle abonelikleri sonlandırılıyor");
  endSubscription("candle");
  endSubscription("productForDemoFutureProcesses");
}

const kpriceReducer = (state = initialState, action) => {
  switch(action.type) {
    case GET_USER_INFO_SUCCESS:
    case GET_USER_INFO_ERROR:
      //console.log("GET_USER_INFO Reducer");
      return requestActions(GET_USER_INFO, "userInfo")(state, action);

    case GET_NOTIFICATIONS_SUCCESS:
    case GET_NOTIFICATIONS_ERROR:
      //console.log("GET_NOTIFICATIONS Reducer");
      return requestActions(GET_NOTIFICATIONS, "notifications")(state, action);

    case GET_NOTIFICATIONS_MORE_SUCCESS:
    case GET_NOTIFICATIONS_MORE_ERROR:
      //console.log("GET_NOTIFICATIONS_MORE Reducer");
      return requestActions(GET_NOTIFICATIONS_MORE, "notifications")(state, action);

    case UPDATE_NOTIFICATIONS_BY_API_SUCCESS:
    case UPDATE_NOTIFICATIONS_BY_API_ERROR:
      //console.log("UPDATE_NOTIFICATIONS_BY_API Reducer");
      return requestActions(UPDATE_NOTIFICATIONS_BY_API, "notifications")(state, action);

    case GET_PRODUCT_IDS_SUCCESS:
    case GET_PRODUCT_IDS_ERROR:
      //console.log("GET_PRODUCT_IDS Reducer");
      return requestActions(GET_PRODUCT_IDS, "products")(state, action);
    
    /*case CONNECT_CUSTOMER_UPDATED_SUCCESS:
    case CONNECT_CUSTOMER_UPDATED_ERROR:
      //console.log("CONNECT_CUSTOMER_UPDATED Reducer");
      return requestActions(CONNECT_CUSTOMER_UPDATED, "userInfo")(state, action);*/
    case CONNECT_CUSTOMER_UPDATED_SUCCESS:
    case CONNECT_CUSTOMER_UPDATED_ERROR:
      if (action.key) {
        return requestActions(CONNECT_CUSTOMER_UPDATED, action.key)(state, action);
      } else {
        // Eski davranışı koru veya bir hata fırlat
        //console.error("No key provided for action");
        return state;
      }
      
    case CONNECT_MINI_TICKER_UPDATED_SUCCESS: {
      const rawData = action.payload;
      const updatedData = action.utilsFunction(rawData, state);
      
      return {
        ...state,
        productsMiniTicker: {
          data: updatedData,
          error: false,
        },
        productsFast: {
          data: updatedData,
          error: false,
        },
      };
    }
    case CONNECT_MINI_TICKER_UPDATED_ERROR:
      return {
        ...state,
        productsMiniTicker: {
          ...state.productsMiniTicker,
          error: action.payload,
        },
        productsFast: {
          ...state.productsFast,
          error: action.payload,
        },
      };
    /*case CONNECT_MINI_TICKER_UPDATED_SUCCESS:
    case CONNECT_MINI_TICKER_UPDATED_ERROR:
      //console.log("CONNECT_MINI_TICKER_UPDATED Reducer");
      return requestActions(CONNECT_MINI_TICKER_UPDATED, "productsMiniTicker")(state, action);*/
    /*case CONNECT_MINI_TICKER_UPDATED_SUCCESS:
    case CONNECT_MINI_TICKER_UPDATED_ERROR:
      if (action.key) {
        return requestActions(CONNECT_MINI_TICKER_UPDATED, action.key)(state, action);
      } else {
        // Eski davranışı koru veya bir hata fırlat
        //console.error("No key provided for action");
        return state;
      }*/

    case GET_PRODUCT_LAST_24H_SUCCESS:
    case GET_PRODUCT_LAST_24H_ERROR:
      //console.log("GET_PRODUCT_LAST_24H Reducer");
      return requestActions(GET_PRODUCT_LAST_24H, "products")(state, action);
    
    case GET_ORDER_HISTORY_SUCCESS:
    case GET_ORDER_HISTORY_ERROR:
      //console.log("GET_ORDER_HISTORY Reducer");
      return requestActions(GET_ORDER_HISTORY, "orderHistory")(state, action);
    
    case GET_FUTURE_ORDER_HISTORY_SUCCESS:
    case GET_FUTURE_ORDER_HISTORY_ERROR:
      //console.log("GET_FUTURE_ORDER_HISTORY Reducer");
      return requestActions(GET_FUTURE_ORDER_HISTORY, "futureOrderHistory")(state, action);
    
    case GET_FUTURE_PROCESSES_SUCCESS:
    case GET_FUTURE_PROCESSES_ERROR:
      //console.log("GET_FUTURE_PROCESSES Reducer");
      return requestActions(GET_FUTURE_PROCESSES, "futureProcesses")(state, action);
    
    case CONNECT_CANDLE_PRODUCT_UPDATED_SUCCESS: {
      return {
        ...state,
        productsFast: {
          data: action.payload,
          error: false,
        },
      };
    }
    case CONNECT_CANDLE_PRODUCT_UPDATED_ERROR:
      return {
        ...state,
        productsFast: {
          ...state.productsFast,
          error: action.payload,
        },
      };
    
    case CONNECT_PRODUCT_UPDATED_FOR_PROCESSES_SUCCESS: {
      const rawData = action.payload;
      const updatedData = action.utilsFunction(rawData, state);
      
      return {
        ...state,
        productsFast: {
          data: updatedData,
          error: false,
        },
      };
    }
    case CONNECT_PRODUCT_UPDATED_FOR_PROCESSES_ERROR:
      return {
        ...state,
        productsFast: {
          ...state.productsFast,
          error: action.payload,
        },
      };
    
    /*
    case CONNECT_PRODUCT_UPDATED_FOR_PROCESSES_SUCCESS:
    case CONNECT_PRODUCT_UPDATED_FOR_PROCESSES_ERROR:
      //console.log("CONNECT_PRODUCT_UPDATED_FOR_PROCESSES Reducer");
      return requestActions(CONNECT_PRODUCT_UPDATED_FOR_PROCESSES, "productsFast")(state, action);
    */
    case GET_FUTURE_PROFILES_SUCCESS:
    case GET_FUTURE_PROFILES_ERROR:
      //console.log("GET_FUTURE_PROFILES Reducer");
      return requestActions(GET_FUTURE_PROFILES, "futureProfiles")(state, action);
    
    case GET_DEMO_FUTURE_ORDER_HISTORY_SUCCESS:
    case GET_DEMO_FUTURE_ORDER_HISTORY_ERROR:
      //console.log("GET_DEMO_FUTURE_ORDER_HISTORY Reducer");
      return requestActions(GET_DEMO_FUTURE_ORDER_HISTORY, "demoFutureOrderHistory")(state, action);
    
    case GET_DEMO_FUTURE_PROCESSES_SUCCESS:
    case GET_DEMO_FUTURE_PROCESSES_ERROR:
      //console.log("GET_DEMO_FUTURE_PROCESSES Reducer");
      return requestActions(GET_DEMO_FUTURE_PROCESSES, "demoFutureProcesses")(state, action);
    
    case CONNECT_PRODUCT_UPDATED_FOR_DEMO_PROCESSES_SUCCESS: {
      const rawData = action.payload;
      const updatedData = action.utilsFunction(rawData, state);
      
      return {
        ...state,
        productsFast: {
          data: updatedData,
          error: false,
        },
      };
    }
    case CONNECT_PRODUCT_UPDATED_FOR_DEMO_PROCESSES_ERROR:
      return {
        ...state,
        productsFast: {
          ...state.productsFast,
          error: action.payload,
        },
      };

    case GET_DEMO_FUTURE_PROFILES_SUCCESS:
    case GET_DEMO_FUTURE_PROFILES_ERROR:
      //console.log("GET_DEMO_FUTURE_PROFILES Reducer");
      return requestActions(GET_DEMO_FUTURE_PROFILES, "demoFutureProfiles")(state, action);
    
    case GET_DEMO_FUTURE_ACTIVE_COMPETITION_SUCCESS:
    case GET_DEMO_FUTURE_ACTIVE_COMPETITION_ERROR:
      //console.log("GET_DEMO_FUTURE_ACTIVE_COMPETITION Reducer");
      return requestActions(GET_DEMO_FUTURE_ACTIVE_COMPETITION, "demoFutureActiveCompetition")(state, action);
    
    case GET_WALLET_VALUE_SUCCESS:
    case GET_WALLET_VALUE_ERROR:
      //console.log("GET_WALLET_VALUE Reducer");
      return requestActions(GET_WALLET_VALUE, "wallet")(state, action);
    
    case GET_LAST_TRADES_VALUE_SUCCESS:
    case GET_LAST_TRADES_VALUE_ERROR:
      //console.log("GET_LAST_TRADES_VALUE Reducer");
      return requestActions(GET_LAST_TRADES_VALUE, "lastTrades")(state, action);
    
    case GET_WITHDRAW_VALUE_SUCCESS:
    case GET_WITHDRAW_VALUE_ERROR:
      //console.log("GET_WITHDRAW_VALUE Reducer");
      return requestActions(GET_WITHDRAW_VALUE, "withdraw")(state, action);

    case GET_DEPOSIT_VALUE_SUCCESS:
    case GET_DEPOSIT_VALUE_ERROR:
      //console.log("GET_DEPOSIT_VALUE Reducer");
      return requestActions(GET_DEPOSIT_VALUE, "deposit")(state, action);
    
    case GET_WITHDRAW_HISTORY_VALUE_SUCCESS:
    case GET_WITHDRAW_HISTORY_VALUE_ERROR:
      //console.log("GET_WITHDRAW_HISTORY_VALUE Reducer");
      return requestActions(GET_WITHDRAW_HISTORY_VALUE, "withdrawHistory")(state, action);
    
    case GET_WITHDRAW_HISTORY_MORE_SUCCESS:
    case GET_WITHDRAW_HISTORY_MORE_ERROR:
      //console.log("GET_WITHDRAW_HISTORY_MORE Reducer");
      return requestActions(GET_WITHDRAW_HISTORY_MORE, "withdrawHistory")(state, action);
    
    case GET_DEPOSIT_HISTORY_VALUE_SUCCESS:
    case GET_DEPOSIT_HISTORY_VALUE_ERROR:
      //console.log("GET_DEPOSIT_HISTORY_VALUE Reducer");
      return requestActions(GET_DEPOSIT_HISTORY_VALUE, "depositHistory")(state, action);
    
    case GET_DEPOSIT_HISTORY_MORE_SUCCESS:
    case GET_DEPOSIT_HISTORY_MORE_ERROR:
      //console.log("GET_DEPOSIT_HISTORY_MORE Reducer");
      return requestActions(GET_DEPOSIT_HISTORY_MORE, "depositHistory")(state, action);
    
    case GET_TRANSFER_HISTORY_VALUE_SUCCESS:
    case GET_TRANSFER_HISTORY_VALUE_ERROR:
      //console.log("GET_TRANSFER_HISTORY_VALUE Reducer");
      return requestActions(GET_TRANSFER_HISTORY_VALUE, "transferHistory")(state, action);
    
    case GET_TRANSFER_HISTORY_MORE_SUCCESS:
    case GET_TRANSFER_HISTORY_MORE_ERROR:
      //console.log("GET_TRANSFER_HISTORY_MORE Reducer");
      return requestActions(GET_TRANSFER_HISTORY_MORE, "transferHistory")(state, action);
    
    case GET_FAVORITES_VALUE_SUCCESS:
    case GET_FAVORITES_VALUE_ERROR:
      //console.log("GET_FAVORITES_VALUE Reducer");
      return requestActions(GET_FAVORITES_VALUE, "favorites")(state, action);
    
    case GET_FUTURE_STATS_VALUE_SUCCESS:
    case GET_FUTURE_STATS_VALUE_ERROR:
      //console.log("GET_FUTURE_STATS_VALUE Reducer");
      return requestActions(GET_FUTURE_STATS_VALUE, "futureStats")(state, action);
    
    case GET_FUTURE_DEMO_STATS_VALUE_SUCCESS:
    case GET_FUTURE_DEMO_STATS_VALUE_ERROR:
      //console.log("GET_FUTURE_DEMO_STATS_VALUE Reducer");
      return requestActions(GET_FUTURE_DEMO_STATS_VALUE, "futureDemoStats")(state, action);
    
    case UPDATE_FAVORITES_BY_API_SUCCESS:
    case UPDATE_FAVORITES_BY_API_ERROR:
      //console.log("UPDATE_FAVORITES_BY_API Reducer");
      return requestActions(UPDATE_FAVORITES_BY_API, "favorites")(state, action);

    default:
      return state;  // Eylem türüne göre bir durum belirtilmezse, mevcut durumu döndürür.
  }
}

export {
    startInit,
    onUserLoadedInit,
    onUserLoggedOut,
    onUpdateProductsSlowData,
    onGetNotificationsMore,
    onUpdateNotificationsByApi,
    kpriceReducer,
    kpriceSaga,
    onChangeSelectedProduct,
    onChangeChartResolution,
    onOrderHistoryChangeTimeInterval,
    onFutureOrderHistoryChangeTimeInterval,
    onFutureProcessesChangeTimeInterval,
    onDemoFutureOrderHistoryChangeTimeInterval,
    onDemoFutureProcessesChangeTimeInterval,
    onDemoFutureActiveCompetitionUpdate,
    onLastTradesOpen,
    onPageWithdrawOpen,
    onPageDepositOpen,
    onWithdrawHistoryOpen,
    onGetWithdrawHistoryMore,
    onDepositHistoryOpen,
    onGetDepositHistoryMore,
    onTransferHistoryOpen,
    onGetTransferHistoryMore,
    onHistorySectionClosed,
    onCurrencyStatusOpen,
    onPageFutureStatsOpen,
    onPageFutureDemoStatsOpen,
    onUpdateFavoritesByApi,
    onChangeFutureProcesses,
    endAllCandleAndProductForProcesses,
    onChangeDemoFutureProcesses,
    endAllCandleAndProductForDemoProcesses,
  };
import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { AuthContext } from '../components/AuthContext/AuthContext';
import logo_ligthmode from "../images/logo_icon_black.svg";
import logo_darkmode from "../images/logo_icon_white.svg";
import userprofile from "../images/userprofile.png";
import "./sidebar.css";
import { Link, useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { Dialog } from 'primereact/dialog';
import SectionTransferPopupContent from '../Pages/section_transfer_popup_content';

const Sidebar = () => {

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const tooltipPlacement = "right";
    const query = useQuery();
    const location = useLocation();
    const selectedProduct = useSelector((state) => state.kprice.selectedProduct);

    const [sidebarCloseStatus, setSidebarCloseStatus] = useState(document.body.classList.contains("sidebar-close"));
    const [openedPage, setOpenedPage] = useState(location.pathname.slice(1));
    const [checkScreenWidthValue, setCheckScreenWidthValue] = useState("");
    
    const userInfo = useSelector((state) => state.kprice.userInfo.data);

    const { isAuthenticated, user, signOut } = useContext(AuthContext);

    const [displayTransferDialog, setDisplayTransferDialog] = useState(false);

    const linkControl = (e, link) => {
        if(location.pathname === link){
            e.preventDefault();
        }
        else if(link == "trade" && location.pathname == "/trade"){
            if(query.get("market") == selectedProduct){
                e.preventDefault();
            }
        }
        else if(link == "future" && location.pathname == "/future"){
            if(query.get("market") == null){
                e.preventDefault();
            }
        }
        else if(link == "future-demo" && location.pathname == "/future-demo"){
            if(query.get("market") == null){
                e.preventDefault();
            }
        }
    };
    
    document.addEventListener("wheel", function(event){//type bilgisi number olan elementlerde scroll işlemi engellendi (input number'larda scroll ile bilgi değişmemesi için)
        if(document.activeElement.type === "number"){
            document.activeElement.blur();
        }
    });

    useEffect(() => {
        // Observer'ın callback fonksiyonu
        const callback = (mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    setSidebarCloseStatus(document.body.classList.contains("sidebar-close"));
                }
            }
        };
    
        // MutationObserver'ı başlatma
        const observer = new MutationObserver(callback);
    
        // `body` elementini ve hangi değişiklikleri dinlemek istediğimizi tanımlama
        observer.observe(document.body, { attributes: true });

        const checkScreenWidth = () => {
            if (window.innerWidth <= 1200 && checkScreenWidthValue != "down") {
                document.body.classList.add('sidebar-close');
                setSidebarCloseStatus(true);
                setCheckScreenWidthValue("down");
            }
            else if (window.innerWidth > 1200 && checkScreenWidthValue != "up") {
                setSidebarCloseStatus(document.body.classList.contains("sidebar-close"));
                setCheckScreenWidthValue("up");
            }
        };
        
        checkScreenWidth();
        
        window.addEventListener('resize', checkScreenWidth);
        
        // Component temizlenirken observer'ı durdurma ve ekran genişliğini dinlemeyi temizleme
        return () => {
            observer.disconnect();
            window.removeEventListener('resize', checkScreenWidth);
        }
    }, [checkScreenWidthValue]);

    return (
        <div id="sidebar" className="sidebar scrollbarhide">
            <Dialog header="Transfer" visible={displayTransferDialog} draggable={false} resizable={false} className="section-walletlist-dialog transfer" onHide={() => setDisplayTransferDialog(false)}>
                <SectionTransferPopupContent />
            </Dialog>
            <Link to='/' className="sidebar-logo" title="Logo">
                <img src={logo_ligthmode} alt="logo" className="logo-lightmode"/>
                <img src={logo_darkmode} alt="logo" className="logo-darkmode"/>
                <div>PRICE</div>
            </Link>
            <div className="sidebar-openclose" title="Sidebar Open/Close" onClick={() => document.body.classList.toggle("sidebar-close")}>
                <i className="fa-solid fa-arrow-right" style={sidebarCloseStatus ? undefined : {display: "none"}}></i>
                <i className="fa-solid fa-arrow-left" style={sidebarCloseStatus ? {display: "none"} : undefined}></i>
            </div>
            {isAuthenticated ? 
            <>
                <div className="sidebar-links">
                    <Tooltip title={sidebarCloseStatus ? "Dashboard" : undefined} placement={tooltipPlacement}>
                        <Link to='/' className={openedPage == "" ? "active" : undefined} onClick={(e) => linkControl(e, '/')}>
                            <div className="mark"></div>
                            <i className="fa-solid fa-house"></i>
                            <span>Dashboard</span>
                        </Link>
                    </Tooltip>
                    <Tooltip title={sidebarCloseStatus ? "Trade" : undefined} placement={tooltipPlacement}>
                        <Link to={selectedProduct ? `/trade?market=${selectedProduct}` : `/trade`} className={openedPage == "trade" ? "active" : undefined} onClick={(e) => linkControl(e, 'trade')}>
                            <div className="mark"></div>
                            <i className="fa-solid fa-arrow-right-arrow-left"></i>
                            <span>Trade</span>
                        </Link>
                    </Tooltip>
                    <Tooltip title={sidebarCloseStatus ? "Future" : undefined} placement={tooltipPlacement}>
                        <Link to="/future" className={openedPage == "future" ? "active" : undefined} onClick={(e) => linkControl(e, 'future')}>
                            <div className="mark"></div>
                            <i className="fa-solid fa-arrow-trend-up"></i>
                            <span>Future</span>
                        </Link>
                    </Tooltip>
                    <Tooltip title={sidebarCloseStatus ? "Future Demo" : undefined} placement={tooltipPlacement}>
                        <Link to="/future-demo" className={openedPage == "future-demo" ? "active" : undefined} onClick={(e) => linkControl(e, 'future-demo')}>
                            <div className="mark"></div>
                            <i className="fa-solid fa-chart-line"></i>
                            <span>Future Demo</span>
                        </Link>
                    </Tooltip>
                    <Tooltip title={sidebarCloseStatus ? "Competition Stats" : undefined} placement={tooltipPlacement}>
                        <Link to="/future-demo-ranking?stats_type=FDC10000" className={openedPage == "future-demo-ranking" ? "active" : undefined} onClick={(e) => linkControl(e, 'future-demo-ranking')}>
                            <div className="mark"></div>
                            <i className="fa-solid fa-ranking-star"></i>
                            <span>Competition Stats</span>
                        </Link>
                    </Tooltip>
                    <Tooltip title={sidebarCloseStatus ? "Transfer" : undefined} placement={tooltipPlacement}>
                        <a className={displayTransferDialog ? "active" : undefined} onClick={() => setDisplayTransferDialog(true)}>
                            <div className="mark"></div>
                            <i className="fa-solid fa-coins"></i>
                            <span>Transfer</span>
                        </a>
                    </Tooltip>
                    <Tooltip title={sidebarCloseStatus ? "Withdraw" : undefined} placement={tooltipPlacement}>
                        <Link to="/withdraw" state={{currency: null}} className={openedPage == "withdraw" ? "active" : undefined} onClick={(e) => linkControl(e, '/withdraw')}>
                            <div className="mark"></div>
                            <i className="fa-solid fa-hand-holding-dollar"></i>
                            <span>Withdraw</span>
                        </Link>
                    </Tooltip>
                    <Tooltip title={sidebarCloseStatus ? "Deposit" : undefined} placement={tooltipPlacement}>
                        <Link to="/deposit" state={{currency: null}} className={openedPage == "deposit" ? "active" : undefined} onClick={(e) => linkControl(e, '/deposit')}>
                            <div className="mark"></div>
                            <i className="fa-solid fa-money-bill"></i>
                            <span>Deposit</span>
                        </Link>
                    </Tooltip>
                    <Tooltip title={sidebarCloseStatus ? "Change Password" : undefined} placement={tooltipPlacement}>
                        <Link to="/change-password" className={openedPage == "change-password" ? "active" : undefined} onClick={(e) => linkControl(e, '/change-password')}>
                            <div className="mark"></div>
                            <i className="fa-solid fa-key"></i>
                            <span>Change Password</span>
                        </Link>
                    </Tooltip>

                    <Tooltip title={sidebarCloseStatus ? "Verification" : undefined} placement={tooltipPlacement}>
                        <Link to="/verification" className={openedPage == "verification" ? "active" : undefined} onClick={(e) => linkControl(e, '/verification')}>
                            <div className="mark"></div>
                            <i className="fa-solid fa-user-check"></i>
                            <span>Verification</span>
                        </Link>
                    </Tooltip>
                </div>
                <div className="sidebar-links">
                    <Tooltip title={sidebarCloseStatus ? "Support" : undefined} placement={tooltipPlacement}>
                        <Link to="/support" className={openedPage == "support" ? "active" : undefined} onClick={(e) => linkControl(e, '/support')}>
                            <div className="mark"></div>
                            <i className="fa-solid fa-headset"></i>
                            <span>Support</span>
                        </Link>
                    </Tooltip>
                    <Tooltip title={sidebarCloseStatus ? "Sign Out" : undefined} placement={tooltipPlacement}>
                        <a onClick={signOut}>
                            <div className="mark"></div>
                            <i className="fa-solid fa-right-from-bracket"></i>
                            <span>Sign Out</span>
                        </a>
                    </Tooltip>
                </div>
                <Tooltip title={sidebarCloseStatus && userInfo && userInfo.customer_id ? userInfo.customer_id : undefined} placement={tooltipPlacement}>
                    <div className="sidebar-user">
                        <Link to="/verification" className="user-img">
                            <div className={`image ${userInfo && userInfo.status_level && (userInfo.status_level == "2" ? "half-verify" : (userInfo.status_level == "3" ? "verify" : "not-verify"))}`} title={userInfo && userInfo.status_level ? (userInfo.status_level == "1" ? "To benefit from the features, please complete the verification steps" : (userInfo.status_level == "2" ? "To benefit from all features, please fill out the level 3 consent form" : "Level 3")) : "Not Verify"}>
                                {userInfo && userInfo.status_level && <span className="icondiv"></span>}
                                {userInfo && userInfo.status_level && ((userInfo.status_level == "2" || userInfo.status_level == "3") ? <i className="fa-solid fa-circle-check verify" title={userInfo.status_level == "2" ? "To benefit from all features, please fill out the level 3 consent form" : "Level 3"}></i> : <i className="fa-solid fa-circle-xmark not-verify" title={userInfo.status_level == "1" ? "To benefit from the features, please complete the verification steps" : "Not Verify"}></i>)}
                                <img src={userprofile} />
                            </div>
                            <div className="level">
                                <i className="fa-solid fa-crown"></i>
                                <label>Lvl {userInfo && userInfo.status_level ? userInfo.status_level : "1"}</label>
                            </div>
                        </Link>
                        <div className="user-info">
                            <label>{userInfo && userInfo.customer_id ? userInfo.customer_id : ""}</label>
                            <label>{userInfo && userInfo.masked_email ? userInfo.masked_email : ""}</label>
                            {userInfo && userInfo.full_name ? <label>{userInfo.full_name}</label> : ""}
                        </div>
                    </div>
                </Tooltip>
            </> : 
            <>
                <div className="sidebar-links">
                    <Tooltip title={sidebarCloseStatus ? "Dashboard" : undefined} placement={tooltipPlacement}>
                        <Link to='/' className={openedPage == "" ? "active" : undefined} onClick={(e) => linkControl(e, '/')}>
                            <div className="mark"></div>
                            <i className="fa-solid fa-house"></i>
                            <span>Dashboard</span>
                        </Link>
                    </Tooltip>
                    <Tooltip title={sidebarCloseStatus ? "Login" : undefined} placement={tooltipPlacement}>
                        <Link to='/login' className={openedPage == "login" ? "active" : undefined} onClick={(e) => linkControl(e, '/login')}>
                            <div className="mark"></div>
                            <i className="fa-solid fa-right-to-bracket"></i>
                            <span>Login</span>
                        </Link>
                    </Tooltip>
                    <Tooltip title={sidebarCloseStatus ? "Register" : undefined} placement={tooltipPlacement}>
                        <Link to='/register' className={openedPage == "register" ? "active" : undefined} onClick={(e) => linkControl(e, '/register')}>
                            <div className="mark"></div>
                            <i className="fa-solid fa-user-plus"></i>
                            <span>Register</span>
                        </Link>
                    </Tooltip>
                </div>
            </>
            }
            
        </div>
    );
}

export default Sidebar;

import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import 'material-react-toastify/dist/ReactToastify.css';
import { Auth } from 'aws-amplify';
import Decimal from 'decimal.js';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Link } from 'react-router-dom';
import Loading from "../../components/Global/Loading";
import { formatValue3, getFreeMargin, getValueWithShowPlaces, getMinAndStepWithShowPlaces, formatNumberTrimTrailingZeros, valueControlWithDigitLimit } from '../../Lib/calculationFunctions';

const CreateForm = ({ selectedProduct, productsData, productsFastData, currenciesData, futureBalance, futureCredit, formTab }) => {
    let productInfo = selectedProduct ? selectedProduct.split("-") : [];//ürün isim bilgileri tutulacak 0: amount, 1: price, 2: product_id
    if(selectedProduct){
        productInfo.push(selectedProduct)
    }

    const navigate = useNavigate();

    const userInfo = useSelector((state) => state.kprice.userInfo.data);
    const currentPrice = productsFastData && productsFastData[selectedProduct] ? productsFastData[selectedProduct].currentPrice : "NaN";
    const commission_rate = productsData && productsData[selectedProduct] ? productsData[selectedProduct].future_commission_rate : "0";
    const inputInfo_step = productsData && productsData[selectedProduct] ? productsData[selectedProduct].min_order_precision : "0";
    const inputInfo_min = productsData && productsData[selectedProduct] ? productsData[selectedProduct].min_order_count : "0";
    const inputInfo_askingPricePlaces = productsData && productsData[selectedProduct] ? productsData[selectedProduct].max_order_asking_price_places : "0";
    const inputInfo_countPlaces = productsData && productsData[selectedProduct] ? productsData[selectedProduct].max_order_count_places : "0";
    const inputInfo_maxBeforePlaces = productsData && productsData[selectedProduct] ? productsData[selectedProduct].max_before_places : null;
    const showPlacesAmount = selectedProduct && currenciesData && currenciesData[selectedProduct.split("-")[0]] && currenciesData[selectedProduct.split("-")[0]].show_places ? currenciesData[selectedProduct.split("-")[0]].show_places : null;
    const showPlacesPrice = selectedProduct && currenciesData && currenciesData[selectedProduct.split("-")[1]] && currenciesData[selectedProduct.split("-")[1]].show_places ? currenciesData[selectedProduct.split("-")[1]].show_places : null;
    const showPlaces_processes = 8;
    const futureProcesses = useSelector((state) => state.kprice.demoFutureProcesses.data);
    const futureLeverages = productsData && productsData[selectedProduct] && productsData[selectedProduct].future_leverages ? productsData[selectedProduct].future_leverages : null;
    const futureLeveragesMarks = futureLeverages ? futureLeverages.reduce((acc, number) => {
        acc[number] = number.toString();
        return acc;
    }, {}) : {};

    const showPlacesUSDT = currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places ? currenciesData["USDT"].show_places : null;

    const form = useRef(null);
    const inputPrice = useRef(null);
    const inputTotal = useRef(null);
    const inputAmount = useRef(null);
    const inputStopLoss = useRef(null);
    const inputTakeProfit = useRef(null);
    const inputPercentage = useRef(null);
    const leverageArea = useRef(null);
    const leverageAreaInput = useRef(null);

    const [freeMarginPrice, setFreeMarginPrice] = useState(0);
    const [freeMarginAmount, setFreeMarginAmount] = useState(0);
    const [volumePrice, setVolumePrice] = useState(0);
    const [volumeAmount, setVolumeAmount] = useState(0);
    const [fee, setFee] = useState(0);
    const [surety, setSurety] = useState(0);
    const [percentageSliderValue, setPercentageSliderValue] = useState(null);
    const futureLeveragesLastIndex = futureLeverages ? futureLeverages.length - 1 : 0;
    const [leverageSliderValue, setLeverageSliderValue] = useState(futureLeverages ? futureLeverages[futureLeveragesLastIndex] : 100);
    const [lastUpdater, setLastUpdater] = useState(null);
    
    const [currentPriceForLimitAskingPrice, setCurrentPriceForLimitAskingPrice] = useState(false);
    const [firstRunForPageOpening, setFirstRunForPageOpening] = useState(false);

    const [inputStopLossActive, setInputStopLossActive] = useState(false);
    const [inputTakeProfitActive, setInputTakeProfitActive] = useState(false);

    const [submitFutureBuyLoading, setSubmitFutureBuyLoading] = useState(false);
    const [submitFutureSellLoading, setSubmitFutureSellLoading] = useState(false);
    
    const [inputStopLossDanger, setInputStopLossDanger] = useState(false);
    const [inputTakeProfitDanger, setInputTakeProfitDanger] = useState(false);
    const [stopLossPotentialProfitOrder, setStopLossPotentialProfitOrder] = useState(null);
    const [takeProfitPotentialProfitOrder, setTakeProfitPotentialProfitOrder] = useState(null);

    useEffect(() => {//current price güncellendikçe potansiyel hesaplanıyor. bunun dışında formControl fonksiyonu içinde diğer veriler güncellendikçe hesaplanıyor.
        if(formTab == "market"){
            calculateStopLossPotential();
            calculateTakeProfitPotential();
        }
    }, [currentPrice, formTab]);

    //potansiyel hesaplama
    const calculateStopLossPotential = () => {
        const isLimit = formTab === "limit";

        // Determine which price to check based on formTab
        const priceValid = isLimit ? inputPrice.current.value !== "" : currentPrice !== "NaN";

        if (
            inputStopLoss.current.value !== "" &&
            !inputStopLossDanger &&
            inputAmount.current.value !== "" &&
            priceValid &&
            formTab
        ) {
            if (!currentPrice || inputStopLoss.current.value == "" || inputStopLossDanger || inputAmount.current.value == "" || !formTab) return;

            const entryPrice = new Decimal(formTab === "limit" ? inputPrice.current.value : currentPrice);
            const stopLossPrice = new Decimal(inputStopLoss.current.value);
            const count = new Decimal(inputAmount.current.value || 0);

            let potential;
            potential = stopLossPrice.minus(entryPrice).times(count);

            // Ensure Stop Loss is always negative
            potential = potential.isNegative() ? potential : potential.negated();

            setStopLossPotentialProfitOrder(potential);
        } else {
            setStopLossPotentialProfitOrder(null); // Reset when conditions are not met
        }
    };

    const calculateTakeProfitPotential = () => {
        const isLimit = formTab === "limit";

        // Determine which price to check based on formTab
        const priceValid = isLimit ? inputPrice.current.value !== "" : currentPrice !== "NaN";

        if (
            inputTakeProfit.current.value !== "" &&
            !inputTakeProfitDanger &&
            inputAmount.current.value !== "" &&
            priceValid &&
            formTab
        ) {
            if (!currentPrice || inputTakeProfit.current.value == "" || inputTakeProfitDanger || inputAmount.current.value == "" || !formTab) return;

            const entryPrice = new Decimal(formTab === "limit" ? inputPrice.current.value : currentPrice);
            const takeProfitPrice = new Decimal(inputTakeProfit.current.value);
            const count = new Decimal(inputAmount.current.value || 0);

            let potential;
            potential = takeProfitPrice.minus(entryPrice).times(count);

            // Ensure Take Profit is always positive
            potential = potential.isPositive() ? potential : potential.negated();

            setTakeProfitPotentialProfitOrder(potential);
        } else {
            setTakeProfitPotentialProfitOrder(null); // Reset when conditions are not met
        }
    };

    const formatProfit = (profit) => {
        if(profit != "" && profit != null && profit != undefined){
            return formatValue3(profit, showPlacesUSDT);
        }
        return profit;
    };

    const clearalldanger = () => {//tüm formlardaki hata belirten kırmızı çerçeveleri temizlemek için
        if(document.getElementsByClassName("section-tradebuysell")[0]){
            let elements1 = document.getElementsByClassName("section-tradebuysell")[0].getElementsByTagName("input");
            for (let i = 0; i < elements1.length; i++) {
                elements1[i].classList.remove("danger");
            }

            let elements2 = document.getElementsByClassName("section-tradebuysell")[0].getElementsByClassName("section-tradebuysell-inner-input-label");
            for (let i = 0; i < elements2.length; i++) {
                elements2[i].classList.remove("danger");
            }
        }
        
        let elements3 = document.getElementsByClassName("section-tradebuysell-inner-results-available");
        for (let i = 0; i < elements3.length; i++) {
            elements3[i].classList.remove("danger");
        }

        setInputStopLossDanger(false);
        setInputTakeProfitDanger(false);
    }

    const clearalldata = () => {//post işlemi sonrası form içindeki bilgileri temizlemek için
        inputPercentage.current = null;
        setPercentageSliderValue(0);
        setLeverageSliderValue(futureLeverages ? futureLeverages[futureLeveragesLastIndex] : 100);

        inputPrice.current.value = "";
        inputAmount.current.value = "";
        inputTotal.current.value = "";

        inputStopLoss.current.value = "";
        inputTakeProfit.current.value = "";
        setInputStopLossActive(false);
        setInputTakeProfitActive(false);

        //result değerleri sıfırlanıyor
        setVolumePrice(0);
        setVolumeAmount(0);
        setFee(0);
        setSurety(0);

        setCurrentPriceForLimitAskingPrice(false);
    }
    
    const setVolumeAndFee = () => {
        let formvalid = true;
        let controlInputs = formTab == "limit" ? [inputPrice, inputAmount] : [inputAmount];
        
        controlInputs.forEach(ref => {//hata verilecek durumlar kontrol ediliyor
            if(ref.current.value == "" || ref.current.value == "0" || Number(ref.current.value) < Number(ref.current.min) || !valueControlWithDigitLimit(ref.current.value, inputInfo_maxBeforePlaces)){
                formvalid = false;
            }
        });

        if(formvalid){
            let value_asking_price = formTab == "limit" ? Number(inputPrice.current.value) : Number(currentPrice);
            let value_amount = Number(inputAmount.current.value);
            let value_leverage = Number(leverageSliderValue);
            
            //volume
            //let effective_processing_price = (new Decimal(value_asking_price).mul(new Decimal(1).plus(commission_rate))).div(new Decimal(value_leverage));
            let effective_processing_price = ((new Decimal(value_asking_price)).div(new Decimal(value_leverage))).plus((new Decimal(value_asking_price)).mul(commission_rate));
            setVolumeAmount(value_amount);
            setVolumePrice(new Decimal(value_amount).mul(effective_processing_price));

            //surety
            const currentSurety = (new Decimal(value_asking_price).mul(new Decimal(value_amount))).div(new Decimal(value_leverage));
            setSurety(formatValue3(currentSurety, showPlacesPrice));

            //fee
            const order_volume_wofee = new Decimal(value_asking_price).mul(new Decimal(value_amount))

            //const currentFee = new Decimal(currentSurety).mul(new Decimal(commission_rate));
            const currentFee = new Decimal(order_volume_wofee).mul(new Decimal(commission_rate));
            setFee(formatValue3(currentFee, showPlacesPrice));
        }
    }

    const calcFreeMarginPrice = () => {
        let tempTotalBalance = futureBalance ? futureBalance : 0;
        let tempTotalProfit = 0;
        let tempTotalSurety = 0;
        let tempTotalAssets = 0;
        let tempCredit = futureCredit ? futureCredit : 0;
        
        if(futureProcesses && futureProcesses.activeProcesses){
            futureProcesses.activeProcesses.map((item, index) => {
                if(item.future_type == "buy"){
                    tempTotalProfit = new Decimal(tempTotalProfit).plus((new Decimal(productsFastData[item.product_id].currentPrice).minus(new Decimal(item.entry_price))).times(new Decimal(item.available_count)));
                }
                else if(item.future_type == "sell"){
                    tempTotalProfit = new Decimal(tempTotalProfit).plus((new Decimal(item.entry_price).minus(new Decimal(productsFastData[item.product_id].currentPrice))).times(new Decimal(item.available_count)));
                }
                
                tempTotalSurety = new Decimal(tempTotalSurety).plus(new Decimal(item.available_surety));
            });
        }
        
        tempTotalAssets = new Decimal(tempTotalBalance).plus(new Decimal(tempTotalProfit));
        tempTotalAssets = tempTotalAssets.plus(new Decimal(tempCredit));
        let tempTotalFreeMargin = tempTotalAssets ? new Decimal(tempTotalAssets).minus(new Decimal(tempTotalSurety)) : 0;
        return tempTotalFreeMargin.toNumber();
    }
    
    const formControl = async (formpost, formType) => {//form post kontrolleri ve işlemleri
        if(formType == "buy"){
            setSubmitFutureBuyLoading(true);
        }
        else if(formType == "sell"){
            setSubmitFutureSellLoading(true);
        }
        clearalldanger();
        
        let formvalid = 1;//form post edilebilir mi, geçerli mi
        let errormessages = [];
        let controlInputs = [];
        
        if(formTab == "limit"){//kontrol edilecek input'lar belirleniyor
            controlInputs = [inputPrice, inputAmount, inputTotal, inputStopLoss, inputTakeProfit];
        }
        else if(formTab == "market"){
            controlInputs = [inputAmount, inputTotal, inputStopLoss, inputTakeProfit];
        }

        controlInputs.forEach(ref => {//bilgileri olması gerektiği gibi düzenleme kontrolleri
            if(ref.current.value == "00"){
                ref.current.value = "0";
            }
            else if(Number(ref.current.value) < 0 || ref.current.value.includes("-") || ref.current.value.includes("e")){
                ref.current.value = "";
            }
            else{
                if(ref.current.value[1] != undefined && ref.current.value[0] == "0" && ref.current.value[1] != "." && ref.current.value[1] != ","){
                    ref.current.value = ref.current.value.slice(1);
                }
                if(ref.current.value.includes(".") || ref.current.value.includes(",")){
                    if(ref.current.name != undefined && ref.current.name == "count"){
                        ref.current.value = getValueWithShowPlaces(inputInfo_countPlaces, ref.current.value);
                    }
                    else{
                        ref.current.value = getValueWithShowPlaces(inputInfo_askingPricePlaces, ref.current.value);
                    }
                }
            }
        });
        
        if(formTab == "limit"){//hata vermesi gereken input'lar belirleniyor
            controlInputs = [inputPrice, inputAmount];
        }
        else if(formTab == "market"){
            controlInputs = [inputAmount];
        }
        
        controlInputs.forEach(ref => {//hata verilecek durumlar kontrol ediliyor
            if(ref.current.value == "" || ref.current.value == "0"){
                if(ref.current.name == "asking_price"){
                    inputPercentage.current = null;
                    setPercentageSliderValue(0);
                    inputTotal.current.value = "";
                }

                formvalid = 0;
                ref.current.classList.add("danger");
                errormessages.push("Please enter value for " + ref.current.dataset.name_info + "!");
            }
            else if(Number(ref.current.value) < Number(ref.current.min)){
                formvalid = 0;
                ref.current.classList.add("danger");
                errormessages.push("The value entered for " + ref.current.dataset.name_info + " must be greater than or equal to " + ref.current.min + "!");
            }
            else if(!valueControlWithDigitLimit(ref.current.value, inputInfo_maxBeforePlaces)){
                formvalid = 0;
                ref.current.classList.add("danger");
                errormessages.push("The integer part of the value you enter for " + ref.current.dataset.name_info + " cannot exceed " + inputInfo_maxBeforePlaces + " digits!");
            }
        });

        //stop loss ve take profit değerlerinin price değerine göre uygunluk kontrolü
        const priceValue = formTab == "limit" ? inputPrice.current.value : currentPrice;

        if(inputStopLoss.current.value != "" && inputStopLoss.current.value != "0" && !valueControlWithDigitLimit(inputStopLoss.current.value, inputInfo_maxBeforePlaces)){
            formvalid = 0;
            inputStopLoss.current.classList.add("danger");
            setInputStopLossDanger(true);
            errormessages.push("The integer part of the value you enter for Stop Loss cannot exceed " + inputInfo_maxBeforePlaces + " digits!");
        }

        if(inputTakeProfit.current.value != "" && inputTakeProfit.current.value != "0" && !valueControlWithDigitLimit(inputTakeProfit.current.value, inputInfo_maxBeforePlaces)){
            formvalid = 0;
            inputTakeProfit.current.classList.add("danger");
            setInputTakeProfitDanger(true);
            errormessages.push("The integer part of the value you enter for Take Profit cannot exceed " + inputInfo_maxBeforePlaces + " digits!");
        }

        if(formpost && formType == "buy"){
            if(priceValue != "" && inputStopLoss.current.value != ""){
                if(Number(priceValue) <= Number(inputStopLoss.current.value)){
                    formvalid = 0;
                    inputPrice.current.classList.add("danger");
                    inputStopLoss.current.classList.add("danger");
                    errormessages.push("Stop Loss value must be less than Price!");
                }
                else if(0 >= Number(inputStopLoss.current.value)){
                    formvalid = 0;
                    inputStopLoss.current.classList.add("danger");
                    errormessages.push("Stop Loss value must be bigger than 0!");
                }
            }
    
            if(priceValue != "" && inputTakeProfit.current.value != ""){
                if(Number(priceValue) >= Number(inputTakeProfit.current.value)){
                    formvalid = 0;
                    inputPrice.current.classList.add("danger");
                    inputTakeProfit.current.classList.add("danger");
                    errormessages.push("Take Profit value must be bigger than Price!");
                }
                else if(0 >= Number(inputTakeProfit.current.value)){
                    formvalid = 0;
                    inputTakeProfit.current.classList.add("danger");
                    errormessages.push("Take Profit value must be bigger than 0!");
                }
            }
        }
        else if(formpost && formType == "sell"){
            if(priceValue != "" && inputStopLoss.current.value != ""){
                if(Number(priceValue) >= Number(inputStopLoss.current.value)){
                    formvalid = 0;
                    inputPrice.current.classList.add("danger");
                    inputStopLoss.current.classList.add("danger");
                    errormessages.push("Stop Loss value must be bigger than Price!");
                }
                else if(0 >= Number(inputStopLoss.current.value)){
                    formvalid = 0;
                    inputStopLoss.current.classList.add("danger");
                    errormessages.push("Stop Loss value must be bigger than 0!");
                }
            }
    
            if(priceValue != "" && inputTakeProfit.current.value != ""){
                if(Number(priceValue) <= Number(inputTakeProfit.current.value)){
                    formvalid = 0;
                    inputPrice.current.classList.add("danger");
                    inputTakeProfit.current.classList.add("danger");
                    errormessages.push("Take Profit value must be less than Price!");
                }
                else if(0 >= Number(inputTakeProfit.current.value)){
                    formvalid = 0;
                    inputTakeProfit.current.classList.add("danger");
                    errormessages.push("Take Profit value must be bigger than 0!");
                }
            }
        }

        if(formvalid == 1){//input girdileri uygunsa amount değerinin uygunluğu kontrol ediliyor.
            let current_volumeAmount, current_volumePrice, current_freeMarginAmount, current_freeMarginPrice;

            let value_asking_price = formTab == "limit" ? Number(inputPrice.current.value) : Number(currentPrice);
            let value_amount = Number(inputAmount.current.value);
            let value_leverage = Number(leverageSliderValue);
            
            //volume
            //let effective_processing_price = (new Decimal(value_asking_price).mul(new Decimal(1).plus(commission_rate))).div(new Decimal(value_leverage));
            let effective_processing_price = ((new Decimal(value_asking_price)).div(new Decimal(value_leverage))).plus((new Decimal(value_asking_price)).mul(commission_rate));
            current_volumeAmount = value_amount;
            current_volumePrice = new Decimal(value_amount).mul(effective_processing_price);

            //freeMargin
            if(futureBalance != null && inputInfo_step != null && inputInfo_min != null && futureBalance != "NaN" && inputInfo_step != "0" && inputInfo_min != "0"){
                current_freeMarginPrice = calcFreeMarginPrice();
                current_freeMarginAmount = getFreeMargin(calcFreeMarginPrice(), value_leverage, currentPrice, commission_rate, inputInfo_step, inputInfo_min, 100);
            }

            if(Number(current_volumePrice) > Number(current_freeMarginPrice)){//free margin uygunluğu
                formvalid = 0;
                inputPrice.current.classList.add("danger");
                form.current.getElementsByClassName("section-tradebuysell-inner-results-available")[0].classList.add("danger");
                errormessages.push("There are not enough balance for the order!");
            }
        }
        else{//input girdileri boş olmama kriterlerini sağlamıyor ise volume ve fee alanları 0 olarak gösteriliyor
            setVolumePrice(0);
            setVolumeAmount(0);
            setFee(0);
            setSurety(0);
        }

        calculateStopLossPotential();
        calculateTakeProfitPotential();

        if(formpost && formvalid == 1){//post edilme aşaması
            const data = new FormData(form.current);
            data.append("type", `${formTab}${formType}`);

            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            axios.post('https://api.kpricemarket.com/demo/futureorder', 
                JSON.stringify(Object.fromEntries(data)),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {
                if(response.status == "200" && response.data.message == "Future order has been added successfully."){
                    clearalldata();
                    toast.success("Future demo order has been added successfully!");
                    navigate('/future-demo');
                }
                else if(response.data.message == "Not enough total_count for order"){
                    toast.error("There are not enough balance for the order!");
                }
                else{
                    toast.error(`Something went wrong!${response.data.message ? ` (${response.data.message})` : ""}`);
                }
                setSubmitFutureBuyLoading(false);
                setSubmitFutureSellLoading(false);
            })
            .catch(function (error) {
                if(error.response.data.error == "Not enough total_count for order"){
                    toast.error("There are not enough balance for the order!");
                }
                else{
                    toast.error(`Something went wrong!${error.response.data.error ? ` (${error.response.data.error})` : ""}`);
                }
                setSubmitFutureBuyLoading(false);
                setSubmitFutureSellLoading(false);
            });
        }
        else if(formpost && formvalid == 0){//hata mesajlarının gösterilme aşaması
            for (let i = 0; i < errormessages.length; i++) {
                toast.error(errormessages[i]);
            }
            setSubmitFutureBuyLoading(false);
            setSubmitFutureSellLoading(false);
        }
        else{
            setSubmitFutureBuyLoading(false);
            setSubmitFutureSellLoading(false);
        }
    }

    const directFormControl = (formpost) => {
        let valuePrice = formTab == "limit" ? Number(inputPrice.current.value) : Number(currentPrice);
        let value_leverage = Number(leverageSliderValue);

        if(lastUpdater == "Amount"){
            if(inputAmount.current.value != ""){
                let maxAmount;
                if(valuePrice && valuePrice !== 0){
                    maxAmount = getFreeMargin(calcFreeMarginPrice(), value_leverage, valuePrice, commission_rate, inputInfo_step, inputInfo_min, 100);
                }
    
                if(maxAmount && maxAmount !== 0){
                    const percentageValue = (Number(inputAmount.current.value) / maxAmount) * 100;
                    inputPercentage.current = percentageValue;
                    setPercentageSliderValue(percentageValue);
                }

                // Total (volume price) hesaplaması:
                let effectiveProcessingPrice;
                //effectiveProcessingPrice = (new Decimal(valuePrice).mul(new Decimal(1).plus(commission_rate))).div(new Decimal(value_leverage));
                effectiveProcessingPrice = ((new Decimal(valuePrice)).div(new Decimal(value_leverage))).plus((new Decimal(valuePrice)).mul(commission_rate));
                const totalValue = new Decimal(inputAmount.current.value).mul(effectiveProcessingPrice);
                inputTotal.current.value = totalValue.toString();
            }
            else{
                inputTotal.current.value = "";
                inputPercentage.current = null;
                setPercentageSliderValue(0);
            }
        }
        else if(lastUpdater == "Total"){
            if(inputTotal.current.value != ""){
                let effectiveProcessingPrice;
                //effectiveProcessingPrice = (new Decimal(valuePrice).mul(new Decimal(1).plus(commission_rate))).div(new Decimal(value_leverage));
                effectiveProcessingPrice = ((new Decimal(valuePrice)).div(new Decimal(value_leverage))).plus((new Decimal(valuePrice)).mul(commission_rate));

                const amountValue = new Decimal(inputTotal.current.value).div(effectiveProcessingPrice);
                inputAmount.current.value = amountValue.toString();

                // Yüzdeyi hesaplayın
                let maxAmount;
                maxAmount = getFreeMargin(calcFreeMarginPrice(), value_leverage, valuePrice, commission_rate, inputInfo_step, inputInfo_min, 100);
                
                if (maxAmount && maxAmount !== 0) {
                    const percentageValue = (Number(inputAmount.current.value) / maxAmount) * 100;
                    inputPercentage.current = percentageValue;
                    setPercentageSliderValue(percentageValue);
                }
            }
            else{
                inputAmount.current.value = "";
                inputPercentage.current = null;
                setPercentageSliderValue(0);
            }
        }
        else if(lastUpdater == "Percentage"){
            if(Number(inputPercentage.current) != 0){
                let valueCount;
                
                if(valuePrice != 0 && valuePrice != null){
                    valueCount = getFreeMargin(calcFreeMarginPrice(), value_leverage, valuePrice, commission_rate, inputInfo_step, inputInfo_min, Number(inputPercentage.current));
                    inputAmount.current.value = getValueWithShowPlaces(inputInfo_countPlaces, valueCount);
                }

                // Total (volume price) hesaplaması:
                let effectiveProcessingPrice;
                //effectiveProcessingPrice = (new Decimal(valuePrice).mul(new Decimal(1).plus(commission_rate))).div(new Decimal(value_leverage));
                effectiveProcessingPrice = ((new Decimal(valuePrice)).div(new Decimal(value_leverage))).plus((new Decimal(valuePrice)).mul(commission_rate));
                const totalValue = inputAmount.current.value ? new Decimal(inputAmount.current.value).mul(effectiveProcessingPrice) : 0;
                inputTotal.current.value = totalValue.toString();
            }
            else{
                inputAmount.current.value = "0";
                inputTotal.current.value = "0";
            }
        }
        formControl(formpost, "");
        setVolumeAndFee();
    }

    useEffect(() => {//lastUpdater güncellendiyse. amount, total, percentage değerlerinden biri olabilir. leverage değeri değişince tekrar hesaplama yaptırılıyor.
        if(!firstRunForPageOpening){
            setFirstRunForPageOpening(true);
            return;
        }
        directFormControl(false);
    }, [lastUpdater, leverageSliderValue]);

    useEffect(() => {//limit - ilk current price değerleri verilecek ya da post sonrası güncellenecek
        if(formTab == "limit" && !currentPriceForLimitAskingPrice && currentPrice != "NaN"){
            if(inputPrice.current.value == ""){
                inputPrice.current.value = formatNumberTrimTrailingZeros(currentPrice);
                if(inputAmount.current.value != ""){
                    directFormControl(false);
                }
            }
            setCurrentPriceForLimitAskingPrice(true);
        }
    }, [currentPriceForLimitAskingPrice, currentPrice, formTab]);

    useEffect(() => {//limit - selectedProduct güncellenirse current price değerleri tekrar verilecek
        if(formTab == "limit" && currentPriceForLimitAskingPrice && currentPrice != "NaN"){
            inputPrice.current.value = formatNumberTrimTrailingZeros(currentPrice);
            if(inputAmount.current.value != ""){
                directFormControl(false);
            }
        }
    }, [selectedProduct]);

    useEffect(() => {//limit - volume, fee
        if(formTab == "limit"){
            setVolumeAndFee();
        }
    }, [commission_rate, showPlacesAmount, showPlacesPrice]);

    useEffect(() => {//market - volume, fee
        if(formTab == "market"){
            setVolumeAndFee();
        }
    }, [currentPrice, commission_rate, showPlacesAmount, showPlacesPrice]);

    useEffect(() => {//buy - freeMargin
        if(futureBalance != null && inputInfo_step != null && inputInfo_min != null && futureBalance != "NaN" && futureCredit != "NaN" && inputInfo_step != "0" && inputInfo_min != "0"){
            let value_leverage = Number(leverageSliderValue);
            setFreeMarginPrice(calcFreeMarginPrice());
            setFreeMarginAmount(getFreeMargin(calcFreeMarginPrice(), value_leverage, currentPrice, commission_rate, inputInfo_step, inputInfo_min, 100));
        }
    }, [currentPrice, futureBalance, futureCredit, futureProcesses, commission_rate, inputInfo_step, inputInfo_min]);
    
    useEffect(() => {//selectedProduct güncellenirse input verileri sıfırlanıyor
        clearalldata();
        setTimeout(() => {
            clearalldanger();
        }, 0);
    }, [selectedProduct]);

    useEffect(() => {//leverage görünümü dışarıya tıklanınca kapanması için listener
        document.addEventListener('click', hiddenCustomizedSelect);
        return () => {//component kapanırken event listener temizleniyor
          document.removeEventListener('click', hiddenCustomizedSelect);
        };
    }, []);

    const hiddenCustomizedSelect = (e) => {//leverage görünümü dışarıya tıklanınca kapanması için
        if(leverageArea.current && !leverageArea.current.contains(e.target) && leverageAreaInput.current && !leverageAreaInput.current.contains(e.target)){
            leverageArea.current.classList.remove("show");
        }
        else if(leverageAreaInput.current && leverageAreaInput.current.contains(e.target)){
            leverageArea.current.classList.toggle("show");
        }
    };
    
    return(
        <form ref={form} className="section-tradebuysell-inner">
            <div className="section-tradebuysell-inner-mainsegment">
                <input type="hidden" name="product_id" value={productInfo[2]} />
                <div>
                    <div className="section-tradebuysell-inner-input">
                        <label className="section-tradebuysell-inner-input-title" onClick={() => inputPrice.current.focus()}>Price</label>
                        <input type="number" ref={inputPrice} step={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} min={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} data-name_info="Price" value={formTab == "market" ? formatNumberTrimTrailingZeros(currentPrice) : undefined} name={formTab == "limit" ? "asking_price" : undefined} className={formTab == "market" ? "locked" : undefined} onChange={() => directFormControl(false)} disabled={formTab == "market" ? true : false} />
                        <label className="section-tradebuysell-inner-input-label" onClick={() => inputPrice.current.focus()}>{productInfo[1] ? productInfo[1] : "NaN"}</label>
                    </div>
                    <div className="section-tradebuysell-inner-input">
                        <label className="section-tradebuysell-inner-input-title" onClick={() => inputAmount.current.focus()}>Amount</label>
                        <input type="number" ref={inputAmount} step={getMinAndStepWithShowPlaces(inputInfo_countPlaces)} min={inputInfo_min} data-name_info="Amount" name="count" onChange={() => {if(lastUpdater == "Amount"){directFormControl(false);}else{setLastUpdater("Amount");}}} />
                        <label className="section-tradebuysell-inner-input-label" onClick={() => inputAmount.current.focus()}>{productInfo[0] ? productInfo[0] : "NaN"}</label>
                    </div>
                    <div className="section-tradebuysell-inner-input" ref={leverageAreaInput}>
                        <label className="section-tradebuysell-inner-input-title">Leverage</label>
                        <input type="text" value={leverageSliderValue} name="leverage" readOnly />
                        <label className="section-tradebuysell-inner-input-label"></label>
                    </div>
                    <div className="leverage-area" ref={leverageArea}>
                        <div className="section-tradebuysell-inner-range">
                            <Slider min={futureLeverages ? futureLeverages[0] : 10} max={futureLeverages ? futureLeverages[futureLeveragesLastIndex] : 100} step={null} value={leverageSliderValue} onChange={(value) => {setLeverageSliderValue(value);directFormControl(false);}} marks={futureLeveragesMarks} />
                        </div>
                    </div>
                    <div className="section-tradebuysell-inner-input">
                        <label className="section-tradebuysell-inner-input-title" onClick={() => inputTotal.current.focus()}>Total</label>
                        <input type="number" ref={inputTotal} step={getMinAndStepWithShowPlaces(showPlacesPrice)} min={getMinAndStepWithShowPlaces(showPlacesPrice)} data-name_info="Total" onChange={() => {if(lastUpdater == "Total"){directFormControl(false);}else{setLastUpdater("Total");}}} />
                        <label className="section-tradebuysell-inner-input-label" onClick={() => inputTotal.current.focus()}>{productInfo[1] ? productInfo[1] : "NaN"}</label>
                    </div>
                    <div className="section-tradebuysell-inner-range">
                        <Slider min={0} max={100} step={1} value={percentageSliderValue} onChange={(value) => {setPercentageSliderValue(value);inputPercentage.current = value;if(lastUpdater == "Percentage"){directFormControl(false);}else{setLastUpdater("Percentage");}}} marks={{0: "0", 25: "25", 50: "50", 75: "75", 100: "100"}} />
                    </div>
                </div>
                <div>
                    <div className="section-tradebuysell-inner-input2">
                        <i className="fa-solid fa-plus" onClick={() => inputStopLoss.current.focus()} style={inputStopLossActive ? {display : "none"} : {}}></i>
                        <label className={inputStopLossActive ? "active" : undefined}>Stop Loss</label>
                        <input type="number" ref={inputStopLoss} className={inputStopLossActive ? undefined : "locked"} step={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} min={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} name={inputStopLossActive ? "stop_loss" : undefined} onChange={() => formControl(false, "")} onBlur={() => {if(inputStopLoss.current.value == ""){setInputStopLossActive(false);}}} onFocus={() => {setInputStopLossActive(true);}} onClick={() => {setInputStopLossActive(true);}} />
                        <span className="section-tradebuysell-inner-input-label" onClick={() => inputStopLoss.current.focus()}>{productInfo[1] ? productInfo[1] : "NaN"}</span>
                    </div>
                    {!inputStopLossDanger && stopLossPotentialProfitOrder !== null && (
                        <div className={stopLossPotentialProfitOrder.greaterThan(0) ? "potential-info success" : (stopLossPotentialProfitOrder.lessThan(0) ? "potential-info danger" : "potential-info")}>
                            {formatProfit(stopLossPotentialProfitOrder)}
                        </div>
                    )}
                    <div className={!inputStopLossDanger && stopLossPotentialProfitOrder !== null ? "section-tradebuysell-inner-input2 take-profit" : "section-tradebuysell-inner-input2"}>
                        <i className="fa-solid fa-plus" onClick={() => inputTakeProfit.current.focus()} style={inputTakeProfitActive ? {display : "none"} : {}}></i>
                        <label className={inputTakeProfitActive ? "active" : undefined}>Take Profit</label>
                        <input type="number" ref={inputTakeProfit} className={inputTakeProfitActive ? undefined : "locked"} step={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} min={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} name={inputTakeProfitActive ? "take_profit" : undefined} onChange={() => formControl(false, "")} onBlur={() => {if(inputTakeProfit.current.value == ""){setInputTakeProfitActive(false);}}} onFocus={() => {setInputTakeProfitActive(true);}} onClick={() => {setInputTakeProfitActive(true);}} />
                        <span className="section-tradebuysell-inner-input-label" onClick={() => inputTakeProfit.current.focus()}>{productInfo[1] ? productInfo[1] : "NaN"}</span>
                    </div>
                    {!inputTakeProfitDanger && takeProfitPotentialProfitOrder !== null && (
                        <div className={takeProfitPotentialProfitOrder.greaterThan(0) ? "potential-info success" : (takeProfitPotentialProfitOrder.lessThan(0) ? "potential-info danger" : "potential-info")}>
                            {formatProfit(takeProfitPotentialProfitOrder)}
                        </div>
                    )}
                </div>
                <div>
                    <div className="section-tradebuysell-inner-results">
                        <div className="section-tradebuysell-inner-results-available">
                            <span>Free Margin:</span>
                            <span><label>{formatValue3(freeMarginPrice, showPlacesPrice)}</label> <label>{productInfo[1]}</label> ≈ <label>{formatValue3(freeMarginAmount, showPlacesAmount)}</label> <label>{productInfo[0]}</label></span>
                        </div>
                        <div className="section-tradebuysell-inner-results-available">
                            <span>Volume:</span>
                            <span><label>{formatValue3(volumePrice, showPlacesPrice)}</label> <label>{productInfo[1]}</label> = <label>{formatValue3(volumeAmount, showPlacesAmount)}</label> <label>{productInfo[0]}</label></span>
                        </div>
                        <div>
                            <span>Fee:</span>
                            <span><label>{fee}</label> <label>{productInfo[1]}</label></span>
                        </div>
                        <div>
                            <span>Surety:</span>
                            <span><label>{surety}</label> <label>{productInfo[1]}</label></span>
                        </div>
                        <div>
                            <span>Total:</span>
                            <span><label>{formatValue3(volumePrice, showPlacesPrice)}</label> <label>{productInfo[1]}</label></span>
                        </div>
                    </div>
                    {userInfo && userInfo.future_permission && userInfo.future_permission == "True" ? 
                    <div className="section-tradebuysell-inner-buttons">
                        <button type="button" className="section-tradebuysell-inner-button buy" onClick={() => formControl(true, "buy")} disabled={submitFutureBuyLoading || submitFutureSellLoading}>{submitFutureBuyLoading ? <Loading /> : "BUY"}</button>
                        <button type="button" className="section-tradebuysell-inner-button sell" onClick={() => formControl(true, "sell")} disabled={submitFutureBuyLoading || submitFutureSellLoading}>{submitFutureSellLoading ? <Loading /> : "SELL"}</button>
                    </div>
                    : 
                    <button type="button" className="section-tradebuysell-inner-button sell with-link">
                        <Link to="/verification">You must be a level 2 approved account</Link>
                    </button>}
                    
                </div>
            </div>
        </form>
    );
}

const SectionFutureDemoTradeBuySell = () => {

    const selectedProduct = useSelector((state) => state.kprice.selectedProduct);

    const dispatch = useDispatch();
    const [tabValue, setTabValue] = useState("Market");

    const productsData = useSelector((state) => state.kprice.products.data);
    const productsFastData = useSelector((state) => state.kprice.productsFast.data);
    const futureProfiles = useSelector((state) => state.kprice.demoFutureProfiles.data);
    const futureProcesses = useSelector((state) => state.kprice.demoFutureProcesses.data);
    const currenciesData = useSelector((state) => state.kprice.currencies.data);

    const [futureBalance, setFutureBalance] = useState("NaN");
    const [futureCredit, setFutureCredit] = useState("NaN");

    useEffect(() => {//hesaplamalarda kullanılacak balance değeri
        if(futureProfiles){
            setFutureBalance(futureProfiles.balance ? futureProfiles.balance : 0);
            setFutureCredit(futureProfiles.credit ? futureProfiles.credit : 0);
        }
    }, [futureProfiles]);
    
    return(
        <div className="allsections allsections-border section-tradebuysell">
            <div className="section-tradebuysell-options">
                <span className={tabValue == "Market" ? "active" : undefined} onClick={() => setTabValue("Market")}>Market</span>
                <span className={tabValue == "Limit" ? "active" : undefined} onClick={() => setTabValue("Limit")}>Limit</span>
            </div>
            {tabValue == "Market" && <div className="section-tradebuysell-tab-area">
                <CreateForm selectedProduct={selectedProduct} productsData={productsData} productsFastData={productsFastData} currenciesData={currenciesData} futureBalance={futureBalance} futureCredit={futureCredit} formTab="market" />
            </div>}
            {tabValue == "Limit" && <div className="section-tradebuysell-tab-area">
                <CreateForm selectedProduct={selectedProduct} productsData={productsData} productsFastData={productsFastData} currenciesData={currenciesData} futureBalance={futureBalance} futureCredit={futureCredit} formTab="limit" />
            </div>}
        </div>
    )
    
}

export default SectionFutureDemoTradeBuySell;
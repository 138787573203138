import axios from "axios";
import { Auth } from 'aws-amplify';
import Decimal from 'decimal.js';

function adjustData_TVCandles(data) {
  const columnsToAdjust = ['timestamp', 'open', 'close', 'high', 'low', 'base_volume', 'quote_volume'];
  return data.map(row => {
    let newRow = { ...row }; 
    columnsToAdjust.forEach(col => {
      if (col in newRow) {  // sadece belirtilen sütunlar mevcutsa dönüştür
        newRow[col] = Number(newRow[col]);
      }
    });
    return newRow;
  });
}

function adjustData_products24h(data) {
  const columnsToAdjust = ['timestamp', 'close'];
  const adjustedData = { ...data };

  for (let productId in adjustedData.product_ids) {
    adjustedData.product_ids[productId] = adjustedData.product_ids[productId].map(row => {
      let newRow = { ...row };
      columnsToAdjust.forEach(col => {
        if (col in newRow) {
          newRow[col] = Number(newRow[col]);
        }
      });
      return newRow;
    });
  }
  
  return adjustedData;
}

function adjustData_products(data) {
  const columnsToAdjust = ['show_places', '24h_c', '24h_h', '24h_l', '24h_o', '24h_q', '24h_v', 'max_before_places', 'max_order_asking_price_places', 'max_order_count_places', 'min_order_count', 'min_order_precision', 'price', 'value'];
  const adjustedData = { ...data };

  //products içerisindeki objeleri dönüştür
  adjustedData.products = adjustedData.products.map(product => {
    let newProduct = { ...product };
    columnsToAdjust.forEach(col => {
      if (col in newProduct) {
        newProduct[col] = Number(newProduct[col]);
      }
    });

    // future_leverages dizilerini dönüştür
    if (newProduct.future_leverages) {
      newProduct.future_leverages = newProduct.future_leverages.map(Number);
    }

    return newProduct;
  });

  //currencies içerisindeki objeleri dönüştür
  adjustedData.currencies = adjustedData.currencies.map(currency => {
    let newCurrency = { ...currency };
    if ('show_places' in newCurrency) {
      newCurrency['show_places'] = Number(newCurrency['show_places']);
    }
    return newCurrency;
  });

  // variables içerisindeki objeleri dönüştür
  adjustedData.variables = adjustedData.variables.map(variable => {
    let newVariable = { ...variable };
    if (newVariable.name === 'spot_commission_rate' || newVariable.name === 'future_commission_rate' || newVariable.name === 'currency_max_before_places') {
      newVariable['value'] = Number(newVariable['value']);
    }
    return newVariable;
  });

  return adjustedData;
}

function adjustData_productLastTrades(data) {
  return Object.entries(data).reduce((acc, [key, value]) => {
    let newItem = { ...value };
    
    Object.keys(newItem).forEach(field => {
      if (field !== "product_id") {
        newItem[field] = Number(newItem[field]);
      }
    });

    acc[key] = newItem;
    return acc;
  }, {});
}

function adjustData_orderHistory(data) {
  const columnsToAdjust = ['entry_timestamp', 'asking_price', 'stop_loss', 'count', 'take_profit', 'realized_count', 'processing_price', 'currency_fee', 'to_currency_fee', 'result_timestamp', 'total'];
  
  const adjustFields = (item) => {
    let newItem = { ...item };
    columnsToAdjust.forEach(col => {
      if (col in newItem) {  // sadece belirtilen sütunlar mevcutsa dönüştür
        newItem[col] = Number(newItem[col]);
      }
    });
    return newItem;
  };

  let adjustedData = { ...data };

  if ('openOrders' in adjustedData) {
    adjustedData.openOrders = adjustedData.openOrders.map(adjustFields);
  }

  if ('closedOrders' in adjustedData) {
    adjustedData.closedOrders = adjustedData.closedOrders.map(adjustFields);
  }

  return adjustedData;
}

function adjustData_futureOrderHistory(data) {
  const columnsToAdjust = ['entry_timestamp', 'asking_price', 'stop_loss', 'count', 'take_profit', 'realized_count', 'processing_price', 'leverage', 'result_timestamp', 'total'];
  
  const adjustFields = (item) => {
    let newItem = { ...item };
    columnsToAdjust.forEach(col => {
      if (col in newItem) {  // sadece belirtilen sütunlar mevcutsa dönüştür
        newItem[col] = Number(newItem[col]);
      }
    });
    return newItem;
  };

  let adjustedData = { ...data };

  if ('openOrders' in adjustedData) {
    adjustedData.openOrders = adjustedData.openOrders.map(adjustFields);
  }

  if ('closedOrders' in adjustedData) {
    adjustedData.closedOrders = adjustedData.closedOrders.map(adjustFields);
  }

  return adjustedData;
}

function adjustData_futureProcesses(data) {
  const columnsToAdjust = ['available_count', 'available_surety', 'entry_count', 'entry_price', 'entry_surety', 'entry_timestamp', 'leverage', 'result_timestamp', 'process_profit'];

  const adjustArray = arr => arr.map(item => {
    let newItem = { ...item };
    columnsToAdjust.forEach(col => {
      if (col in newItem) {
        newItem[col] = Number(newItem[col]);
      }
    });
    return newItem;
  });

  return {
    ...data,
    activeProcesses: adjustArray(data.activeProcesses),
    deactiveProcesses: adjustArray(data.deactiveProcesses)
  };
}

function adjustData_futureProfiles(data) {
  const columnsToAdjust = ['balance', 'total_surety', 'credit', 'credit_deposit'];

  let adjustedData = { ...data };
  columnsToAdjust.forEach(col => {
    if (col in adjustedData) {
      adjustedData[col] = Number(adjustedData[col]);
    }
  });

  return adjustedData;
}

function adjustData_wallet(data) {
  const columnsToAdjust = ['total_count'];

  return {
    ...data,
    data: data.data.map(item => {
      let newItem = { ...item };
      columnsToAdjust.forEach(col => {
        if (col in newItem) {
          newItem[col] = Number(newItem[col]);
        }
      });
      return newItem;
    })
  };
}

function adjustData_withdraw(data) {
  const columnsToAdjust = ['fee_usd', 'min_amount_usd'];

  Object.keys(data.network_types).forEach(network => {
    columnsToAdjust.forEach(col => {
      if (col in data.network_types[network]) {
        data.network_types[network][col] = Number(data.network_types[network][col]);
      }
    });
  });

  return data;
}

function adjustData_favorites(data) {
  const columnsToAdjust = ['timestamp'];

  return data.map(item => {
    let newItem = { ...item };
    columnsToAdjust.forEach(col => {
      if (col in newItem) {
        newItem[col] = Number(newItem[col]);
      }
    });
    return newItem;
  });
}

function adjustData_notifications(data) {
  let adjustedData = { ...data };

  if ('notifications' in adjustedData) {
    adjustedData.notifications = adjustedData.notifications.map(notification => {
      if ('event_timestamp' in notification) {
        return { ...notification, event_timestamp: Number(notification.event_timestamp) };
      }
      return notification;
    });
  }

  if ('unread_notifications_length' in adjustedData) {
    adjustedData.unread_notifications_length = Number(adjustedData.unread_notifications_length);
  }

  return adjustedData;
}

function adjustData_withdrawDepositTransferHistory(data) {
  let adjustedData = { ...data };

  if ('history' in adjustedData) {
    adjustedData.history = adjustedData.history.map(item => {
      let newItem = { ...item };
      if ('event_timestamp' in newItem) {
        newItem.event_timestamp = Number(newItem.event_timestamp);
      }
      if ('result_timestamp' in newItem) {
        newItem.result_timestamp = Number(newItem.result_timestamp);
      }
      if ('count' in newItem) {
        newItem.count = Decimal(newItem.count).toFixed();
      }
      if ('fee_count' in newItem) {
        newItem.fee_count = Decimal(newItem.fee_count).toFixed();
      }
      return newItem;
    });
  }

  return adjustedData;
}

function adjustData_futureStats(data) {
  const columnsToAdjust = ['rank', 'total_profit', 'total_assets'];

  return data.map(item => {
    let newItem = { ...item };
    columnsToAdjust.forEach(col => {
      if (col in newItem) {
        newItem[col] = Number(newItem[col]);
      }
    });
    return newItem;
  });
}

export const kpriceApi = {
  //user info
  getUserInfo: async () => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/user`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
  },


  //notifications
  getNotifications: async () => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/notifications`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_notifications(res.data);
      return {
        data: resData
      };
    });
  },
  getNotificationsMore: async ({ log_id, event_timestamp }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/notifications?log_id=${log_id}&event_timestamp=${event_timestamp}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_notifications(res.data);
      return {
        data: resData
      };
    })
    .catch(function (error) {
    });
  },


  //products
  getProductIds: async () => {
    return axios
    .get(`https://api.kpricemarket.com/products`)
    .then((res) => {
      let resData = adjustData_products(res.data);
      return {
        data: resData
      };
    });
  },
  getProduct24h: async () => {
    return axios
    .get(`https://api.kpricemarket.com/products24h`)
    .then((res) => {
      let resData = adjustData_products24h(res.data);
      return {
        data: resData
      };
    });
  },
  getProductLastTrades: async ({ product }) => {
    return axios
    .get(`https://api.kpricemarket.com/data/last-trades?product_id=${product}`)
    .then((res) => {
      let resData = adjustData_productLastTrades(res.data);
      return {
        data: resData
      };
    })
    .catch(function (error) {
    });
  },


  //grafik candle verileri
  getTVCandles: async ({ product_id, resolution, fromTs, toTs, firstDataRequest }) => {
    let getTimeType;
    let timeCount;
    switch (resolution) {
      case "1":
        timeCount = "1";
        getTimeType = "min";
        break;
      case "5":
        timeCount = "5";
        getTimeType = "min";
        break;
      case "15":
        timeCount = "15";
        getTimeType = "min";
        break;
      case "30":
        timeCount = "30";
        getTimeType = "min";
        break;
      case "60":
        timeCount = "1";
        getTimeType = "h";
        break;
      case "120":
        timeCount = "2";
        getTimeType = "h";
        break;
      case "240":
        timeCount = "4";
        getTimeType = "h";
        break;
      case "360":
        timeCount = "6";
        getTimeType = "h";
        break;
      case "720":
        timeCount = "12";
        getTimeType = "h";
        break;
      case "1D":
        timeCount = "1";
        getTimeType = "d";
        break;
      case "1W":
        timeCount = "1";
        getTimeType = "w";
        break;
      case "1M":
        timeCount = "1";
        getTimeType = "mo";
        break;
      default:
        throw new Error("Invalid resolution value");
    }

    let url = `https://api.kpricemarket.com/data?product_id=${product_id}&time_value=${timeCount}&time_interval=${getTimeType}&fromTs=${fromTs}`;
    if (!firstDataRequest) {
      url += `&toTs=${toTs}`;
    }

    // API isteğini yap
    return axios.get(url)
    .then((res) => {
      let resData = adjustData_TVCandles(res.data.data);
      return {
        ...res,
        data: resData.sort((a, b) => a.timestamp - b.timestamp),
      };
    });

  },


  //order history
  getOrderHistory: async ({ timeInterval }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/spotorder?time_interval=${timeInterval}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_orderHistory(res.data);
      return {
        data: resData
      };
    });
  },


  //future order history
  getFutureOrderHistory: async ({ timeInterval }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/futureorder?time_interval=${timeInterval}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_futureOrderHistory(res.data);
      return {
        data: resData
      };
    });
  },


  //future process
  getFutureProcesses: async ({ timeInterval }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/futureprocess?time_interval=${timeInterval}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_futureProcesses(res.data);
      return {
        data: resData
      };
    });
  },


  //future profile
  getFutureProfiles: async () => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/futureprofile`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_futureProfiles(res.data);
      return {
        data: resData
      };
    });
  },


  //demo future order history
  getDemoFutureOrderHistory: async ({ timeInterval }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/demo/futureorder?time_interval=${timeInterval}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_futureOrderHistory(res.data);
      return {
        data: resData
      };
    });
  },


  //demo future process
  getDemoFutureProcesses: async ({ timeInterval }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/demo/futureprocess?time_interval=${timeInterval}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_futureProcesses(res.data);
      return {
        data: resData
      };
    });
  },


  //demo future profile
  getDemoFutureProfiles: async () => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/demo/futureprofile`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_futureProfiles(res.data);
      return {
        data: resData
      };
    });
  },


  //demo future active competition
  getDemoFutureActiveCompetition: async () => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/demo/futureactivecompetition`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      return {
        data: res.data
      };
    });
  },

  
  //wallet
  getWallet: async () => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/wallet`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_wallet(res.data);
      return {
        data: resData
      };
    });
  },


  //withdraw
  getWithdraw: async () => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/withdraw`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_withdraw(res.data);
      return {
        data: resData
      };
    });
  },


  //deposit
  getDeposit: async () => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/deposit`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
  },


  //withdraw history
  getWithdrawHistory: async ({ currency }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/withdraw/history?currency=${currency}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_withdrawDepositTransferHistory(res.data);
      return {
        data: resData
      };
    })
    .catch(function (error) {
    });
  },
  getWithdrawHistoryMore: async ({ currency, log_id, event_timestamp }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/withdraw/history?currency=${currency}&log_id=${log_id}&event_timestamp=${event_timestamp}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_withdrawDepositTransferHistory(res.data);
      return {
        data: resData
      };
    })
    .catch(function (error) {
    });
  },


  //deposit history
  getDepositHistory: async ({ currency }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/deposit/history?currency=${currency}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_withdrawDepositTransferHistory(res.data);
      return {
        data: resData
      };
    })
    .catch(function (error) {
    });
  },
  getDepositHistoryMore: async ({ currency, log_id, event_timestamp }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/deposit/history?currency=${currency}&log_id=${log_id}&event_timestamp=${event_timestamp}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_withdrawDepositTransferHistory(res.data);
      return {
        data: resData
      };
    })
    .catch(function (error) {
    });
  },


  //transfer history
  getTransferHistory: async ({ currency }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/transfer/history?currency=${currency}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_withdrawDepositTransferHistory(res.data);
      return {
        data: resData
      };
    })
    .catch(function (error) {
    });
  },
  getTransferHistoryMore: async ({ currency, log_id, event_timestamp }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/transfer/history?currency=${currency}&log_id=${log_id}&event_timestamp=${event_timestamp}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_withdrawDepositTransferHistory(res.data);
      return {
        data: resData
      };
    })
    .catch(function (error) {
    });
  },


  //favorites
  getFavorites: async () => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/favorites`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_favorites(res.data);
      return {
        data: resData
      };
    });
  },


  //future stats
  getFutureStats: async ({ code }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/futureprocess/stats?stats_type=${code}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_futureStats(res.data);
      return {
        data: resData
      };
    });
  },


  //future demo stats
  getFutureDemoStats: async ({ code }) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return axios
    .get(`https://api.kpricemarket.com/demo/futureprocess/stats?stats_type=${code}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((res) => {
      let resData = adjustData_futureStats(res.data);
      return {
        data: resData
      };
    });
  },
};

import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import campaign0dark from "../../images/campaign/campaign0dark.png";
import campaign0light from "../../images/campaign/campaign0light.png";
import campaign50dark from "../../images/campaign/campaign50dark.png";
import campaign50light from "../../images/campaign/campaign50light.png";
import FDC10000dark from "../../images/campaign/FDC10000dark.png";
import FDC10000light from "../../images/campaign/FDC10000light.png";
import robotref from "../../images/campaign/robot-ref.png";
import { Link } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import SectionTransferPopupContent from '../section_transfer_popup_content';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const SectionQuickActions = () => {

    const selectedProduct = useSelector((state) => state.kprice.selectedProduct);
    const [displayCampaignCode, setDisplayCampaignCode] = useState(false);//kampanya kodunun görünürlüğünü tutuyor
    const campaignArea = useRef(null);

    const [displayTransferDialog, setDisplayTransferDialog] = useState(false);

    useEffect(() => {//animasyon için - sekmeler ve closed orders süresi güncellenince çalışacak
        if(campaignArea.current){
            campaignArea.current.style.animation = "none";
            let tempForAnimation = campaignArea.current.offsetHeight;
            campaignArea.current.style.animation = "open-animation 1s";
        }
    }, [displayCampaignCode]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return(
        <div id="section-quickactions" className="allsections allsections-border section-quickactions">
            <Dialog header="Transfer" visible={displayTransferDialog} draggable={false} resizable={false} className="section-walletlist-dialog transfer" onHide={() => setDisplayTransferDialog(false)}>
                <SectionTransferPopupContent />
            </Dialog>
            <div className="section-quickactions-title">
                <label>Quick Actions</label>
            </div>
            <Slider {...settings}>
                <div>
                    <div className="slide-item">
                        <div className="slide-image slide-image-dark"><img src={FDC10000dark} /></div>
                        <div className="slide-image slide-image-light"><img src={FDC10000light} /></div>
                        <div className="slide-bottom">
                            <span>Demo Future Trading Competition</span>
                            <label>7.500 USDT Prize Pool</label>
                            <Link to="/future-demo-competition-10000">Join Now</Link>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="slide-item">
                        <div className="slide-image slide-image-dark"><img src={campaign50dark} /></div>
                        <div className="slide-image slide-image-light"><img src={campaign50light} /></div>
                        <div className="slide-bottom">
                            <span>FUTURE50</span>
                            <label>50% credit campaign in Future</label>
                            <Link to="/campaign50">Join the Campaign</Link>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="slide-item">
                        <div className="slide-image slide-image-dark"><img src={campaign0dark} /></div>
                        <div className="slide-image slide-image-light"><img src={campaign0light} /></div>
                        <div className="slide-bottom">
                            <span>Withdraw</span>
                            <label>0% commission on withdrawals</label>
                            <Link to="/withdraw">Withdraw</Link>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="slide-item">
                        <div className="slide-image"><img src={robotref} /></div>
                        <div className="slide-bottom">
                            <span>Reference</span>
                            <label>Invite your friends and earn a reference bonus.</label>
                            <Link to="/profile/reference">Invite Code</Link>
                        </div>
                    </div>
                </div>
            </Slider>
            <div className="section-quickactions-buttons">
                <Link to="/deposit" className="section-quickactions-button1">
                    <div>
                        <i className="fa-solid fa-money-bill"></i>
                        <span>Deposit</span>
                    </div>
                    <i className="fa-solid fa-chevron-right"></i>
                </Link>
                <Link to="/future-demo" className="section-quickactions-button1 section-quickactions-button1-second" onClick={() => setDisplayTransferDialog(true)}>
                    <div>
                        <i className="fa-solid fa-flag-checkered"></i>
                        <span>2025 Competition</span>
                    </div>
                    <i className="fa-solid fa-chevron-right"></i>
                </Link>
                <Link to="/withdraw" className="section-quickactions-button2">
                    <i className="fa-solid fa-hand-holding-dollar"></i>
                    <span>Withdraw</span>
                </Link>
                <Link to={`/trade?market=${selectedProduct}`} className="section-quickactions-button2">
                    <i className="fa-solid fa-arrow-right-arrow-left"></i>
                    <span>Trade</span>
                </Link>
                <Link to="/future" className="section-quickactions-button2">
                    <i className="fa-solid fa-arrow-trend-up"></i>
                    <span>Future</span>
                </Link>
                <Link to="/profile" className="section-quickactions-button2">
                    <i className="fa-solid fa-user"></i>
                    <span>Profile</span>
                </Link>
                <Link to="/verification" className="section-quickactions-button2">
                    <i className="fa-solid fa-user-check"></i>
                    <span>KYC</span>
                </Link>
                <Link to="/profile/security" className="section-quickactions-button2">
                <i className="fa-solid fa-shield"></i>
                    <span>Security</span>
                </Link>
                <a className="section-quickactions-button2" onClick={() => setDisplayTransferDialog(true)}>
                    <i className="fa-solid fa-coins"></i>
                    <span>Transfer</span>
                </a>
                <Link to="/support" className="section-quickactions-button2">
                    <i className="fa-solid fa-headset"></i>
                    <span>Support</span>
                </Link>
            </div>
        </div>
    )

}

export default SectionQuickActions;
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import { onPageFutureStatsOpen } from "../Reducer/kpriceReducer";
import withLoadingData from "../Container/withLoadingData";
import isEqual from "react-fast-compare";
import Loading from "../components/Global/Loading";
import "./page_dashboard.css";

const FutureRanking = ({ isFutureStatsLoading }) => {

    const dispatch = useDispatch();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const location = useLocation();
    const query = useQuery();
    
    const stats = useSelector((state) => state.kprice.futureStats.data);
    const [statsData, setStatsData] = useState(null);

    const [code, setCode] = useState(query.get("stats_type"));

    useEffect(() => {//stats güncellenince
        if(stats && stats.length > 0){
            setStatsData(stats.slice(0, 10));
        }
        else{
            setStatsData([]);
        }
    }, [stats]);

    useEffect(() => {//link değişirse
        const newCode = query.get("stats_type");
        if(newCode != code){
            setCode(newCode);
        }
    }, [location]);

    useEffect(() => {
        if(code != "" && code != null){
            dispatch(onPageFutureStatsOpen({ code }));
        }
        else{
            setStatsData([]);
        }
    }, [code]);

    const tableColumns = useMemo(() => [//tablo bilgileri
        { accessorKey: 'rank', header: 'Rank' },
        { accessorKey: 'item_customer_id', header: 'Customer ID' },
        { accessorKey: 'total_profit', header: 'Total Profit' },
    ], []);
    
    return(
        <div id="main" className="main">
            <div className="dashboard-sections">
                <div className="single-section">
                    <div className="allsections allsections-border section-ranking">
                        <div id="section-currencystatus" className="section-currencystatus">
                            {(isFutureStatsLoading != false || statsData == null) ? (//undefined ya da true geldiyse loading gösteriliyor, false ise grafik
                                <div className="currencyStatusLoading"><Loading /></div>
                            ) : (
                                <div className="column3">
                                    <MaterialReactTable 
                                        columns={tableColumns} 
                                        data={statsData} 
                                        enablePagination={false}
                                        enableRowVirtualization={true}
                                        enableColumnActions={false}
                                        enableSorting={false}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    
}

export default withLoadingData()(React.memo(FutureRanking, isEqual));
import React, { useEffect, useState, useMemo, useRef } from "react";
import { MaterialReactTable } from 'material-react-table';
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
import { onDemoFutureOrderHistoryChangeTimeInterval, onDemoFutureProcessesChangeTimeInterval, onChangeDemoFutureProcesses } from "../../Reducer/kpriceReducer";
import axios from 'axios';
import { Auth } from 'aws-amplify';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import withLoadingData from "../../Container/withLoadingData";
import Loading from "../../components/Global/Loading";
import isEqual from "react-fast-compare";
import moment from "moment-timezone";
import Decimal from 'decimal.js';
import { debounce } from 'lodash';
import Slider from 'rc-slider';
import SectionLoadFundsPopupContent from '../section_load_funds_popup_content';
import { formatValuePriceControl, formatValue3, formatValue4, formatValue5, getValueWithShowPlaces, setInputValueByShowPlacesFunction, getMinAndStepWithShowPlaces, valueControlWithDigitLimit } from '../../Lib/calculationFunctions';

const SectionFutureDemoOrderHistory = ({ isDemoFutureOrderHistoryLoading, isDemoFutureProcessesLoading, isDemoFutureProfilesLoading }) => {

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [viewType, setViewType] = useState("pc");
    const [viewTypeOpenOrdersSpecial, setViewTypeOpenOrdersSpecial] = useState("pc");
    const [activeOption, setActiveOption] = useState("Positions");//order history açık sekme değeri
    const selectedFutureOrderHistoryTimeInterval = useSelector((state) => state.kprice.selectedDemoFutureOrderHistoryTimeInterval);
    const [closedOrdersTime, setClosedOrdersTime] = useState(selectedFutureOrderHistoryTimeInterval ? selectedFutureOrderHistoryTimeInterval : "last1w");//closed orders zaman değeri
    const [closedOrdersTimeSelectDisplay, setClosedOrdersTimeSelectDisplay] = useState(false);//closed orders zaman select menüsünün görünürlüğü
    const closedOrdersTimeSelect = useRef(null);//closed orders zaman select'i
    const closedOrdersTimeSelectMenu = useRef(null);//closed orders zaman select menüsü
    const orderHistoryArea = useRef(null);//tablo alanı
    const userInfo = useSelector((state) => state.kprice.userInfo.data);
    
    const [displayEditDialog, setDisplayEditDialog] = useState(false);//order düzenleme popup görünürlüğünü tutuyor
    const [displayConfirm, setDisplayConfirm] = useState(false);//order iptal etme işlemi öncesinde çıkan popup görünürlüğünü tutuyor
    const [currentEditOrder, setCurrentEditOrder] = useState(null);//order iptal etme aşamasında iptal edilecek orderid bilgisini tutuyor
    const [currentDeleteOrderId, setCurrentDeleteOrderId] = useState("");//order iptal etme aşamasında iptal edilecek orderid bilgisini tutuyor
    const [editOrderFutureLeverages, setEditOrderFutureLeverages] = useState(null);
    const [editOrderFutureLeveragesMarks, setEditOrderFutureLeveragesMarks] = useState({});
    const [editOrderFutureLeveragesLastIndex, setEditOrderFutureLeveragesLastIndex] = useState(0);
    const [editOrderLeverageSliderValue, setEditOrderLeverageSliderValue] = useState(100);
    const leverageArea = useRef(null);
    const leverageAreaInput = useRef(null);

    const [submitOrderUpdateLoading, setSubmitOrderUpdateLoading] = useState(false);
    const [submitProcessesUpdateLoading, setSubmitProcessesUpdateLoading] = useState(false);
    const [submitProcessesCloseLoading, setSubmitProcessesCloseLoading] = useState(false);

    useEffect(() => {//closed orders süresi güncellenince çalışacak
        if(selectedFutureOrderHistoryTimeInterval != closedOrdersTime){
            const newTimeInterval = closedOrdersTime;
            dispatch(onDemoFutureOrderHistoryChangeTimeInterval({ newTimeInterval }));
        }
    }, [closedOrdersTime]);

    useEffect(() => {//animasyon için - sekmeler ve closed orders süresi güncellenince çalışacak
        if(orderHistoryArea.current){
            orderHistoryArea.current.style.animation = "none";
            let tempForAnimation = orderHistoryArea.current.offsetHeight;
            orderHistoryArea.current.style.animation = "open-animation 1s";
        }
    }, [activeOption, closedOrdersTime]);

    //sütuna özel sıralama için gerekli fonksiyonlar - başlangıç
    const fuzzy = (rowA, rowB, columnId) => {
        let dir = 0;
        if (rowA.columnFiltersMeta[columnId]) {
          dir = compareItems(
            rowA.columnFiltersMeta[columnId],
            rowB.columnFiltersMeta[columnId]
          );
        }
        
        return dir === 0
          ? alphanumComparator(rowA, rowB)
          : dir;
    };

    function alphanumComparator(a, b) {
        const regex = /\d+|\D+/g;
      
        const aParts = String(a).match(regex);
        const bParts = String(b).match(regex);
      
        for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
          if (aParts[i] !== bParts[i]) {
            const isNumA = !isNaN(aParts[i]);
            const isNumB = !isNaN(bParts[i]);
      
            if (isNumA && isNumB) {
              return parseInt(aParts[i], 10) - parseInt(bParts[i], 10);
            } else if (isNumA) {
              return -1; // Sayılar metinlerden önce gelsin
            } else if (isNumB) {
              return 1;
            } else {
              return aParts[i].localeCompare(bParts[i]);
            }
          }
        }
        return aParts.length - bParts.length;
    }

    function compareItems(itemA, itemB) {
        if (itemA > itemB) return 1;
        if (itemA < itemB) return -1;
        return 0;
    }
    //sütuna özel sıralama için gerekli fonksiyonlar - son
    
    const productData = useSelector((state) => state.kprice.products.data);
    const productSlowData = useSelector((state) => state.kprice.productsSlow.data);
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const orderHistory = useSelector((state) => state.kprice.demoFutureOrderHistory.data);
    const orderHistoryNewData = orderHistory.updateData;
    const isFirstRenderForNewData = useRef(true);
    const [openOrders, setOpenOrders] = useState([]);
    const [closedOrders, setClosedOrders] = useState([]);
    const showPlacesUSDT = currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places ? currenciesData["USDT"].show_places : null;

    const [currentProductId, setCurrentProductId] = useState(null);//düzenlenecek order için güncel product id bilgisi. step, min gibi değerlerin güncel tutulması için
    const [inputNumberMinValue, setInputNumberMinValue] = useState(0);
    const [inputInfo_askingPricePlaces, setInputInfo_askingPricePlaces] = useState(0);
    const [inputInfo_countPlaces, setInputInfo_countPlaces] = useState(0);
    const [inputInfo_maxBeforePlaces, setInputInfo_maxBeforePlaces] = useState(0);

    useEffect(() => {//eğer tablo boş dönerse boş olarak güncellenebilsin diye
        setOpenOrders(orderHistory.openOrders ? orderHistory.openOrders : []);
        setClosedOrders(orderHistory.closedOrders ? orderHistory.closedOrders : []);
    }, [orderHistory]);

    const EditIconForm = ({ order }) => {//her waiting satırında gözüken order düzenleme butonu
        return (
            <Tooltip title="Edit" className="editicon" onClick={() => {setCurrentEditOrder(order);setDisplayEditDialog(true);}}>
                <IconButton>
                    <EditIcon />
                </IconButton>
            </Tooltip>
        );
    }

    const CancelIconForm = ({ order, index }) => {//her waiting satırında gözüken order iptal etme butonu
        return (
            <form id={`form-cancel${index}`} className="canceliconform">
                <Tooltip title="Cancel" className="cancelicon" onClick={() => {setCurrentDeleteOrderId(`form-cancel${index}`);setDisplayConfirm(true);}}>
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
                <input type="hidden" value="remove" name="update_type"/>
                <input type="hidden" value={order.order_id} name="order_id"/>
            </form>
        );
    }

    const cancelIconClick = async (formid) => {//iptal butonuna tıklandığında
        setDisplayConfirm(false);
        let formforcancel = document.getElementById(formid);
        
        const data = new FormData(formforcancel);
        const updateData = Object.fromEntries(data);

        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();
        
        axios.post('https://api.kpricemarket.com/demo/futureorder/update', 
            JSON.stringify(updateData),
            { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
        )
        .then(function (response) {
            if(response.status == "200" && response.data.message == "Data updated successfully"){
                toast.success("Order deleted successfully!");
                orderHistoryTableUpdate(response.data.data);
            }
            else{
                toast.error(`There was a problem deleting the order!${response.data.message ? ` (${response.data.message})` : ""}`);
            }
        })
        .catch(function (error) {
            toast.error(`There was a problem deleting the order!${error.response.data.error ? ` (${error.response.data.error})` : ""}`);
        });
    }

    const editDialogUpdate = async (formpost, event) => {//update input içinde bilgi değiştiğinde ve post edileceğinde
        setSubmitOrderUpdateLoading(true);
        let formvalid = true;
        const form = document.getElementById("edit-order-form");
        const eventValue = event ? event.value : null;

        let dangerElements = form.getElementsByTagName("input");
        for (let i = 0; i < dangerElements.length; i++) {
            dangerElements[i].classList.remove("danger");
            setInputStopLossDangerOrder(false);
            setInputTakeProfitDangerOrder(false);
        }

        let inputElements = form.getElementsByTagName("input");

        if(eventValue){
            if(eventValue.includes('-') || eventValue.includes('e')){
                event.value = "";
            }
            if(eventValue == "00"){
                event.value = "0";
            }
            if(eventValue[0] && eventValue[1]){
                if(eventValue[0] == "0" && eventValue[1] != "." && eventValue[1] != ","){
                    event.value = eventValue.slice(1);
                }
            }
        }
        else{
            if(inputElements[0].value.includes('-') || inputElements[0].value.includes('e')){
                inputElements[0].value = "";
            }
            if(inputElements[1].value.includes('-') || inputElements[1].value.includes('e')){
                inputElements[1].value = "";
            }
            if(inputElements[3] != null && inputElements[4] != null && inputElements[3].type == "number" && inputElements[4].type == "number"){
                if(inputElements[3].value.includes('-') || inputElements[3].value.includes('e')){
                    inputElements[3].value = "";
                }

                if(inputElements[4].value.includes('-') || inputElements[4].value.includes('e')){
                    inputElements[4].value = "";
                }
            }
        }

        if(inputElements[0].value == ""){
            inputElements[0].classList.add("danger");
            formvalid = false;
            if(formpost){toast.error("Please enter value for Price!");}
        }
        else if(Number(inputElements[0].value) < Number(inputElements[0].min)){
            inputElements[0].classList.add("danger");
            formvalid = false;
            if(formpost){toast.error("The value entered for Price must be greater than or equal to " + inputElements[0].min + "!");}
        }
        else if(!valueControlWithDigitLimit(inputElements[0].value, inputInfo_maxBeforePlaces)){
            inputElements[0].classList.add("danger");
            formvalid = false;
            if(formpost){toast.error("The integer part of the value you enter for Price cannot exceed " + inputInfo_maxBeforePlaces + " digits!");}
        }

        if(inputElements[1].value == ""){
            inputElements[1].classList.add("danger");
            formvalid = false;
            if(formpost){toast.error("Please enter value for Count!");}
        }
        else if(Number(inputElements[1].value) < Number(inputElements[1].min)){
            inputElements[1].classList.add("danger");
            formvalid = false;
            if(formpost){toast.error("The value entered for Count must be greater than or equal to " + inputElements[1].min + "!");}
        }
        else if(!valueControlWithDigitLimit(inputElements[1].value, inputInfo_maxBeforePlaces)){
            inputElements[1].classList.add("danger");
            formvalid = false;
            if(formpost){toast.error("The integer part of the value you enter for Count cannot exceed " + inputInfo_maxBeforePlaces + " digits!");}
        }
        
        if(formvalid && inputElements[3] != null && inputElements[4] != null && inputElements[3].type == "number" && inputElements[4].type == "number"){//price ve count uygunsa, stop loss ve take profit değerleri var ise kontrol edilecek
            let currentOrderType = currentEditOrder.type == "limitbuy" || currentEditOrder.type == "marketbuy" ? "buy" : "sell";
            
            if((currentOrderType == "buy" && parseFloat(inputElements[0].value) <= parseFloat(inputElements[3].value)) || (currentOrderType == "sell" && parseFloat(inputElements[0].value) >= parseFloat(inputElements[3].value))){//stop loss için
                inputElements[3].classList.add("danger");
                setInputStopLossDangerOrder(true);
                if(formpost){
                    if(currentOrderType == "buy"){
                        toast.error("Stop Loss value must be less than Asking Price!");
                    }
                    else if(currentOrderType == "sell"){
                        toast.error("Stop Loss value must be bigger than Asking Price!");
                    }
                }
                formvalid = false;
            }
            else if(!valueControlWithDigitLimit(inputElements[3].value, inputInfo_maxBeforePlaces)){
                inputElements[3].classList.add("danger");
                setInputStopLossDangerOrder(true);
                formvalid = false;
                if(formpost){toast.error("The integer part of the value you enter for Stop Loss cannot exceed " + inputInfo_maxBeforePlaces + " digits!");}
            }
            else if(inputElements[3].value != "" && 0 >= Number(inputElements[3].value)){
                inputElements[3].classList.add("danger");
                setInputStopLossDangerOrder(true);
                formvalid = false;
                if(formpost){toast.error("Stop Loss value must be bigger than 0!");}
            }

            if((currentOrderType == "buy" && parseFloat(inputElements[0].value) >= parseFloat(inputElements[4].value)) || (currentOrderType == "sell" && parseFloat(inputElements[0].value) <= parseFloat(inputElements[4].value))){//take profit için
                inputElements[4].classList.add("danger");
                setInputTakeProfitDangerOrder(true);
                if(formpost){
                    if(currentOrderType == "buy"){
                        toast.error("Take Profit value must be bigger than Asking Price!");
                    }
                    else if(currentOrderType == "sell"){
                        toast.error("Take Profit value must be less than Asking Price!");
                    }
                }
                formvalid = false;
            }
            else if(!valueControlWithDigitLimit(inputElements[4].value, inputInfo_maxBeforePlaces)){
                inputElements[4].classList.add("danger");
                setInputTakeProfitDangerOrder(true);
                formvalid = false;
                if(formpost){toast.error("The integer part of the value you enter for Take Profit cannot exceed " + inputInfo_maxBeforePlaces + " digits!");}
            }
            else if(inputElements[4].value != "" && 0 >= Number(inputElements[4].value)){
                inputElements[4].classList.add("danger");
                setInputTakeProfitDangerOrder(true);
                formvalid = false;
                if(formpost){toast.error("Take Profit value must be bigger than 0!");}
            }
        }

        calculateStopLossPotentialProfitOrder();
        calculateTakeProfitPotentialProfitOrder();

        if(formvalid && formpost){//uygunsa post edilecek
            const data = new FormData(form);
            const updateData = Object.fromEntries(data);
            updateData.leverage = String(editOrderLeverageSliderValue);

            const keysToCheck = ['asking_price', 'count', 'leverage', 'stop_loss', 'take_profit'];
            let isDifferent = false;

            for (let key of keysToCheck) {
                let newUpdateValue = updateData[key];
                let currentValue = currentEditOrder[key];

                if(newUpdateValue == "" || newUpdateValue == undefined){
                    newUpdateValue = null;
                }
                if(currentValue == "" || currentValue == undefined){
                    currentValue = null;
                }

                if (newUpdateValue != currentValue && (newUpdateValue != null || currentValue != null)) {
                    isDifferent = true;
                    break;
                }
            }
            
            if(isDifferent){//popup içindeki bilgiler değiştirilmiş mi
                const session = await Auth.currentSession();
                const jwtToken = session.getIdToken().getJwtToken();

                axios.post('https://api.kpricemarket.com/demo/futureorder/update', 
                    JSON.stringify(updateData),
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
                )
                .then(function (response) {
                    if(response.status == "200" && response.data.message == "Data updated successfully"){
                        toast.success("Order updated successfully!");
                        orderHistoryTableUpdate(response.data.data);
                    }
                    else{
                        toast.error(`There was a problem updating the order!${response.data.message ? ` (${response.data.message})` : ""}`);
                    }
                    setSubmitOrderUpdateLoading(false);
                })
                .catch(function (error) {
                    toast.error(`There was a problem updating the order!${error.response.data.error ? ` (${error.response.data.error})` : ""}`);
                    setSubmitOrderUpdateLoading(false);
                });
            }
            else{
                setSubmitOrderUpdateLoading(false);
            }
            setDisplayEditDialog(false);
        }
        else{
            setSubmitOrderUpdateLoading(false);
        }
    }

    const orderHistoryTableUpdateAnimation = (orderid) => {
        let visibleRows = document.getElementsByClassName("MuiTableRow-root");//tabloda görünen veriler
        for (let i = 0; i < visibleRows.length; i++) {
            if(visibleRows[i].innerHTML.includes(orderid)){
                let updatedRow = visibleRows[i];
                updatedRow.style.animation = "close-animation 1s";
                updatedRow.addEventListener('animationend', () => {
                    updatedRow.style.animation = "";
                });
            }
        }
    }

    const [triggerAnimation, setTriggerAnimation] = useState(null);

    useEffect(() => {
        if (triggerAnimation) {
            orderHistoryTableUpdateAnimation(triggerAnimation);
            setTriggerAnimation(null);
        }
    }, [triggerAnimation]);

    const orderHistoryTableUpdate = (newOrder) => {
        if(newOrder != undefined && Object.keys(newOrder).length != 0){
            let isOrderUpdate = false;//bulunan order'ın state'i ile gelen bilginin state'i eşit ise bulunan veri güncellenecek mi diye karşılaştırılsın
            let newDataState = newOrder.state != undefined ? newOrder.state : null;

            if(newDataState){//new order içinde date bilgisi gelmezse burada oluşturuyoruz
                if(newOrder.entry_date == undefined && newOrder.entry_timestamp != undefined && (newDataState == "waiting" || newDataState == "processing")){
                    newOrder["entry_date"] = moment(Number(newOrder.entry_timestamp)).tz(timeZone).format("YYYY-MM-DD HH:mm");
                }
                else if(newOrder.result_date == undefined && newOrder.result_timestamp != undefined && (newDataState == "approved" || newDataState == "denied")){
                    newOrder["result_date"] = moment(Number(newOrder.result_timestamp)).tz(timeZone).format("YYYY-MM-DD HH:mm");
                }
            }

            let existInOpen = false;//open içinde var mı
            let existInOpenIndex = null;//open içinde varsa index'i
            let existInOpenState = null;//open içinde varsa state'i
            
            let existInClosed = false;//closed içinde var mı
            let existInClosedIndex = null;//closed içinde varsa index'i
            let existInClosedState = null;//closed içinde varsa state'i

            for (let i = openOrders.length - 1; i >= 0; i--) {//open içinde var mı
                if (openOrders[i].order_id == newOrder.order_id) {
                    existInOpen = true;
                    existInOpenIndex = i;
                    existInOpenState = openOrders[i].state;
                }
            }

            for (let i = closedOrders.length - 1; i >= 0; i--) {//closed içinde var mı
                if (closedOrders[i].order_id == newOrder.order_id) {
                    existInClosed = true;
                    existInClosedIndex = i;
                    existInClosedState = closedOrders[i].state;
                }
            }

            //silme işlemleri
            if(existInOpen){//open içinde var ise
                if(existInOpenState == newDataState){
                    isOrderUpdate = true;
                }
                else{
                    if(!(existInOpenState == "processing" && newDataState == "waiting")){//open içinde processing var ama yeni veri waiting olarak gelmiş ise silmiyoruz, bu durum dışındakiler silinecek
                        if(newDataState == null || newDataState == "cancelled"){//animasyon için cancelled bilgilerde 1 saniye bekletiyoruz
                            setTriggerAnimation(newOrder.order_id);
                            setTimeout(() => {
                                setOpenOrders(prevOrders => {
                                    if (prevOrders[existInOpenIndex]?.order_id === newOrder.order_id) {
                                        return [
                                            ...prevOrders.slice(0, existInOpenIndex),
                                            ...prevOrders.slice(existInOpenIndex + 1)
                                        ];
                                    }
                                    return prevOrders;
                                });
                            }, 1000);
                        }
                        else{
                            setOpenOrders(prevOrders => {
                                if (prevOrders[existInOpenIndex]?.order_id === newOrder.order_id) {
                                    return [
                                        ...prevOrders.slice(0, existInOpenIndex),
                                        ...prevOrders.slice(existInOpenIndex + 1)
                                    ];
                                }
                                return prevOrders;
                            });
                        }
                        existInOpen = false;
                    }
                }
            }
            else if(existInClosed){//closed içinde var ise
                if(existInClosedState == newDataState){
                    isOrderUpdate = true;
                }
                else{
                    if(newDataState != "waiting" && newDataState != "processing"){//waiting ve processing gelmediyse siliyoruz
                        if(newDataState == null || newDataState == "cancelled"){//animasyon için cancelled bilgilerde 1 saniye bekletiyoruz
                            setTriggerAnimation(newOrder.order_id);
                            setTimeout(() => {
                                setClosedOrders(prevOrders => {
                                    if (prevOrders[existInClosedIndex]?.order_id === newOrder.order_id) {
                                        return [
                                            ...prevOrders.slice(0, existInClosedIndex),
                                            ...prevOrders.slice(existInClosedIndex + 1)
                                        ];
                                    }
                                    return prevOrders;
                                });
                            }, 1000);
                        }
                        else{
                            setClosedOrders(prevOrders => {
                                if (prevOrders[existInClosedIndex]?.order_id === newOrder.order_id) {
                                    return [
                                        ...prevOrders.slice(0, existInClosedIndex),
                                        ...prevOrders.slice(existInClosedIndex + 1)
                                    ];
                                }
                                return prevOrders;
                            });
                        }
                        existInClosed = false;
                    }
                }
            }

            //karşılaştırmaya göre silme işlemleri
            if(isOrderUpdate){//order id var ve yeni gelen datanın state bilgisiyle aynı. o yüzden güncelleme yapılacak mı kontrol edilecek.
                const keysToCheck = existInOpen ? 
                ['asking_price', 'count', 'leverage', 'customer_id', 'entry_timestamp', 'product_id', 'state', 'stop_loss', 'take_profit', 'type']
                : ['asking_price', 'count', 'leverage', 'customer_id', 'processing_price', 'product_id', 'realized_count', 'result_timestamp', 'state', 'total', 'type'];
                let isDifferent = false;
                let controlValue = existInOpen ? openOrders[existInOpenIndex] : closedOrders[existInClosedIndex];
                
                for (let key of keysToCheck) {
                    let newOrderValue = newOrder[key];
                    let tableDataValue = controlValue[key];

                    if(newOrderValue == "" || newOrderValue == undefined){
                        newOrderValue = null;
                    }
                    if(tableDataValue == "" || tableDataValue == undefined){
                        tableDataValue = null;
                    }

                    if(newOrderValue != tableDataValue && (newOrderValue != null || tableDataValue != null)){
                        isDifferent = true;
                        break;
                    }
                }

                if(isDifferent){
                    if(existInOpen){
                        setOpenOrders(prevOrders => {
                            if (prevOrders[existInOpenIndex]?.order_id === newOrder.order_id) {
                                return [
                                    ...prevOrders.slice(0, existInOpenIndex),
                                    ...prevOrders.slice(existInOpenIndex + 1)
                                ];
                            }
                            return prevOrders;
                        });
                        existInOpen = false;
                    }
                    else if(existInClosed){
                        setClosedOrders(prevOrders => {
                            if (prevOrders[existInClosedIndex]?.order_id === newOrder.order_id) {
                                return [
                                    ...prevOrders.slice(0, existInClosedIndex),
                                    ...prevOrders.slice(existInClosedIndex + 1)
                                ];
                            }
                            return prevOrders;
                        });
                        existInClosed = false;
                    }
                }
            }

            //ekleme işlemleri
            if(!existInOpen && !existInClosed && (newDataState == "waiting" || newDataState == "processing")){//hem open hem closed içinde yok ise ve waiting ya da processing geldiyse
                setOpenOrders(prevOrders => [...prevOrders, newOrder]);
                setTriggerAnimation(newOrder.order_id);
            }
            else if(!existInClosed && (newDataState == "approved" || newDataState == "denied")){
                setClosedOrders(prevOrders => [...prevOrders, newOrder]);
                setTriggerAnimation(newOrder.order_id);
            }
        }
    }

    //order bilgilerinin kontrolleri - başlangıç
    const orderDataControl_Market = (product_id) => {
        const currency = product_id.split("-")[0];
        if(currenciesData && currenciesData[currency] && currenciesData[currency].picture_url){
            return <div className="market"><img src={currenciesData[currency].picture_url} /><span>{product_id}</span></div>;
        }
        return <div className="market"><span>{product_id}</span></div>;
    }

    const orderDataControl_Type = (type) => {
        if (type === "limitbuy") {
            return "Limit Buy";
        } else if (type === "limitsell") {
            return "Limit Sell";
        } else if (type === "marketbuy") {
            return "Market Buy";
        } else if (type === "marketsell") {
            return "Market Sell";
        } else{
            return "";
        }
    }

    const orderDataControl_StopLoss = (type, order, forSorting, show_places) => {
        if(order.stop_loss == "" || order.stop_loss == null || order.stop_loss == undefined){
            return forSorting ? 0 : "";
        } else{
            if(forSorting){
                return Number(order.stop_loss);
            }
            else{
                const response = formatValuePriceControl(order.stop_loss, show_places);
                if(response){
                    return formatValue3(order.stop_loss, show_places);
                }
                else{
                    return formatValue4(order.stop_loss);
                }
            }
        }
    }

    const orderDataControl_TakeProfit = (type, order, forSorting, show_places) => {
        if(order.take_profit == "" || order.take_profit == null || order.take_profit == undefined){
            return forSorting ? 0 : "";
        } else{
            if(forSorting){
                return Number(order.take_profit);
            }
            else{
                const response = formatValuePriceControl(order.take_profit, show_places);
                if(response){
                    return formatValue3(order.take_profit, show_places);
                }
                else{
                    return formatValue4(order.take_profit);
                }
            }
        }
    }

    const orderDataControl_TypeClass = (type) => {
        if(type === "Limit Buy" || type === "Market Buy"){
            return "success";
        } else{
            return "danger";
        }
    }

    const orderDataControl_TypeForTable = (type, typeclass) => {
        if(type === "Limit Buy" || type === "Limit Sell"){
            return <span className={typeclass}>Limit</span>;
        } else{
            return <span className={typeclass}>Market</span>;
        }
    }

    const orderDataControl_State_Open = (order, i) => {
        if(order.state === "waiting"){
            return <span data-order_id={order.order_id} className="openOrderState"><EditIconForm order={order} index={i} /><label className="warning">Waiting</label><CancelIconForm order={order} index={i} /></span>;
        } else if(order.state === "processing"){
            return <span data-order_id={order.order_id}><label className="success nobuttons">Processing</label></span>;
        } else{
            return "";
        }
    }

    const orderDataControl_State_Close = (order) => {
        if(order.state === "approved"){
            return <span data-order_id={order.order_id}><label className="success">Approved</label></span>;
        } else if(order.state === "denied"){
            return <span data-order_id={order.order_id}><label className="danger">Denied</label></span>;
        } else{
            return "";
        }
    }

    const orderDataControl_Price = (price, show_places) => {
        if(price != "" && price != null && price != undefined){
            const response = formatValuePriceControl(price, show_places);
            if(response){
                return formatValue3(price, show_places);
            }
            else{
                return formatValue4(price);
            }
        }
        return "";
    }

    //mobil görünüm için
    const orderDataMobileControl_Market = (order) => {
        if(order){
            const currency = order.product_id.split("-")[0];
            let type = "";
            let typeClass = "";
            let isFuture = order.future_type ? true : false;

            if(isFuture){
                if (order.future_type === "buy") {
                    type = "Buy";
                } else if (order.future_type === "sell") {
                    type = "Sell";
                } else{
                    type = "";
                }
            }
            else{
                if (order.type === "limitbuy") {
                    type = "Limit Buy";
                } else if (order.type === "limitsell" || order.type === "stoploss" || order.type === "takeprofit") {
                    type = "Limit Sell";
                } else if (order.type === "marketbuy") {
                    type = "Market Buy";
                } else if (order.type === "marketsell") {
                    type = "Market Sell";
                } else{
                    type = "";
                }
            }

            if(type === "Limit Buy" || type === "Market Buy" || type === "Buy"){
                typeClass = "success";
            } else{
                typeClass = "danger";
            }

            return <div className="mobile-column-market">
                <div className="market">
                    {currenciesData && currenciesData[currency] && currenciesData[currency].picture_url && <img src={currenciesData[currency].picture_url} />}
                    <div>
                        <span>{order.product_id}</span>
                        {isFuture ? 
                            <span className={typeClass}>{type}</span>
                            :
                            (type === "Limit Buy" || type === "Limit Sell" ? <span className={typeClass}>Limit</span> : <span className={typeClass}>Market</span>)
                        }
                    </div>
                </div>
                {!isFuture && <div className="date">
                    {order.entry_date ? order.entry_date : order.result_date}
                </div>}
            </div>;
        }
        return "";
    }

    const orderDataMobileControl_Price_Open = (order) => {
        if(order){
            const productMaxOrderAskingPricePlaces = productData && productData[order.product_id] && productData[order.product_id].max_order_asking_price_places ? productData[order.product_id].max_order_asking_price_places : null;
            
            let editedValue = null;
            if(order.asking_price != "" && order.asking_price != null && order.asking_price != undefined){
                const response = formatValuePriceControl(order.asking_price, productMaxOrderAskingPricePlaces);
                if(response){
                    editedValue = formatValue3(order.asking_price, productMaxOrderAskingPricePlaces);
                }
                else{
                    editedValue = formatValue4(order.asking_price);
                }
            }

            return <div className="mobile-column-price">
                <div className="price">
                    <label>Price:</label>
                    <span>{editedValue}</span>
                </div>
                <div className="count">
                    <label>Count:</label>
                    <span>{order.count != "" && order.count != null && order.count != undefined ? formatValue4(order.count) : ""}</span>
                </div>
                <div className="total">
                    <label>Leverage:</label>
                    <span>{order.leverage}</span>
                </div>
            </div>;
        }
        return "";
    }

    const orderDataMobileControl_State_Open = (order, i) => {
        if(order){
            if(order.state === "waiting"){
                return <span data-order_id={order.order_id} className="mobile-column-state"><EditIconForm order={order} index={i} /><i className="fa-solid fa-clock warning"></i><CancelIconForm order={order} index={i} /></span>;
            } else if(order.state === "processing"){
                return <span data-order_id={order.order_id}><i className="fa-solid fa-clock success nobuttons"></i></span>;
            } else{
                return "";
            }
        }
        return "";
    }

    const orderDataMobileControl_Price_Close = (order) => {
        if(order){
            const productMaxOrderAskingPricePlaces = productData && productData[order.product_id] && productData[order.product_id].max_order_asking_price_places ? productData[order.product_id].max_order_asking_price_places : null;
            
            let currentValue = order.state == "approved" ? order.processing_price : order.asking_price;

            let editedValue = null;
            if(currentValue != "" && currentValue != null && currentValue != undefined){
                const response = formatValuePriceControl(currentValue, productMaxOrderAskingPricePlaces);
                if(response){
                    editedValue = formatValue3(currentValue, productMaxOrderAskingPricePlaces);
                }
                else{
                    editedValue = formatValue4(currentValue);
                }
            }

            return <div className="mobile-column-price">
                <div className="price">
                    <label>Price:</label>
                    <span>{editedValue}</span>
                </div>
                <div className="count">
                    <label>Count:</label>
                    <span>{order.realized_count != "" && order.realized_count != null && order.realized_count != undefined ? formatValue4(order.realized_count) : ""}</span>
                </div>
                <div className="total">
                    <label>Leverage:</label>
                    <span>{order.leverage}</span>
                </div>
            </div>;
        }
        return "";
    }

    const orderDataMobileControl_State_Close = (order, i) => {
        if(order){
            if(order.state === "approved"){
                return <span data-order_id={order.order_id} className="mobile-column-state"><i className="fa-solid fa-circle-check success"></i></span>;
            } else if(order.state === "denied"){
                return <span data-order_id={order.order_id} className="mobile-column-state"><i className="fa-solid fa-circle-xmark danger"></i></span>;
            } else{
                return "";
            }
        }
        return "";
    }
    //order bilgilerinin kontrolleri - son

    const columnsOpen = useMemo(() => [//open orders tablo bilgileri
        { accessorKey: 'timestamp', header: 'Date', Cell: ({ row }) => row.original.date },
        { accessorKey: 'market', header: 'Market', Cell: ({ row }) => row.original.marketForTable },
        { accessorKey: 'type', header: 'Type', Cell: ({ row }) => row.original.typeForTable }, //row.original.typeForTable geçen yer newData içindeki typeForTable değeri
        { accessorKey: 'price', header: 'Price', Cell: ({ row }) => row.original.priceForTable },
        { accessorKey: 'count', header: 'Count' },
        { accessorKey: 'leverage', header: 'Leverage' },
        { accessorKey: 'stop_loss', header: 'Stop Loss', Cell: ({ row }) => row.original.stop_lossForTable },
        { accessorKey: 'take_profit', header: 'Take Profit', Cell: ({ row }) => row.original.take_profitForTable },
        { accessorKey: 'state', header: 'State' },
    ], []);

    const columnsClosed = useMemo(() => [//closed orders tablo bilgileri
        { accessorKey: 'timestamp', header: 'Date', Cell: ({ row }) => row.original.date },
        { accessorKey: 'market', header: 'Market', Cell: ({ row }) => row.original.marketForTable },
        { accessorKey: 'type', header: 'Type', Cell: ({ row }) => row.original.typeForTable }, //row.original.typeForTable geçen yer newData içindeki typeForTable değeri
        { accessorKey: 'price', header: 'Price', Cell: ({ row }) => row.original.priceForTable },
        { accessorKey: 'count', header: 'Count' },
        { accessorKey: 'leverage', header: 'Leverage' },
        { accessorKey: 'state', header: 'State' },
    ], []);

    const columnsOpenMobile = useMemo(() => [//open orders mobil tablo bilgileri
        { accessorKey: 'timestamp', header: 'Timestamp' },
        { accessorKey: 'market', header: 'Market' },
        { accessorKey: 'price', header: 'Price' },
        { accessorKey: 'state', header: 'State' },
    ], []);

    const columnsClosedMobile = useMemo(() => [//closed orders mobil tablo bilgileri
        { accessorKey: 'timestamp', header: 'Timestamp' },
        { accessorKey: 'market', header: 'Market' },
        { accessorKey: 'price', header: 'Price' },
        { accessorKey: 'state', header: 'State' },
    ], []);

    const tableDataOpen = useMemo(() => {//open orders verileri
        if (!openOrders.length) return [];
    
        const newData = [];
        openOrders.forEach((order, i) => {
            const productMaxOrderAskingPricePlaces = productData && productData[order.product_id] && productData[order.product_id].max_order_asking_price_places ? productData[order.product_id].max_order_asking_price_places : null;
            let marketText = orderDataControl_Market(order.product_id);
            let typetext = orderDataControl_Type(order.type);
            let stoplosscell = orderDataControl_StopLoss(typetext, order, true, null);
            let takeprofitcell = orderDataControl_TakeProfit(typetext, order, true, null);
            
            let typeclass = orderDataControl_TypeClass(typetext);
            let typeForTable = orderDataControl_TypeForTable(typetext, typeclass);
            let stateText = orderDataControl_State_Open(order, i);

            let priceCell = orderDataControl_Price(order.asking_price, productMaxOrderAskingPricePlaces);
            
            newData.push({
                order_id: order.order_id,
                date: order.entry_date,
                timestamp: order.entry_timestamp,
                market: order.product_id,
                marketForTable: marketText,
                type: typetext,
                typeForTable: typeForTable,
                price: order.asking_price,
                priceForTable: priceCell,
                count: order.count != "" && order.count != null && order.count != undefined ? formatValue4(order.count) : "NaN",
                leverage: order.leverage,
                stop_loss: stoplosscell,
                stop_lossForTable: orderDataControl_StopLoss(typetext, order, false, productMaxOrderAskingPricePlaces),
                take_profit: takeprofitcell,
                take_profitForTable: orderDataControl_TakeProfit(typetext, order, false, productMaxOrderAskingPricePlaces),
                state: stateText
            });
        });
    
        return newData;
    }, [openOrders, orderHistory, productData]);

    const tableDataClosed = useMemo(() => {//closed orders verileri
        if (!closedOrders.length) return [];
    
        const newData = [];
        closedOrders.forEach((order, i) => {
            if(order.state != "cancelled"){
                const productMaxOrderAskingPricePlaces = productData && productData[order.product_id] && productData[order.product_id].max_order_asking_price_places ? productData[order.product_id].max_order_asking_price_places : null;
                let marketText = orderDataControl_Market(order.product_id);
                let typetext = orderDataControl_Type(order.type);
                
                let typeclass = orderDataControl_TypeClass(typetext);
                let typeForTable = orderDataControl_TypeForTable(typetext, typeclass);
                let stateText = orderDataControl_State_Close(order);

                let pricetext = order.state == "approved" ? order.processing_price : order.asking_price;
                let pricetextForTable = order.state == "approved" ? orderDataControl_Price(order.processing_price, productMaxOrderAskingPricePlaces) : orderDataControl_Price(order.asking_price, productMaxOrderAskingPricePlaces);
    
                newData.push({
                    order_id: order.order_id,
                    date: order.result_date,
                    timestamp: order.result_timestamp,
                    market: order.product_id,
                    marketForTable: marketText,
                    type: typetext,
                    typeForTable: typeForTable,
                    price: pricetext,
                    priceForTable: pricetextForTable,
                    count: order.realized_count != "" && order.realized_count != null && order.realized_count != undefined ? formatValue4(order.realized_count) : "NaN",
                    leverage: order.leverage,
                    state: stateText
                });
            }
        });
    
        return newData;
    }, [closedOrders, orderHistory, productData]);

    const tableDataOpenMobile = useMemo(() => {//open orders verileri
        if (!openOrders.length) return [];
    
        const newData = [];
        openOrders.forEach((order, i) => {
            let marketText = orderDataMobileControl_Market(order);
            let priceText = orderDataMobileControl_Price_Open(order);
            let stateText = orderDataMobileControl_State_Open(order, i);
            
            newData.push({
                timestamp: order.entry_timestamp,
                market: marketText,
                price: priceText,
                state: stateText
            });
        });
    
        return newData;
    }, [openOrders, orderHistory, productData]);

    const tableDataClosedMobile = useMemo(() => {//open orders verileri
        if (!closedOrders.length) return [];
    
        const newData = [];
        closedOrders.forEach((order, i) => {
            let marketText = orderDataMobileControl_Market(order);
            let priceText = orderDataMobileControl_Price_Close(order);
            let stateText = orderDataMobileControl_State_Close(order, i);
            
            newData.push({
                timestamp: order.result_timestamp,
                market: marketText,
                price: priceText,
                state: stateText
            });
        });
    
        return newData;
    }, [closedOrders, orderHistory, productData]);

    useEffect(() => {//order history tablosu için yeni bilgi geldiğinde yapılanlar
        if (isFirstRenderForNewData.current) {
            isFirstRenderForNewData.current = false;
            return;
        }
        orderHistoryTableUpdate(orderHistoryNewData);
    }, [orderHistoryNewData]);

    useEffect(() => {//listener için
        const checkScreenWidth = () => {
            if(window.innerWidth <= 800){
                setViewType("mobile");
            }
            else{
                setViewType("pc");
            }

            if((window.innerWidth >= 1350 && window.innerWidth <= 2415) || window.innerWidth <= 800){
                setViewTypeOpenOrdersSpecial("mobile");
            }
            else{
                setViewTypeOpenOrdersSpecial("pc");
            }
        }

        checkScreenWidth();
        
        window.addEventListener('resize', checkScreenWidth);
        document.addEventListener('click', hiddenCustomizedSelect);
        document.addEventListener('click', hiddenCustomizedSelectLeverage);
        return () => {//component kapanırken event listener temizleniyor
          window.removeEventListener('resize', checkScreenWidth);
          document.removeEventListener('click', hiddenCustomizedSelect);
          document.removeEventListener('click', hiddenCustomizedSelectLeverage);
        };
    }, []);

    const hiddenCustomizedSelect = (e) => {//closed orders time select görünürlüğü dışarıya tıklandığında otomatik kapanması için
        if (closedOrdersTimeSelect.current && !closedOrdersTimeSelect.current.contains(e.target) && closedOrdersTimeSelectMenu.current && !closedOrdersTimeSelectMenu.current.contains(e.target)) {
            setClosedOrdersTimeSelectDisplay(false);
        }
    };

    const hiddenCustomizedSelectLeverage = (e) => {//leverage görünümü dışarıya tıklanınca kapanması için
        if(leverageArea.current && !leverageArea.current.contains(e.target) && leverageAreaInput.current && !leverageAreaInput.current.contains(e.target)){
            leverageArea.current.classList.remove("show");
        }
        else if(leverageAreaInput.current && leverageAreaInput.current.contains(e.target)){
            leverageArea.current.classList.toggle("show");
        }
    };





    //processes bilgileri
    //processes bilgileri
    //processes bilgileri
    const futureProcesses = useSelector((state) => state.kprice.demoFutureProcesses.data);
    const futureProfiles = useSelector((state) => state.kprice.demoFutureProfiles.data);
    const demoFutureActiveCompetition = useSelector((state) => state.kprice.demoFutureActiveCompetition.data);
    const [activeProcesses, setActiveProcesses] = useState([]);
    const [deactiveProcesses, setDeactiveProcesses] = useState([]);
    const [updateDataProcesses, setUpdateDataProcesses] = useState([]);

    const selectedFutureProcessesTimeInterval = useSelector((state) => state.kprice.selectedDemoFutureProcessesTimeInterval);
    const [processesTime, setProcessesTime] = useState(selectedFutureProcessesTimeInterval ? selectedFutureProcessesTimeInterval : "last1w");//closed orders zaman değeri
    const [activeFutureProcessesCount, setActiveFutureProcessesCount] = useState(0);
    const [demoFutureCompetitionCode, setDemoFutureCompetitionCode] = useState("");
    const [balance, setBalance] = useState(0);
    const [credit, setCredit] = useState(0);
    const [displayCreditInfo, setDisplayCreditInfo] = useState(false);
    const [totalProfit, setTotalProfit] = useState(0);
    const [totalProfitProcessesHistory, setTotalProfitProcessesHistory] = useState(0);
    const [totalAssets, setTotalAssets] = useState(0);
    const [totalSurety, setTotalSurety] = useState(0);
    const [freeMargin, setFreeMargin] = useState(0);
    const [marginLevel, setMarginLevel] = useState(null);
    const [quickFinishLock, setQuickFinishLock] = useState(false);

    const [loadFundsModalVisible, setLoadFundsModalVisible] = useState(false);
    const [resetDemoAccountLoading, setResetDemoAccountLoading] = useState(false);
    const [resetDemoAccountModalVisible, setResetDemoAccountModalVisible] = useState(false);
    const [verificationModalText, setVerificationModalText] = useState("");
    const [verificationModalVisible, setVerificationModalVisible] = useState(false);
    const [displayProcessesEditDialog, setDisplayProcessesEditDialog] = useState(false);//Processes düzenleme popup görünürlüğünü tutuyor
    const [currentEditProcess, setCurrentEditProcess] = useState(null);//Processes düzenleme aşamasındaki order bilgisini tutuyor
    const [currentFinishProcess, setCurrentFinishProcess] = useState(null);//Processes sonlandırma aşamasında order bilgisini tutuyor
    const [currentLOTForFinishProcess, setCurrentLOTForFinishProcess] = useState(null);

    useEffect(() => {//eğer tablo boş dönerse boş olarak güncellenebilsin diye
        const activeProcesses = futureProcesses.activeProcesses ? futureProcesses.activeProcesses : [];
        setActiveProcesses(activeProcesses);
        setDeactiveProcesses(futureProcesses.deactiveProcesses ? futureProcesses.deactiveProcesses : []);
        setUpdateDataProcesses(futureProcesses.updateData ? futureProcesses.updateData : []);

        //futureProcesses güncellenince abonelikler kontrol ediliyor
        const withSelectedProduct = false;
        dispatch(onChangeDemoFutureProcesses({ withSelectedProduct }));
    }, [futureProcesses]);

    useEffect(() => {
        processesTableUpdate(updateDataProcesses);
    }, [updateDataProcesses]);

    const processesData_actions = (process) => {
        return  <span>
                    <Tooltip title="Edit" className="editicon" onClick={() => {setCurrentEditProcess(process);setCurrentFinishProcess(process);setCurrentLOTForFinishProcess(process.available_count);setDisplayProcessesEditDialog(true);}}>
                        <IconButton>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Close" className="editicon" onClick={() => quickFinishProcess(process)}>
                        <IconButton>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </span>;
    }

    const processesDataControl_Type = (process) => {
        if(process.future_type === "buy"){
            return <span data-order_id={process.order_id} className="success">Buy</span>;
        } else{
            return <span data-order_id={process.order_id} className="danger">Sell</span>;
        }
    }

    const processesDataControl_CalcProfit = (future_type, product_id, entry_price, available_count) => {
        let profit = 0;

        if(future_type == "buy"){
            profit = (new Decimal(productSlowData[product_id] ? productSlowData[product_id].currentPrice : 0).minus(new Decimal(entry_price))).times(new Decimal(available_count));
        }
        else if(future_type == "sell"){
            profit = (new Decimal(entry_price).minus(new Decimal(productSlowData[product_id] ? productSlowData[product_id].currentPrice : 0))).times(new Decimal(available_count));
        }
        
        return Number(profit);
    }

    const processesDataControl_ProfitForTable = (item) => {
        const currentProfit = item.state == "active" ? processesDataControl_CalcProfit(item.future_type, item.product_id, item.entry_price, item.available_count) : item.process_profit;
        return <span className={currentProfit != "" && currentProfit != null && currentProfit != undefined ? (currentProfit > 0 ? "success" : (currentProfit < 0 ? "danger" : undefined)) : undefined}>{(currentProfit == 0 || (currentProfit != "" && currentProfit != null && currentProfit != undefined)) ? formatValue5(currentProfit, showPlacesUSDT) : "NaN"}</span>
    }

    const processesDataControl_PriceForTable = (item) => {
        const productMaxOrderAskingPricePlaces = productData && productData[item.product_id] && productData[item.product_id].max_order_asking_price_places ? productData[item.product_id].max_order_asking_price_places : null;
        const productShowPlaces = productData && productData[item.product_id] && productData[item.product_id].show_places ? productData[item.product_id].show_places : null;
        
        let firstPrice = null;//item.entry_price
        let secondPrice = null;//productSlowData[item.product_id].currentPrice

        if(item.entry_price != "" && item.entry_price != null && item.entry_price != undefined){
            const response_firstPrice = formatValuePriceControl(item.entry_price, productMaxOrderAskingPricePlaces);
            if(response_firstPrice){
                firstPrice = formatValue3(item.entry_price, productMaxOrderAskingPricePlaces);
            }
            else{
                firstPrice = formatValue4(item.entry_price);
            }
        }

        if(productSlowData[item.product_id].currentPrice != "" && productSlowData[item.product_id].currentPrice != null && productSlowData[item.product_id].currentPrice != undefined){
            const response_secondPrice = formatValuePriceControl(productSlowData[item.product_id].currentPrice, productShowPlaces);
            if(response_secondPrice){
                secondPrice = formatValue3(productSlowData[item.product_id].currentPrice, productShowPlaces);
            }
            else{
                secondPrice = formatValue4(productSlowData[item.product_id].currentPrice);
            }
        }
        return <span className="priceForTable">{firstPrice}<i className="fa-solid fa-arrow-right"></i>{secondPrice}</span>
    }

    const processesDataMobile_Price = (order) => {
        if(order){
            let liqPrice = null;
            if (futureProfiles && futureProcesses && productSlowData) {
                const liqPriceResult = calculateLiquidationPrice(order);
                if (liqPriceResult !== null) {
                    liqPrice = formatNumberForLiq(order, liqPriceResult);
                }
            }

            return <div className="mobile-column-price">
                <div className="price">
                    <label>LOT:</label>
                    <span>{order.available_count}</span>
                </div>
                <div className="count">
                    <label>Price:</label>
                    <span>{processesDataControl_PriceForTable(order)}</span>
                    
                </div>
                <div className="total">
                    <label>Profit:</label>
                    <span>{processesDataControl_ProfitForTable(order)}</span>
                </div>
                <div className="total">
                    <label>Liq Price:</label>
                    <span>{liqPrice != null ? `≈ ${liqPrice}` : '-'}</span>
                </div>
            </div>;
        }
        return "";
    }

    const calculateLiquidationPrice = (order) => {
        // Extract necessary data from futureProfiles
        let tempTotalBalance = futureProfiles.balance ? futureProfiles.balance : 0;
        let tempCredit = futureProfiles.credit ? futureProfiles.credit : 0;
        
        let tempTotalAssets = 0;
    
        let tempTotalSurety = new Decimal(0);
        futureProcesses.activeProcesses.forEach((proc) => {
            tempTotalSurety = tempTotalSurety.plus(new Decimal(proc.available_surety));
        });
    
        // Calculate totalProfitOther (profit from other positions)
        let totalProfitOther = new Decimal(0);
        futureProcesses.activeProcesses.forEach((proc) => {
            if (proc.order_id !== order.order_id) { // Exclude current position
                if(productSlowData && productSlowData[proc.product_id] && productSlowData[proc.product_id].currentPrice){
                    const currentPrice = new Decimal(productSlowData[proc.product_id]?.currentPrice || 0);
                    const entryPrice = new Decimal(proc.entry_price);
                    const availableCount = new Decimal(proc.available_count);
                    let procProfit = new Decimal(0);
        
                    if (proc.future_type === "buy") {
                        procProfit = (currentPrice.minus(entryPrice)).times(availableCount);
                    } else if (proc.future_type === "sell") {
                        procProfit = (entryPrice.minus(currentPrice)).times(availableCount);
                    }

                    totalProfitOther = totalProfitOther.plus(procProfit);
                }
            }
        });
    
        // Calculate required profit for liquidation (Pi_required)
        const Pi_required = ((tempTotalSurety.times(0.2)).minus(tempTotalBalance)).minus(tempCredit).minus(totalProfitOther);

        const entryPrice = new Decimal(order.entry_price);
        const availableCount = new Decimal(order.available_count);
    
        if (availableCount.equals(0)) return null; // Avoid division by zero
    
        let deltaPrice = Pi_required.dividedBy(availableCount);
        let liqPrice = null;
        if (order.future_type === "buy") {
            liqPrice = entryPrice.plus(deltaPrice);
        } else if (order.future_type === "sell") {
            liqPrice = entryPrice.minus(deltaPrice);
        }

        if (liqPrice.lessThanOrEqualTo(0)) {
            liqPrice = null;
        }

        return liqPrice;
    };

    const formatNumberForLiq = (item, number) => {
        if (!number || !number.isFinite()) return null;
        
        const productShowPlaces = productData && productData[item.product_id] && productData[item.product_id].show_places ? productData[item.product_id].show_places : null;
        let formattedNumberLiq = null;
        if(number != "" && number != null && number != undefined){
            formattedNumberLiq = formatValue3(number, productShowPlaces);
        }
        return formattedNumberLiq;
    };

    const columnsProcesses = useMemo(() => [
        { accessorKey: 'timestamp', header: 'Timestamp' },
        { accessorKey: 'market', header: 'Market' },
        { accessorKey: 'futureType', header: 'Type', Cell: ({ row }) => row.original.futureTypeForTable },
        { accessorKey: 'count', header: 'LOT' },
        { accessorKey: 'price', header: 'Price', Cell: ({ row }) => row.original.priceForTable },
        { accessorKey: 'profit', header: 'Profit', Cell: ({ row }) => row.original.profitForTable },
        { accessorKey: 'liqPrice', header: 'Liq Price', Cell: ({ row }) => row.original.liqPriceForTable },
        { accessorKey: 'actions', header: 'Actions' },
    ], []);

    const columnsProcessesHistory = useMemo(() => [
        { accessorKey: 'timestamp', header: 'Date', Cell: ({ row }) => row.original.date },
        { accessorKey: 'market', header: 'Market' },
        { accessorKey: 'futureType', header: 'Type', Cell: ({ row }) => row.original.futureTypeForTable },
        { accessorKey: 'profit', header: 'Profit', Cell: ({ row }) => row.original.profitForTable },
    ], []);

    const columnsProcessesMobile = useMemo(() => [
        { accessorKey: 'timestamp', header: 'Timestamp' },
        { accessorKey: 'market', header: 'Market' },
        { accessorKey: 'price', header: 'Price' },
        { accessorKey: 'actions', header: 'Actions' },
    ], []);

    const tableDataProcesses = useMemo(() => {
        const newData = [];

        activeProcesses.map((item, index) => {
            let liqPrice = null;
            if (futureProfiles && futureProcesses && productSlowData) {
                const liqPriceResult = calculateLiquidationPrice(item);
                if (liqPriceResult !== null) {
                    liqPrice = formatNumberForLiq(item, liqPriceResult);
                }
            }
            
            newData.push({
                order_id: item.order_id,
                date: moment(item.entry_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
                timestamp: item.entry_timestamp,
                market: orderDataControl_Market(item.product_id),
                futureType: item.future_type,
                futureTypeForTable: processesDataControl_Type(item),
                price: item.entry_price,
                priceForTable: processesDataControl_PriceForTable(item),
                count: item.available_count != "" && item.available_count != null && item.available_count != undefined ? formatValue4(item.available_count) : "NaN",
                leverage: item.leverage,
                surety: item.available_surety,
                profit: processesDataControl_CalcProfit(item.future_type, item.product_id, item.entry_price, item.available_count),
                profitForTable: processesDataControl_ProfitForTable(item),
                stop_loss: item.stop_loss ? item.stop_loss : null,
                take_profit: item.take_profit ? item.take_profit : null,
                actions: processesData_actions(item),
                liqPrice : liqPrice,
                liqPriceForTable : liqPrice != null ? `≈ ${liqPrice}` : '-'
            });
        });
    
        return newData;
    }, [activeProcesses, productData, productSlowData, futureProfiles]);

    const tableDataProcessesHistory = useMemo(() => {
        const newData = [];

        deactiveProcesses.map((item, index) => {
            const productMaxOrderAskingPricePlaces = productData && productData[item.product_id] && productData[item.product_id].max_order_asking_price_places ? productData[item.product_id].max_order_asking_price_places : null;
            let stop_loss = null;
            let take_profit = null;
            
            if(item.stop_loss != "" && item.stop_loss != null && item.stop_loss != undefined){
                const response_stop_loss = formatValuePriceControl(item.stop_loss, productMaxOrderAskingPricePlaces);
                if(response_stop_loss){
                    stop_loss = formatValue3(item.stop_loss, productMaxOrderAskingPricePlaces);
                }
                else{
                    stop_loss = formatValue4(item.stop_loss);
                }
            }
            
            if(item.take_profit != "" && item.take_profit != null && item.take_profit != undefined){
                const response_take_profit = formatValuePriceControl(item.take_profit, productMaxOrderAskingPricePlaces);
                if(response_take_profit){
                    take_profit = formatValue3(item.take_profit, productMaxOrderAskingPricePlaces);
                }
                else{
                    take_profit = formatValue4(item.take_profit);
                }
            }

            newData.push({
                order_id: item.order_id,
                entry_date: moment(item.entry_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
                result_date: moment(item.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
                timestamp: item.result_timestamp,
                market: orderDataControl_Market(item.product_id),
                futureType: item.future_type,
                futureTypeForTable: processesDataControl_Type(item),
                leverage: item.leverage,
                profit: item.process_profit,
                profitForTable: processesDataControl_ProfitForTable(item),
                stop_loss: stop_loss,
                take_profit: take_profit
            });
        });

        return newData;
    }, [deactiveProcesses, productData]);

    const tableDataProcessesMobile = useMemo(() => {
        const newData = [];

        activeProcesses.map((item, index) => {
            newData.push({
                date: moment(item.entry_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
                leverage: item.leverage,
                take_profit: item.take_profit ? item.take_profit : null,
                stop_loss: item.stop_loss ? item.stop_loss : null,
                order_id: item.order_id,
                timestamp: item.entry_timestamp,
                market: orderDataMobileControl_Market(item),
                price: processesDataMobile_Price(item),
                actions: processesData_actions(item)
            });
        });
    
        return newData;
    }, [activeProcesses, productData, productSlowData, futureProfiles]);

    const editAndFinishProcess = async (formpost, type) => {
        if(type == "modify"){
            setSubmitProcessesUpdateLoading(true);
        }
        else if(type == "finish"){
            setSubmitProcessesCloseLoading(true);
        }
        let formvalid = true;

        if(type == "modify"){
            const currentStopLoss = document.getElementById("edit-process-form-input1");
            const currentTakeProfit = document.getElementById("edit-process-form-input2");
            currentStopLoss.classList.remove("danger");
            currentTakeProfit.classList.remove("danger");
            setInputStopLossDanger(false);
            setInputTakeProfitDanger(false);

            if(currentStopLoss.value.includes('-') || currentStopLoss.value.includes('e')){
                currentStopLoss.value = "";
            }
            if(currentTakeProfit.value.includes('-') || currentTakeProfit.value.includes('e')){
                currentTakeProfit.value = "";
            }

            if(currentEditProcess.future_type == "buy"){
                if(currentStopLoss.value != "" && Number(currentStopLoss.value) >= productSlowData[currentEditProcess.product_id].currentPrice){//stop loss
                    currentStopLoss.classList.add("danger");
                    setInputStopLossDanger(true);
                    formvalid = false;
                    if(formpost){toast.error("Stop Loss value must be less than Entry Price!");}
                }
                else if(currentStopLoss.value != "" && !valueControlWithDigitLimit(Number(currentStopLoss.value), inputInfo_maxBeforePlaces)){
                    currentStopLoss.classList.add("danger");
                    setInputStopLossDanger(true);
                    formvalid = false;
                    if(formpost){toast.error("The integer part of the value you enter for Stop Loss cannot exceed " + inputInfo_maxBeforePlaces + " digits!");}
                }
                else if(currentStopLoss.value != "" && 0 >= Number(currentStopLoss.value)){
                    currentStopLoss.classList.add("danger");
                    setInputStopLossDanger(true);
                    formvalid = false;
                    if(formpost){toast.error("Stop Loss value must be bigger than 0!");}
                }

                if(currentTakeProfit.value != "" && Number(currentTakeProfit.value) <= productSlowData[currentEditProcess.product_id].currentPrice){//take profit
                    currentTakeProfit.classList.add("danger");
                    setInputTakeProfitDanger(true);
                    formvalid = false;
                    if(formpost){toast.error("Take Profit value must be bigger than Entry Price!");}
                }
                else if(currentTakeProfit.value != "" && !valueControlWithDigitLimit(Number(currentTakeProfit.value), inputInfo_maxBeforePlaces)){
                    currentTakeProfit.classList.add("danger");
                    setInputTakeProfitDanger(true);
                    formvalid = false;
                    if(formpost){toast.error("The integer part of the value you enter for Take Profit cannot exceed " + inputInfo_maxBeforePlaces + " digits!");}
                }
                else if(currentTakeProfit.value != "" && 0 >= Number(currentTakeProfit.value)){
                    currentTakeProfit.classList.add("danger");
                    setInputTakeProfitDanger(true);
                    formvalid = false;
                    if(formpost){toast.error("Take Profit value must be bigger than 0!");}
                }
            }
            else if(currentEditProcess.future_type == "sell"){
                if(currentStopLoss.value != "" && Number(currentStopLoss.value) <= Number(currentEditProcess.entry_price)){//stop loss
                    currentStopLoss.classList.add("danger");
                    setInputStopLossDanger(true);
                    formvalid = false;
                    if(formpost){toast.error("Stop Loss value must be bigger than Entry Price!");}
                }
                else if(currentStopLoss.value != "" && !valueControlWithDigitLimit(Number(currentStopLoss.value), inputInfo_maxBeforePlaces)){
                    currentStopLoss.classList.add("danger");
                    setInputStopLossDanger(true);
                    formvalid = false;
                    if(formpost){toast.error("The integer part of the value you enter for Stop Loss cannot exceed " + inputInfo_maxBeforePlaces + " digits!");}
                }
                else if(currentStopLoss.value != "" && 0 >= Number(currentStopLoss.value)){
                    currentStopLoss.classList.add("danger");
                    setInputStopLossDanger(true);
                    formvalid = false;
                    if(formpost){toast.error("Stop Loss value must be bigger than 0!");}
                }

                if(currentTakeProfit.value != "" && Number(currentTakeProfit.value) >= Number(currentEditProcess.entry_price)){//take profit
                    currentTakeProfit.classList.add("danger");
                    setInputTakeProfitDanger(true);
                    formvalid = false;
                    if(formpost){toast.error("Take Profit value must be less than Entry Price!");}
                }
                else if(currentTakeProfit.value != "" && !valueControlWithDigitLimit(Number(currentTakeProfit.value), inputInfo_maxBeforePlaces)){
                    currentTakeProfit.classList.add("danger");
                    setInputTakeProfitDanger(true);
                    formvalid = false;
                    if(formpost){toast.error("The integer part of the value you enter for Take Profit cannot exceed " + inputInfo_maxBeforePlaces + " digits!");}
                }
                else if(currentTakeProfit.value != "" && 0 >= Number(currentTakeProfit.value)){
                    currentTakeProfit.classList.add("danger");
                    setInputTakeProfitDanger(true);
                    formvalid = false;
                    if(formpost){toast.error("Take Profit value must be bigger than 0!");}
                }
            }
        }
        else if(type == "finish"){
            const currentFinishCount = document.getElementById("finish-process-form-input");
            currentFinishCount.classList.remove("danger");

            if(currentFinishCount.value.includes('-') || currentFinishCount.value.includes('e')){
                currentFinishCount.value = "";
            }
            
            if(currentFinishCount.value == ""){
                currentFinishCount.classList.add("danger");
                formvalid = false;
                if(formpost){toast.error("Please enter the LOT amount you want to close!");}
            }
            else if(Number(currentFinishCount.value) > Number(currentFinishProcess.available_count)){
                currentFinishCount.classList.add("danger");
                formvalid = false;
                if(formpost){toast.error("The value to be reduced cannot be more than the entered Count value!");}
            }
            else if(0 >= Number(currentFinishCount.value)){
                currentFinishCount.classList.add("danger");
                formvalid = false;
                if(formpost){toast.error("LOT value must be bigger than 0!");}
            }
        }

        calculateStopLossPotentialProfit();
        calculateTakeProfitPotentialProfit();
        
        if(formvalid && formpost){//uygunsa post edilecek
            let updateData = null;
            let isDifferent = false;
            let currentValues = null;
            let keysToCheck = [];
            
            if(type == "modify"){
                const data = new FormData(document.getElementById("edit-process-form"));
                updateData = Object.fromEntries(data);
                currentValues = currentEditProcess;
                keysToCheck = ['stop_loss', 'take_profit'];
            }
            else if(type == "finish"){
                const data = new FormData(document.getElementById("finish-process-form"));
                const currentFinishCount = document.getElementById("finish-process-form-input");

                if(Number(currentFinishCount.value) < Number(currentFinishProcess.available_count)){
                    data.append("close_type", "decrease");
                }
                else{
                    data.append("close_type", "finish");
                    data.delete("close_count");
                }

                updateData = Object.fromEntries(data);
                currentValues = currentFinishProcess;
                keysToCheck = ['count'];
            }
            
            for (let key of keysToCheck) {
                let newUpdateValue = updateData[key];
                let currentValue = key == "count" ? currentValues["available_count"] : currentValues[key];

                if(newUpdateValue == "" || newUpdateValue == undefined){
                    newUpdateValue = null;
                }
                if(currentValue == "" || currentValue == undefined){
                    currentValue = null;
                }

                if (newUpdateValue != currentValue && (newUpdateValue != null || currentValue != null)) {
                    isDifferent = true;
                    break;
                }
            }
            
            if(isDifferent && updateData){//popup içindeki bilgiler değiştirildiyse
                const session = await Auth.currentSession();
                const jwtToken = session.getIdToken().getJwtToken();
                
                axios.post('https://api.kpricemarket.com/demo/futureprocess/update', 
                    JSON.stringify(updateData),
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
                )
                .then(function (response) {
                    if(response.status == "200" && response.data.message == "Data updated successfully"){
                        toast.success("Position updated successfully!");
                        processesTableUpdate(response.data.data);
                    }
                    else if(response.data.message == "Internal server error(0)" || response.data.message == "Internal server error(2)"){
                        toast.error("There was a problem updating the position!");
                    }
                    else{
                        toast.error(response.data.message);
                    }
                    setSubmitProcessesUpdateLoading(false);
                    setSubmitProcessesCloseLoading(false);
                })
                .catch(function (error) {
                    if(error.response.data.error == "Internal server error(0)" || error.response.data.error == "Internal server error(2)"){
                        toast.error("There was a problem updating the position!");
                    }
                    else{
                        toast.error(error.response.data.error);
                    }
                    setSubmitProcessesUpdateLoading(false);
                    setSubmitProcessesCloseLoading(false);
                });
            }
            else{
                setSubmitProcessesUpdateLoading(false);
                setSubmitProcessesCloseLoading(false);
            }

            setDisplayProcessesEditDialog(false);
        }
        else{
            setSubmitProcessesUpdateLoading(false);
            setSubmitProcessesCloseLoading(false);
        }
    }

    const quickFinishProcess = async (process) => {
        if(!quickFinishLock){
            setQuickFinishLock(true);

            const data = {
                "close_type" : "finish",
                "order_id" : process.order_id,
                "update_type" : "close"
            };
            
            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            axios.post('https://api.kpricemarket.com/demo/futureprocess/update', 
                JSON.stringify(data),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {
                if(response.status == "200" && response.data.message == "Data updated successfully"){
                    toast.success("Position closed successfully!");
                    setQuickFinishLock(false);
                }
                else if(response.data.message == "Internal server error(0)" || response.data.message == "Internal server error(2)"){
                    toast.error("There was a problem closing the position!");
                    setQuickFinishLock(false);
                }
                else{
                    toast.error(response.data.message);
                    setQuickFinishLock(false);
                }
            })
            .catch(function (error) {
                if(error.response.data.error == "Internal server error(0)" || error.response.data.error == "Internal server error(2)"){
                    toast.error("There was a problem closing the position!");
                }
                else{
                    toast.error(error.response.data.error);
                }

                setTimeout(() => {
                    setQuickFinishLock(false);
                }, 1000);
            });
        }
        else{
            toast.error("Please wait for the position to complete!");
        }
    }

    function processesTableUpdate(updateData) {//tablo bilgileri güncellendikçe tetiklenecek fonksiyon
        const isActive = updateData.state !== 'deactive';//active ya da deactive. yeni bilginin state bilgisi
        const isFromSubscription = 'leverage' in updateData;//güncellemeyi post ettiğimiz response içinden dönen bilgi ya da abonelikten gelen bilgi olup olmadığı
        
        const indexInActive = activeProcesses.findIndex(item => item.order_id === updateData.order_id);//active tablosunda varsa index'i
        const indexInDeactive = deactiveProcesses.findIndex(item => item.order_id === updateData.order_id);//deactive tablosunda varsa index'i
        
        if(updateData.order_id){
            /*//alt menüsü açıksa kapatıyoruz
            const submenuValues = document.querySelectorAll('.submenu-values2');
            submenuValues.forEach(element => {
                if (element.getAttribute('data-order_id') === updateData.order_id) {
                    const subMenuRow = element.closest('tr');
                    if(subMenuRow){
                        const mainRow = subMenuRow.previousElementSibling;
                        const expandButton = mainRow.querySelector("td:first-child button");
                        if(expandButton){
                            expandButton.click();
                        }
                    }
                }
            });*/
            //açık olan alt menüler kapatılıyor
            const submenuElements = document.querySelectorAll('.submenu-values');
            submenuElements.forEach(element => {
                const closestTrElement = element.closest('tr');
                const closestTrElementPrevious = closestTrElement.previousElementSibling;
                if(closestTrElementPrevious){
                    const elementExpandButton = closestTrElementPrevious.querySelector("td:first-child button");
                    if(elementExpandButton){
                        elementExpandButton.click();
                    }
                }
            });
        }

        if (isActive) {
            if (indexInDeactive > -1) {
                if (indexInActive > -1) {//eğer gelen bilgi active ve deactive tablosunda varsa aktif tablodan kaldır
                    setTriggerAnimation(updateData.order_id);
                    const updatedActiveProcesses = [...activeProcesses];
                    updatedActiveProcesses.splice(indexInActive, 1);
                    setTimeout(() => {
                        setActiveProcesses(updatedActiveProcesses);
                    }, 1000);
                }
                return;
            }
    
            if (indexInActive > -1) {//active tablosunda varsa güncelle
                const updatedActiveProcesses = [...activeProcesses];
                const isUpdated = processesTableUpdate_TableRow(updatedActiveProcesses, indexInActive, updateData, ['order_id', 'customer_id']);
                if (isUpdated) {
                    setActiveProcesses(updatedActiveProcesses);
                    setTriggerAnimation(updateData.order_id);
                }
            }
            else if (isFromSubscription) {//active tablosunda yoksa ve abonelik bilgisinden geliyorsa ekle
                setActiveProcesses(prev => [...prev, updateData]);
                setTimeout(() => {
                    setTriggerAnimation(updateData.order_id);
                }, 1000);
            }
        } 
        else {
            if (indexInActive > -1) {//active tablosundan kaldırma işlemi
                setTriggerAnimation(updateData.order_id);
                const updatedActiveProcesses = [...activeProcesses];
                updatedActiveProcesses.splice(indexInActive, 1);
                setTimeout(() => {
                    setActiveProcesses(updatedActiveProcesses);
                }, 1000);
            }
    
            if (indexInDeactive > -1) {//deactive tablosunda varsa güncelleme
                const updatedDeactiveProcesses = [...deactiveProcesses];
                const isUpdated = processesTableUpdate_TableRow(updatedDeactiveProcesses, indexInDeactive, updateData, ['order_id', 'customer_id']);
                if (isUpdated) {
                    setDeactiveProcesses(updatedDeactiveProcesses);
                    setTriggerAnimation(updateData.order_id);
                }
            }
            else if (indexInActive === -1) {//deactive tablosunda yoksa ve active tablosunda da yoksa ekle
                setDeactiveProcesses(prev => [...prev, updateData]);
                setTimeout(() => {
                    setTriggerAnimation(updateData.order_id);
                }, 1000);
            }
        }
    }
    
    function processesTableUpdate_TableRow(table, index, data, excludeKeys) {//tablo bilgilerinin kontrolü ve farklılık varsa güncellenmesi
        let isUpdated = false;
        const row = table[index];
    
        Object.keys(data).forEach(key => {
            if (!excludeKeys.includes(key) && row[key] !== data[key]) {
                row[key] = data[key];
                isUpdated = true;
            }
        });
    
        if (isUpdated) {
            table[index] = row;
        }

        return isUpdated;
    }

    const getProcessesDetails = async (orderId) => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();
        try {
            const response = await axios.get(`https://api.kpricemarket.com/demo/futureprocess/events?order_id=${orderId}`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });
            return response.data;
        } catch (error) {
            return null;
        }
    }
    
    useEffect(() => {//processes süresi güncellenince çalışacak
        if(selectedFutureProcessesTimeInterval != processesTime){
            const newTimeInterval = processesTime;
            dispatch(onDemoFutureProcessesChangeTimeInterval({ newTimeInterval }));
        }
    }, [processesTime]);

    useEffect(() => {//sabit bilgi alanı için hesaplamalar
        if(productSlowData && futureProcesses && futureProcesses.activeProcesses && futureProfiles){
            let tempTotalBalance = futureProfiles.balance ? futureProfiles.balance : 0;
            let tempTotalProfit = 0;
            let tempTotalSurety = 0;
            let tempTotalAssets = 0;
            let tempCredit = futureProfiles.credit ? futureProfiles.credit : 0;
            
            futureProcesses.activeProcesses.map((item, index) => {
                if(item.future_type == "buy"){
                    tempTotalProfit = new Decimal(tempTotalProfit).plus((new Decimal(productSlowData[item.product_id].currentPrice).minus(new Decimal(item.entry_price))).times(new Decimal(item.available_count)));
                }
                else if(item.future_type == "sell"){
                    tempTotalProfit = new Decimal(tempTotalProfit).plus((new Decimal(item.entry_price).minus(new Decimal(productSlowData[item.product_id].currentPrice))).times(new Decimal(item.available_count)));
                }
                
                tempTotalSurety = new Decimal(tempTotalSurety).plus(new Decimal(item.available_surety));
            });

            setTotalProfit(tempTotalProfit);
            setTotalSurety(tempTotalSurety);
            
            setBalance(tempTotalBalance);
            setCredit(tempCredit);
            tempTotalAssets = new Decimal(tempTotalBalance).plus(new Decimal(tempTotalProfit));
            tempTotalAssets = tempTotalAssets.plus(new Decimal(tempCredit));
            setTotalAssets(tempTotalAssets);

            setFreeMargin(tempTotalAssets ? new Decimal(tempTotalAssets).minus(new Decimal(tempTotalSurety)) : 0);
            setMarginLevel(futureProcesses.activeProcesses.length > 0 ? (tempTotalAssets && tempTotalSurety ? (new Decimal(tempTotalAssets).div(new Decimal(tempTotalSurety))).times(100) : 0) : null);
        }

        if(activeOption == "Positions History"){
            let tempTotalProfitProcessesHistory = 0;

            futureProcesses.deactiveProcesses.map((item, index) => {
                tempTotalProfitProcessesHistory = new Decimal(tempTotalProfitProcessesHistory).plus((new Decimal(item.process_profit)));
            });

            setTotalProfitProcessesHistory(tempTotalProfitProcessesHistory);
        }
        else{
            setTotalProfitProcessesHistory(0);
        }
    }, [productSlowData, futureProcesses, futureProfiles, activeOption]);

    //min, step değerlerinin düzenlenecek order'ın product_id bilgisine göre güncellenmesi
    useEffect(() => {
        if(currentEditOrder){
            setCurrentProductId(currentEditOrder.product_id);

            //leverage değerleri
            const futureLeverages = productData && productData[currentEditOrder.product_id] && productData[currentEditOrder.product_id].future_leverages ? productData[currentEditOrder.product_id].future_leverages : null;
            const futureLeveragesMarks = futureLeverages ? futureLeverages.reduce((acc, number) => {
                acc[number] = number.toString();
                return acc;
            }, {}) : {};
            const futureLeveragesLastIndex = futureLeverages ? futureLeverages.length - 1 : 0;
            const sliderValue = currentEditOrder.leverage && futureLeverages && futureLeverages.includes(currentEditOrder.leverage) ? currentEditOrder.leverage : (futureLeverages ? futureLeverages[futureLeveragesLastIndex] : 100);

            setEditOrderFutureLeverages(futureLeverages);
            setEditOrderFutureLeveragesMarks(futureLeveragesMarks);
            setEditOrderFutureLeveragesLastIndex(futureLeveragesLastIndex);
            setEditOrderLeverageSliderValue(sliderValue);
        }
    }, [currentEditOrder, productData]);

    useEffect(() => {
        if(currentEditProcess){
            setCurrentProductId(currentEditProcess.product_id);
        }
    }, [currentEditProcess]);

    useEffect(() => {
        if(currentFinishProcess){
            setCurrentProductId(currentFinishProcess.product_id);
        }
    }, [currentFinishProcess]);

    useEffect(() => {
        if(currentProductId && productData && productData[currentProductId]){
            setInputNumberMinValue(productData[currentProductId].min_order_count);
            setInputInfo_askingPricePlaces(productData[currentProductId].max_order_asking_price_places);
            setInputInfo_countPlaces(productData[currentProductId].max_order_count_places);
            setInputInfo_maxBeforePlaces(productData[currentProductId].max_before_places);
        }
        else{
            setInputNumberMinValue(0);
            setInputInfo_askingPricePlaces(0);
            setInputInfo_countPlaces(0);
            setInputInfo_maxBeforePlaces(0);
        }
    }, [currentProductId]);

    /*
    const calculateSubmenuHeights = () => {
        const processesTable = document.querySelector('.processesTable');
        const processesHistoryTable = document.querySelector('.processesHistoryTable');
        let currentMarginBottom = 0;

        if(processesTable){
            currentMarginBottom = 72;
            if(window.innerWidth <= 1550){currentMarginBottom = 52;}
            else if(window.innerWidth <= 1350){currentMarginBottom = 47;}
            else if(window.innerWidth <= 1000){currentMarginBottom = 36;}
            else if(window.innerWidth <= 800){currentMarginBottom = 65;}
            else if(window.innerWidth <= 400){currentMarginBottom = 53;}

            const rows = processesTable.querySelectorAll('tr.Mui-TableBodyCell-DetailPanel');
            
            rows.forEach(row => {
                const previousRow = row.previousElementSibling;

                if (previousRow) {
                    if(row.querySelector('.submenu-values')){
                        const height = row.clientHeight - currentMarginBottom;
                        //console.log(`Found 'Mui-TableBodyCell-DetailPanel' tr with 'submenu-values', height: ${height}px`);
                        
                        previousRow.style.marginBottom = `${height}px`;
                        //console.log(`Set margin-bottom of the previous row to ${height}px`);
                    }
                    else{
                        previousRow.style.marginBottom = `-${currentMarginBottom}px`;
                    }
                }
            });
        }
    }
    */

    const handleRowClick = (event) => {//alt alan için yapılan dinlemenin tetiklediği fonksiyon 1
        let row = null;
        let rowIsValid = false;
        if(event.target.tagName == "TR"){
            rowIsValid = true;
            row = event.target;
        }
        else{
            rowIsValid = true;
            row = event.target.closest('tr');
        }

        //düzenleme butonu ve alt alanı açan butona tıklatıldıysa dikkate alma, aşağıdaki adımları atla
        if(event.target.tagName == "BUTTON" || event.target.tagName == "svg"){
            rowIsValid = false;
        }
        
        if(row && rowIsValid){
            const expandButton = row.querySelector("td:first-child button");
            if(expandButton){
                expandButton.click();
                setTimeout(() => {
                    loadingDetails();
                }, 100);
            }
        }
    };

    const loadingDetails = () => {//alt alan için yapılan dinlemenin tetiklediği fonksiyon 2
        let orderId = null;
        const submenuElements = document.querySelectorAll('.submenu-values');
        submenuElements.forEach(element => {
            if(element.querySelector('.loading')){
                const submenuValues2Element = element.querySelector('.submenu-values2');
                if(submenuValues2Element){
                    orderId = submenuValues2Element.getAttribute('data-order_id');
                    updateSubmenu(orderId);
                }
            }
        });

        //yeni açılan dışında alt alan açıksa kapatıyoruz
        if(orderId != null){
            submenuElements.forEach(element => {
                const submenuValues2Element = element.querySelector('.submenu-values2');
                if(submenuValues2Element){
                    if(submenuValues2Element.getAttribute('data-order_id') != orderId){
                        const closestTrElement = element.closest('tr');
                        const closestTrElementPrevious = closestTrElement.previousElementSibling;
                        if(closestTrElementPrevious){
                            const elementExpandButton = closestTrElementPrevious.querySelector("td:first-child button");
                            if(elementExpandButton){
                                elementExpandButton.click();
                            }
                        }
                    }
                }
            });
        }
    }

    const updateSubmenu = async (orderId) => {//alt alan için yapılan dinlemenin tetiklediği fonksiyon 3
        let submenu = null;
        const submenuValues2Elements = document.querySelectorAll('.submenu-values2');
        submenuValues2Elements.forEach(element => {
            if (element.getAttribute('data-order_id') === orderId) {
                submenu = element;
            }
        });
        
        if(submenu){
            const details = await getProcessesDetails(orderId);
            
            if(details && details.ProcessEvents){
                if(details.ProcessEvents.length > 0){
                    let itemHTML = '<div class="details">';
                    for (let i = 0; i < details.ProcessEvents.length; i++) {
                        const productMaxOrderAskingPricePlaces = productData && productData[details.ProcessDetails.product_id] && productData[details.ProcessDetails.product_id].max_order_asking_price_places ? productData[details.ProcessDetails.product_id].max_order_asking_price_places : null;
                        const productShowPlaces = productData && productData[details.ProcessDetails.product_id] && productData[details.ProcessDetails.product_id].show_places ? productData[details.ProcessDetails.product_id].show_places : null;
                        
                        let firstPrice = null;//details.ProcessDetails.entry_price
                        let secondPrice = null;//details.ProcessEvents[i].event_price

                        if(details.ProcessDetails.entry_price != "" && details.ProcessDetails.entry_price != null && details.ProcessDetails.entry_price != undefined){
                            const response_firstPrice = formatValuePriceControl(details.ProcessDetails.entry_price, productMaxOrderAskingPricePlaces);
                            if(response_firstPrice){
                                firstPrice = formatValue3(details.ProcessDetails.entry_price, productMaxOrderAskingPricePlaces);
                            }
                            else{
                                firstPrice = formatValue4(details.ProcessDetails.entry_price);
                            }
                        }

                        if(details.ProcessEvents[i].event_price != "" && details.ProcessEvents[i].event_price != null && details.ProcessEvents[i].event_price != undefined){
                            const response_secondPrice = formatValuePriceControl(details.ProcessEvents[i].event_price, productShowPlaces);
                            if(response_secondPrice){
                                secondPrice = formatValue3(details.ProcessEvents[i].event_price, productShowPlaces);
                            }
                            else{
                                secondPrice = formatValue4(details.ProcessEvents[i].event_price);
                            }
                        }

                        let eventDate = moment(Number(details.ProcessEvents[i].event_timestamp)).tz(timeZone).format("YYYY-MM-DD HH:mm");
                        itemHTML += `<div class="details-item"><div><span>Date</span><label>${eventDate}</label></div>`;
                        itemHTML += `<div><span>Count</span><label>${details.ProcessEvents[i].close_count != "" && details.ProcessEvents[i].close_count != null && details.ProcessEvents[i].close_count != undefined ? formatValue4(details.ProcessEvents[i].close_count) : ""}</label></div>`;
                        itemHTML += `<div><span>Price</span><label>${firstPrice} <i class="fa-solid fa-arrow-right"></i> ${secondPrice}</label></div>`;
                        itemHTML += `<div><span>Profit</span><label ${details.ProcessEvents[i].profit != "" && details.ProcessEvents[i].profit != null && details.ProcessEvents[i].profit != undefined ? (details.ProcessEvents[i].profit > 0 ? 'class="success"' : (details.ProcessEvents[i].profit < 0 ? 'class="danger"' : '')) : ''}>${details.ProcessEvents[i].profit != "" && details.ProcessEvents[i].profit != null && details.ProcessEvents[i].profit != undefined ? formatValue3(details.ProcessEvents[i].profit, showPlacesUSDT) : ""}</label></div></div>`;

                        if(details.ProcessEvents[i].query_type){
                            const submenuValues1Div = submenu.previousElementSibling;
                            if(submenuValues1Div && submenuValues1Div.classList.contains('submenu-values1')){
                                if(details.ProcessEvents[i].query_type == "take_profit"){
                                    submenuValues1Div.getElementsByClassName("take_profit")[0].classList.add("danger");
                                }
                                else if(details.ProcessEvents[i].query_type == "stop_loss"){
                                    submenuValues1Div.getElementsByClassName("stop_loss")[0].classList.add("danger");
                                }
                            }
                        }
                    }
                    itemHTML += '</div>';
                    submenu.innerHTML = itemHTML;
                }
                else{
                    submenu.innerHTML = '<div class="empty-details"><i class="fa-regular fa-folder-open"></i><span>No details</span></div>';
                }
            }
            else{
                submenu.innerHTML = '<div class="empty-details"><i class="fa-solid fa-triangle-exclamation"></i><span>There was a problem fetching position information</span></div>';
            }
        }
    }

    useEffect(() => {//tabloyu listener ile dinleme alanı (alt alanın açılması ile ilgili)
        const container = document.querySelector('.withExpandMenu');

        if (container) {
            container.addEventListener('click', handleRowClick);
      
            return () => {
                container.removeEventListener('click', handleRowClick);
            };
        }
    }, [activeOption, tableDataProcesses, tableDataProcessesHistory]);

    useEffect(() => {//tabloyu observer ile dinleme alanı (scroll ile ilgili)
        const tableContainer = document.querySelector('.withExpandMenu');
        if (!tableContainer) return;

        let allMutations = []; // Tüm mutationları biriktireceğimiz array

        // Tüm mutationları işleyecek fonksiyon
        const handleMutations = debounce(() => {
            let hasDetailPanel = false;
            for (const mutation of allMutations) {
                if(mutation){
                    if(mutation.target.classList.contains("Mui-TableBodyCell-DetailPanel") || mutation.target.classList.contains("submenu-values2") || mutation.target.classList.contains("MuiTouchRipple-root")){
                        hasDetailPanel = true;
                    }
                }
            }

            if(!hasDetailPanel){
                const submenuElements = document.querySelectorAll('.submenu-values');
                submenuElements.forEach(element => {
                    const closestTrElement = element.closest('tr');
                    const closestTrElementPrevious = closestTrElement.previousElementSibling;
                    if(closestTrElementPrevious){
                        const elementExpandButton = closestTrElementPrevious.querySelector("td:first-child button");
                        if(elementExpandButton){
                            elementExpandButton.click();
                        }
                    }
                });
            }
            allMutations = []; // İşlemden sonra array'i sıfırla
        }, 100);

        // Observer callback fonksiyonu, her bir mutation'ı biriktirir
        const observerCallback = (mutationsList, observer) => {
            allMutations = allMutations.concat(mutationsList); // Mevcut mutationları biriktir
            handleMutations(); // Debounce fonksiyonunu tetikle
        };

        const observer = new MutationObserver(observerCallback);
        observer.observe(tableContainer, { childList: true, subtree: true });

        return () => {
            observer.disconnect();
            handleMutations.cancel(); // Debounce fonksiyonunu iptal et
        };
    }, [activeOption, tableDataProcesses, tableDataProcessesHistory]);

    useEffect(() => {
        if(!displayEditDialog){
            setCurrentEditOrder(null);
            setEditOrderFutureLeverages(null);
            setEditOrderFutureLeveragesMarks({});
            setEditOrderFutureLeveragesLastIndex(0);
            setEditOrderLeverageSliderValue(100);
        }
    }, [displayEditDialog]);

    useEffect(() => {
        if(!displayConfirm){
            setCurrentDeleteOrderId("");
        }
    }, [displayConfirm]);

    useEffect(() => {
        if(!displayProcessesEditDialog){
            setCurrentEditProcess(null);
            setCurrentFinishProcess(null);
            setCurrentLOTForFinishProcess(null);
        }
    }, [displayProcessesEditDialog]);



    const [inputStopLossDangerOrder, setInputStopLossDangerOrder] = useState(false);
    const [inputTakeProfitDangerOrder, setInputTakeProfitDangerOrder] = useState(false);
    const [stopLossPotentialProfitOrder, setStopLossPotentialProfitOrder] = useState(null);
    const [takeProfitPotentialProfitOrder, setTakeProfitPotentialProfitOrder] = useState(null);

    useEffect(() => {
        if(currentEditOrder){
            calculateStopLossPotentialProfitOrder();
        }
    }, [inputStopLossDangerOrder]);
    
    useEffect(() => {
        if(currentEditOrder){
            calculateTakeProfitPotentialProfitOrder();
        }
    }, [inputTakeProfitDangerOrder]);

    useEffect(() => {
        if(currentEditOrder){
            setTimeout(() => {
                calculateStopLossPotentialProfitOrder();
                calculateTakeProfitPotentialProfitOrder();
            }, 500);
        }
        else{
            calculateStopLossPotentialProfitOrder();
            calculateTakeProfitPotentialProfitOrder();
        }
    }, [currentEditOrder]);

    const calculateStopLossPotentialProfitOrder = () => {
        const stopLossValue = document.getElementById("edit-order-form-input1") ? document.getElementById("edit-order-form-input1").value : "";
        if (stopLossValue !== "" && currentEditOrder && !inputStopLossDangerOrder) {
            if (!currentEditOrder) return;
            
            const priceValue = document.getElementById("edit-order-form-input-price").value;
            const countValue = document.getElementById("edit-order-form-input-count").value;

            const askingPrice = new Decimal(priceValue != "" ? priceValue : currentEditOrder.asking_price);
            const count = new Decimal(countValue != "" ? countValue : currentEditOrder.count);
            const stopLossPrice = new Decimal(stopLossValue);
        
            let profit = new Decimal(0);

            const orderType = (currentEditOrder.type === "limitbuy" || currentEditOrder.type === "marketbuy") ? "buy" : "sell";

            if (orderType === "buy") {
                profit = stopLossPrice.minus(askingPrice).times(count);
            } else if (orderType === "sell") {
                profit = askingPrice.minus(stopLossPrice).times(count);
            }

            setStopLossPotentialProfitOrder(profit);
        } else {
            setStopLossPotentialProfitOrder(null);
        }
    };
    
    const calculateTakeProfitPotentialProfitOrder = () => {
        const takeProfitValue = document.getElementById("edit-order-form-input2") ? document.getElementById("edit-order-form-input2").value : "";
        if (takeProfitValue !== "" && currentEditOrder && !inputTakeProfitDangerOrder) {
            if (!currentEditOrder) return;

            const priceValue = document.getElementById("edit-order-form-input-price").value;
            const countValue = document.getElementById("edit-order-form-input-count").value;
        
            const askingPrice = new Decimal(priceValue != "" ? priceValue : currentEditOrder.asking_price);
            const count = new Decimal(countValue != "" ? countValue : currentEditOrder.count);
            const takeProfitPrice = new Decimal(takeProfitValue);
        
            let profit = new Decimal(0);
            
            const orderType = (currentEditOrder.type === "limitbuy" || currentEditOrder.type === "marketbuy") ? "buy" : "sell";

            if (orderType === "buy") {
                profit = takeProfitPrice.minus(askingPrice).times(count);
            } else if (orderType === "sell") {
                profit = askingPrice.minus(takeProfitPrice).times(count);
            }
        
            setTakeProfitPotentialProfitOrder(profit);
        } else {
            setTakeProfitPotentialProfitOrder(null);
        }
    };
    
    const [inputStopLossDanger, setInputStopLossDanger] = useState(false);
    const [inputTakeProfitDanger, setInputTakeProfitDanger] = useState(false);
    const [stopLossPotentialProfit, setStopLossPotentialProfit] = useState(null);
    const [takeProfitPotentialProfit, setTakeProfitPotentialProfit] = useState(null);

    useEffect(() => {
        if(currentEditProcess){
            calculateStopLossPotentialProfit();
        }
    }, [inputStopLossDanger]);
    
    useEffect(() => {
        if(currentEditProcess){
            calculateTakeProfitPotentialProfit();
        }
    }, [inputTakeProfitDanger]);

    useEffect(() => {
        if(currentEditProcess){
            setTimeout(() => {
                calculateStopLossPotentialProfit();
                calculateTakeProfitPotentialProfit();
            }, 500);
        }
        else{
            calculateStopLossPotentialProfit();
            calculateTakeProfitPotentialProfit();
        }
    }, [currentEditProcess]);

    const calculateStopLossPotentialProfit = () => {
        const stopLossValue = document.getElementById("edit-process-form-input1") ? document.getElementById("edit-process-form-input1").value : "";
        if (stopLossValue !== "" && currentEditProcess && !inputStopLossDanger) {
            if (!currentEditProcess) return;
    
            const entryPrice = new Decimal(currentEditProcess.entry_price);
            const availableCount = new Decimal(currentEditProcess.available_count);
            const stopLossPrice = new Decimal(stopLossValue);
        
            let profit = new Decimal(0);
        
            if (currentEditProcess.future_type === "buy") {
                profit = stopLossPrice.minus(entryPrice).times(availableCount);
            } else if (currentEditProcess.future_type === "sell") {
                profit = entryPrice.minus(stopLossPrice).times(availableCount);
            }
        
            setStopLossPotentialProfit(profit);
        } else {
            setStopLossPotentialProfit(null);
        }
    };
    
    const calculateTakeProfitPotentialProfit = () => {
        const takeProfitValue = document.getElementById("edit-process-form-input2") ? document.getElementById("edit-process-form-input2").value : "";
        if (takeProfitValue !== "" && currentEditProcess && !inputTakeProfitDanger) {
            if (!currentEditProcess) return;
            
            const entryPrice = new Decimal(currentEditProcess.entry_price);
            const availableCount = new Decimal(currentEditProcess.available_count);
            const takeProfitPrice = new Decimal(takeProfitValue);
        
            let profit = new Decimal(0);
        
            if (currentEditProcess.future_type === "buy") {
                profit = takeProfitPrice.minus(entryPrice).times(availableCount);
            } else if (currentEditProcess.future_type === "sell") {
                profit = entryPrice.minus(takeProfitPrice).times(availableCount);
            }
        
            setTakeProfitPotentialProfit(profit);
        } else {
            setTakeProfitPotentialProfit(null);
        }
    };

    const formatProfit = (profit) => {
        if(profit != "" && profit != null && profit != undefined){
            return formatValue3(profit, showPlacesUSDT);
        }
    };

    useEffect(() => {
        setActiveFutureProcessesCount(futureProcesses && futureProcesses.activeProcesses ? futureProcesses.activeProcesses.length : 0);
    }, [futureProcesses]);
      
    useEffect(() => {
        setDemoFutureCompetitionCode(demoFutureActiveCompetition && demoFutureActiveCompetition.active_competition ? demoFutureActiveCompetition.active_competition : "");
    }, [demoFutureActiveCompetition]);

    const buttonOperations = (type) => {
        let permission = true;
    
        if(!(userInfo && userInfo.future_permission && userInfo.future_permission == "True")){
            permission = false;
            setVerificationModalText(type == "Join Competition" ? "You must verify to join the competition!" : (type == "Load Funds" ? "You must verify to load funds!" : (type == "Reset Demo Account" ? "You must verify to reset your demo account!" : "")));
            setVerificationModalVisible(true);
        }
        else if(type != "Reset Demo Account" && demoFutureCompetitionCode != ""){
            permission = false;
            if(type == "Join Competition"){
                toast.success(`Joined (${demoFutureCompetitionCode})`);
            }
            else{
                toast.error(`You are in an active competition (${demoFutureCompetitionCode}). Please reset demo account.`);
            }
        }
        else if(activeFutureProcessesCount > 0){
            permission = false;
            toast.error("Active process detected. Please close all processes.");
        }
        else if(type != "Reset Demo Account" && (balance != 0 || totalSurety != 0 || credit != 0)){
            permission = false;
            toast.error("Balance, Total Surety and Credit must be zero. Please reset the demo account.");
        }
        
        if(permission){
            if(type == "Join Competition"){
                navigate('/future-demo-competition-10000');
            }
            else if(type == "Load Funds"){
                setLoadFundsModalVisible(true);
            }
            else if(type == "Reset Demo Account"){
                setResetDemoAccountModalVisible(true);
            }
        }
    }
    
    const resetDemoAccount = async () => {
        setResetDemoAccountModalVisible(false);
        if(activeFutureProcessesCount == 0 && userInfo && userInfo.future_permission && userInfo.future_permission == "True"){
            setResetDemoAccountLoading(true);
        
            let updateData = {
                update_type: "reset_demo_account"
            }
        
            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();
        
            axios.post('https://api.kpricemarket.com/demo/futuredemo', 
                JSON.stringify(updateData),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {
                if(response.status == "200"){
                    if(response && response.data && response.data.data){
                        if(response.data.data.total_surety){setTotalSurety(Number(response.data.data.total_surety));}
                        if(response.data.data.balance){setBalance(Number(response.data.data.balance));}
                        if(response.data.data.credit){setCredit(Number(response.data.data.credit));}
                        if(response.data.data.active_competition){setDemoFutureCompetitionCode(response.data.data.active_competition);}
                    }
                    toast.success("Demo account reset successfully!");
                }
                else{
                    toast.error(`There was a problem resetting the demo account!${response.data.message ? ` (${response.data.message})` : ""}`);
                }
                setResetDemoAccountLoading(false);
            })
            .catch(function (error) {
                toast.error(`There was a problem resetting the demo account!${error.response.data.error ? ` (${error.response.data.error})` : ""}`);
                setResetDemoAccountLoading(false);
            });
        }
    }

    
    return (
        <div className="allsections allsections-border section-tradeorderhistory future-demo">
            <Dialog header="Future Demo Load Funds" visible={loadFundsModalVisible} draggable={false} resizable={false} className="section-walletlist-dialog transfer" onHide={() => setLoadFundsModalVisible(false)}>
                <SectionLoadFundsPopupContent setBalanceValue={setBalance} setTotalSuretyValue={setTotalSurety} setCreditValue={setCredit} setDemoFutureCompetitionCodeValue={setDemoFutureCompetitionCode} closeModal={() => setLoadFundsModalVisible(false)} />
            </Dialog>
            <ConfirmDialog 
                visible={displayConfirm}
                draggable={false}
                resizable={false}
                onHide={() => setDisplayConfirm(false)}
                message="Are you sure you want to cancel the order?"
                header="Cancel Order"
                icon="pi pi-info-circle"
                accept={() => cancelIconClick(currentDeleteOrderId)}
                reject={() => setDisplayConfirm(false)}
                className="future-confirm-dialog"
            />
            <ConfirmDialog 
                visible={resetDemoAccountModalVisible}
                draggable={false}
                resizable={false}
                onHide={() => setResetDemoAccountModalVisible(false)}
                message="If you are in the competition, you will be disqualified and will not be able to join the same competition again."
                header="Are you sure you want to reset your demo account?"
                icon="pi pi-info-circle"
                accept={() => resetDemoAccount()}
                reject={() => setResetDemoAccountModalVisible(false)}
                className="future-confirm-dialog"
            />
            <ConfirmDialog 
                visible={verificationModalVisible}
                draggable={false}
                resizable={false}
                onHide={() => setVerificationModalVisible(false)}
                message={verificationModalText}
                header="Verification"
                icon="pi pi-info-circle"
                accept={() => navigate("/verification/steps")}
                acceptLabel="Go to Verification"
                reject={() => setVerificationModalVisible(false)}
                className="future-confirm-dialog"
            />
            <Dialog header="Edit Order" visible={displayEditDialog} draggable={false} resizable={false} className="edit-order-dialog" onHide={() => setDisplayEditDialog(false)}>
                {currentEditOrder ? 
                <form id="edit-order-form" className="edit-order-form">
                    <span>Price</span>
                    <input type="number" id="edit-order-form-input-price" defaultValue={getValueWithShowPlaces(inputInfo_askingPricePlaces, currentEditOrder.asking_price)} name="asking_price" step={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} min={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} placeholder="Price" onChange={(event) => {setInputValueByShowPlacesFunction(inputInfo_askingPricePlaces, event.target);editDialogUpdate(false, event.target);}} />
                    <label onClick={() => document.getElementById("edit-order-form-input-price").focus()}>{currentEditOrder.product_id ? currentEditOrder.product_id.split("-")[1] : "NaN"}</label>
                    <span>Count</span>
                    <input type="number" id="edit-order-form-input-count" defaultValue={getValueWithShowPlaces(inputInfo_countPlaces, currentEditOrder.count)} name="count" step={getMinAndStepWithShowPlaces(inputInfo_countPlaces)} min={inputNumberMinValue} placeholder="Count" onChange={(event) => {setInputValueByShowPlacesFunction(inputInfo_countPlaces, event.target);editDialogUpdate(false, event.target);}} />
                    <label onClick={() => document.getElementById("edit-order-form-input-count").focus()}>{currentEditOrder.product_id ? currentEditOrder.product_id.split("-")[0] : "NaN"}</label>
                    <span>Leverage</span>
                    <input type="text" readOnly value={editOrderLeverageSliderValue} ref={leverageAreaInput} style={{marginBottom: 10}} />
                    <div className="section-tradebuysell-inner">
                        <div className="leverage-area" ref={leverageArea}>
                            <div className="section-tradebuysell-inner-range">
                                <Slider min={editOrderFutureLeverages ? editOrderFutureLeverages[0] : 10} max={editOrderFutureLeverages ? editOrderFutureLeverages[editOrderFutureLeveragesLastIndex] : 100} step={null} value={editOrderLeverageSliderValue} onChange={(value) => {setEditOrderLeverageSliderValue(value);editDialogUpdate(false, null);}} marks={editOrderFutureLeveragesMarks} />
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: -10}}>
                        <div className="title-area">
                            <span>Stop Loss</span>
                            {!inputStopLossDangerOrder && stopLossPotentialProfitOrder !== null && (
                                <div className={stopLossPotentialProfitOrder.greaterThan(0) ? "potential-info success" : (stopLossPotentialProfitOrder.lessThan(0) ? "potential-info danger" : "potential-info")}>
                                    {formatProfit(stopLossPotentialProfitOrder)}
                                </div>
                            )}
                        </div>
                        <i className="fa-solid fa-plus plusicon" id="edit-order-form-plus1" onClick={() => {document.getElementById("edit-order-form-input1").focus();}} style={currentEditOrder.stop_loss ? {display: "none"} : {undefined}}></i>
                        <input type="number" id="edit-order-form-input1" defaultValue={getValueWithShowPlaces(inputInfo_askingPricePlaces, currentEditOrder.stop_loss)} name={currentEditOrder.stop_loss ? "stop_loss" : undefined} className={currentEditOrder.stop_loss ? undefined : "locked"} onBlur={(event) => {if(event.target.value == ""){document.getElementById("edit-order-form-plus1").style.display="block";event.target.name="";event.target.classList.add("locked");}else{event.target.name="stop_loss";event.target.classList.remove("locked");}}} onFocus={(event) => {document.getElementById("edit-order-form-plus1").style.display="none";event.target.classList.remove("locked");}} onChange={(event) => {setInputValueByShowPlacesFunction(inputInfo_askingPricePlaces, event.target);editDialogUpdate(false, event.target);}} step={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} min={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} />
                        <label onClick={() => document.getElementById("edit-order-form-input1").focus()}>{currentEditOrder.product_id ? currentEditOrder.product_id.split("-")[1] : "NaN"}</label>
                        <div className="title-area">
                            <span>Take Profit</span>
                            {!inputTakeProfitDangerOrder && takeProfitPotentialProfitOrder !== null && (
                                <div className={takeProfitPotentialProfitOrder.greaterThan(0) ? "potential-info success" : (takeProfitPotentialProfitOrder.lessThan(0) ? "potential-info danger" : "potential-info")}>
                                    {formatProfit(takeProfitPotentialProfitOrder)}
                                </div>
                            )}
                        </div>
                        <i className="fa-solid fa-plus plusicon" id="edit-order-form-plus2" onClick={() => {document.getElementById("edit-order-form-input2").focus();}} style={currentEditOrder.take_profit ? {display: "none"} : {undefined}}></i>
                        <input type="number" id="edit-order-form-input2" defaultValue={getValueWithShowPlaces(inputInfo_askingPricePlaces, currentEditOrder.take_profit)} name={currentEditOrder.take_profit ? "take_profit" : undefined} className={currentEditOrder.take_profit ? undefined : "locked"} onBlur={(event) => {if(event.target.value == ""){document.getElementById("edit-order-form-plus2").style.display="block";event.target.name="";event.target.classList.add("locked");}else{event.target.name="take_profit";event.target.classList.remove("locked");}}} onFocus={(event) => {document.getElementById("edit-order-form-plus2").style.display="none";event.target.classList.remove("locked");}} onChange={(event) => {setInputValueByShowPlacesFunction(inputInfo_askingPricePlaces, event.target);editDialogUpdate(false, event.target);}} step={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} min={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} />
                        <label onClick={() => document.getElementById("edit-order-form-input2").focus()}>{currentEditOrder.product_id ? currentEditOrder.product_id.split("-")[1] : "NaN"}</label>
                    </div> 
                    <input type="hidden" value={currentEditOrder.order_id} name="order_id" />
                    <input type="hidden" value="modify" name="update_type"/>
                    <button type="button" onClick={() => editDialogUpdate(true, null)} disabled={submitOrderUpdateLoading}>{submitOrderUpdateLoading ? <Loading /> : "Update"}</button>
                </form> 
                : null}
            </Dialog>
            <Dialog header="Edit Position" visible={displayProcessesEditDialog} draggable={false} resizable={false} className="edit-order-dialog edit-process-dialog" onHide={() => setDisplayProcessesEditDialog(false)}>
                {currentEditProcess && displayProcessesEditDialog ? 
                <form id="edit-process-form" className="edit-finish-process-form edit-order-form edit-process-form">
                    <div className="market-info">
                        <div>
                            <img src={currenciesData[currentEditProcess.product_id.split("-")[0]].picture_url} />
                            <span>{currentEditProcess.product_id}</span>
                        </div>
                        <div>
                            {productSlowData[currentEditProcess.product_id].currentPrice != "" && productSlowData[currentEditProcess.product_id].currentPrice != null && productSlowData[currentEditProcess.product_id].currentPrice != undefined ? 
                                (formatValuePriceControl(productSlowData[currentEditProcess.product_id].currentPrice, productData && productData[currentEditProcess.product_id] && productData[currentEditProcess.product_id].show_places ? productData[currentEditProcess.product_id].show_places : "NaN") ? 
                                    formatValue3(productSlowData[currentEditProcess.product_id].currentPrice, productData && productData[currentEditProcess.product_id] && productData[currentEditProcess.product_id].show_places ? productData[currentEditProcess.product_id].show_places : "NaN")
                                    : 
                                    formatValue4(productSlowData[currentEditProcess.product_id].currentPrice))
                                :
                                ""
                            }
                        </div>
                    </div>
                    <div className="sl-tp">
                        <div>
                            <span>Stop Loss</span>
                            <i className="fa-solid fa-plus plusicon" id="edit-process-form-plus1" onClick={() => {document.getElementById("edit-process-form-input1").focus();}} style={currentEditProcess.stop_loss ? {display: "none"} : {undefined}}></i>
                            <input type="number" id="edit-process-form-input1" 
                                defaultValue={getValueWithShowPlaces(inputInfo_askingPricePlaces, currentEditProcess.stop_loss)} 
                                name={currentEditProcess.stop_loss ? "stop_loss" : undefined} 
                                className={currentEditProcess.stop_loss ? undefined : "locked"} 
                                onBlur={(event) => {if(event.target.value == ""){document.getElementById("edit-process-form-plus1").style.display="block";event.target.name="";event.target.classList.add("locked");}else{event.target.name="stop_loss";event.target.classList.remove("locked");}}} 
                                onFocus={(event) => {document.getElementById("edit-process-form-plus1").style.display="none";event.target.classList.remove("locked");}} 
                                onChange={(event) => {setInputValueByShowPlacesFunction(inputInfo_askingPricePlaces, event.target);editAndFinishProcess(false, "modify");}} 
                                step={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} 
                                min={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} />
                            <label onClick={() => document.getElementById("edit-process-form-input1").focus()}>{currentEditProcess.product_id ? currentEditProcess.product_id.split("-")[1] : "NaN"}</label>
                            {!inputStopLossDanger && stopLossPotentialProfit !== null && (
                                <div className={stopLossPotentialProfit.greaterThan(0) ? "potential-info success" : (stopLossPotentialProfit.lessThan(0) ? "potential-info danger" : "potential-info")}>
                                    {formatProfit(stopLossPotentialProfit)}
                                </div>
                            )}
                        </div>
                        <div>
                            <span>Take Profit</span>
                            <i className="fa-solid fa-plus plusicon" id="edit-process-form-plus2" onClick={() => {document.getElementById("edit-process-form-input2").focus();}} style={currentEditProcess.take_profit ? {display: "none"} : {undefined}}></i>
                            <input type="number" id="edit-process-form-input2" 
                                defaultValue={getValueWithShowPlaces(inputInfo_askingPricePlaces, currentEditProcess.take_profit)} 
                                name={currentEditProcess.take_profit ? "take_profit" : undefined} 
                                className={currentEditProcess.take_profit ? undefined : "locked"} 
                                onBlur={(event) => {if(event.target.value == ""){document.getElementById("edit-process-form-plus2").style.display="block";event.target.name="";event.target.classList.add("locked");}else{event.target.name="take_profit";event.target.classList.remove("locked");}}} 
                                onFocus={(event) => {document.getElementById("edit-process-form-plus2").style.display="none";event.target.classList.remove("locked");}} 
                                onChange={(event) => {setInputValueByShowPlacesFunction(inputInfo_askingPricePlaces, event.target);editAndFinishProcess(false, "modify");}} 
                                step={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} 
                                min={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} />
                            <label onClick={() => document.getElementById("edit-process-form-input2").focus()}>{currentEditProcess.product_id ? currentEditProcess.product_id.split("-")[1] : "NaN"}</label>
                            {!inputTakeProfitDanger && takeProfitPotentialProfit !== null && (
                                <div className={takeProfitPotentialProfit.greaterThan(0) ? "potential-info success" : (takeProfitPotentialProfit.lessThan(0) ? "potential-info danger" : "potential-info")}>
                                    {formatProfit(takeProfitPotentialProfit)}
                                </div>
                            )}
                        </div>
                    </div>
                    <input type="hidden" value={currentEditProcess.order_id} name="order_id" />
                    <input type="hidden" value="modify" name="update_type" />
                    <button type="button" onClick={() => editAndFinishProcess(true, "modify")} disabled={submitProcessesUpdateLoading || submitProcessesCloseLoading}>{submitProcessesUpdateLoading ? <Loading /> : "Update"}</button>
                </form> 
                : null}
                {currentFinishProcess && displayProcessesEditDialog ? 
                <form id="finish-process-form" className="edit-finish-process-form edit-order-form finish-process-form">
                    <span>LOT</span>
                    <input type="number" id="finish-process-form-input" 
                        defaultValue={getValueWithShowPlaces(inputInfo_countPlaces, currentFinishProcess.available_count)} 
                        name={"close_count"} 
                        onChange={(event) => {setCurrentLOTForFinishProcess(event.target.value);setInputValueByShowPlacesFunction(inputInfo_countPlaces, event.target);editAndFinishProcess(false, "finish");}} 
                        step={getMinAndStepWithShowPlaces(inputInfo_countPlaces)} 
                        min={getMinAndStepWithShowPlaces(inputInfo_countPlaces)} />
                    <label onClick={() => document.getElementById("finish-process-form-input").focus()}>{currentEditProcess.product_id ? currentEditProcess.product_id.split("-")[0] : "NaN"}</label>
                    <input type="hidden" value={currentFinishProcess.order_id} name="order_id" />
                    <input type="hidden" value="close" name="update_type" />
                    <button type="button" onClick={() => editAndFinishProcess(true, "finish")} disabled={submitProcessesUpdateLoading || submitProcessesCloseLoading}>{submitProcessesCloseLoading ? <Loading /> : ""}</button>
                    {!submitProcessesCloseLoading && <label className={processesDataControl_CalcProfit(currentEditProcess.future_type, currentEditProcess.product_id, currentEditProcess.entry_price, currentEditProcess.available_count) > 0 ? "editProcessButtonText success" : (processesDataControl_CalcProfit(currentEditProcess.future_type, currentEditProcess.product_id, currentEditProcess.entry_price, currentEditProcess.available_count) < 0 ? "editProcessButtonText danger" : "editProcessButtonText")}>Close Position with Profit {getValueWithShowPlaces(showPlacesUSDT, processesDataControl_CalcProfit(currentEditProcess.future_type, currentEditProcess.product_id, currentEditProcess.entry_price, (currentLOTForFinishProcess ? currentLOTForFinishProcess : 0)))}</label>}
                </form> 
                : null}
            </Dialog>
            <div className={isDemoFutureProfilesLoading != false ? "general-values loading" : "general-values"}>
                {isDemoFutureProfilesLoading != false ?
                <Loading /> :
                <div className="general-values-inner">
                    <div className="values">
                        <div>
                            <span>Total Assets: </span>
                            <label>{formatValue3(totalAssets, showPlacesUSDT)}</label>
                        </div>
                        <div>
                            <span>Total Surety: </span>
                            <label>{formatValue3(totalSurety, showPlacesUSDT)}</label>
                        </div>
                        <div>
                            <span>Free Margin: </span>
                            <label>{formatValue3(freeMargin, showPlacesUSDT)}</label>
                        </div>
                        <div className={marginLevel != null && marginLevel < 75 ? "danger" : undefined}>
                            <span>Margin Level: </span>
                            <label>{marginLevel ? formatValue3(marginLevel, 2) : "-"}</label>
                        </div>
                    </div>
                    <div className="section-quickactions-buttons">
                        <div className="section-quickactions-buttons-inner">
                            <a className="section-quickactions-button1" onClick={() => buttonOperations("Join Competition")}>
                                <i className="fa-solid fa-flag-checkered"></i>
                                <span>Join Competition</span>
                            </a>
                            <a className="section-quickactions-button1" onClick={() => buttonOperations("Load Funds")}>
                                <i className="fa-solid fa-coins"></i>
                                <span>Load Funds</span>
                            </a>
                        </div>
                        <div className="section-quickactions-buttons-inner">
                            <a className="section-quickactions-button1" onClick={() => {if(!resetDemoAccountLoading){buttonOperations("Reset Demo Account")}}}>
                                {resetDemoAccountLoading ? 
                                    <div className="loading"><Loading /></div>
                                    : 
                                    <>
                                        <i className="fa-solid fa-repeat"></i>
                                        <span>Reset Demo Account</span>
                                    </>
                                }
                            </a>
                            <Link to="/future-demo-ranking?stats_type=FDC10000" className="section-quickactions-button1">
                                <i className="fa-solid fa-ranking-star"></i>
                                <span>Rankings</span>
                            </Link>
                        </div>
                    </div>
                </div>
                }
            </div>
            <div className="section-tradeorderhistory-options">
                <div>
                    <span className={activeOption == "Positions" ? "active" : undefined} onClick={() => setActiveOption("Positions")}>Positions</span>
                    <span className={activeOption == "Positions History" ? "active" : undefined} onClick={() => setActiveOption("Positions History")}>Positions History</span>
                    <span className={activeOption == "Open Orders" ? "active" : undefined} onClick={() => setActiveOption("Open Orders")}>Open Orders</span>
                    <span className={activeOption == "Closed Orders" ? "active" : undefined} onClick={() => setActiveOption("Closed Orders")}>Closed Orders</span>
                </div>
                <div className="section-tradeorderhistory-options-select" ref={closedOrdersTimeSelect} style={activeOption == "Closed Orders" || activeOption == "Positions History" ? {} : {visibility: "hidden"}} onClick={() => {if(closedOrdersTimeSelectDisplay){setClosedOrdersTimeSelectDisplay(false)}else{setClosedOrdersTimeSelectDisplay(true)}}}>
                    {activeOption == "Closed Orders" ? 
                    (closedOrdersTime == "last1w" ? "1 Week" : (closedOrdersTime == "last1mo" ? "1 Month" : (closedOrdersTime == "last3mo" ? "3 Month" : (closedOrdersTime == "last6mo" ? "6 Month" : "1 Year")))) : 
                    (processesTime == "last1w" ? "1 Week" : (processesTime == "last1mo" ? "1 Month" : (processesTime == "last3mo" ? "3 Month" : (processesTime == "last6mo" ? "6 Month" : "1 Year"))))}
                    <i className="fa-solid fa-caret-up" style={closedOrdersTimeSelectDisplay ? {marginTop: "2px"} : {display: "none"}}></i>
                    <i className="fa-solid fa-caret-down" style={closedOrdersTimeSelectDisplay ? {display: "none"} : {}}></i>
                </div>
                <div className="section-tradeorderhistory-options-select-menu" ref={closedOrdersTimeSelectMenu} style={closedOrdersTimeSelectDisplay ? {display: "block"} : {display: "none"}}>
                    {activeOption == "Closed Orders" ? 
                    <>
                        <label className={closedOrdersTime == "last1w" ? "active" : undefined} onClick={() => {setClosedOrdersTime("last1w");setClosedOrdersTimeSelectDisplay(false);}}>1 Week</label>
                        <label className={closedOrdersTime == "last1mo" ? "active" : undefined} onClick={() => {setClosedOrdersTime("last1mo");setClosedOrdersTimeSelectDisplay(false);}}>1 Month</label>
                        <label className={closedOrdersTime == "last3mo" ? "active" : undefined} onClick={() => {setClosedOrdersTime("last3mo");setClosedOrdersTimeSelectDisplay(false);}}>3 Month</label>
                        <label className={closedOrdersTime == "last6mo" ? "active" : undefined} onClick={() => {setClosedOrdersTime("last6mo");setClosedOrdersTimeSelectDisplay(false);}}>6 Month</label>
                        <label className={closedOrdersTime == "last1y" ? "active" : undefined} onClick={() => {setClosedOrdersTime("last1y");setClosedOrdersTimeSelectDisplay(false);}}>1 Year</label>
                    </> : 
                    <>
                        <label className={processesTime == "last1w" ? "active" : undefined} onClick={() => {setProcessesTime("last1w");setClosedOrdersTimeSelectDisplay(false);}}>1 Week</label>
                        <label className={processesTime == "last1mo" ? "active" : undefined} onClick={() => {setProcessesTime("last1mo");setClosedOrdersTimeSelectDisplay(false);}}>1 Month</label>
                        <label className={processesTime == "last3mo" ? "active" : undefined} onClick={() => {setProcessesTime("last3mo");setClosedOrdersTimeSelectDisplay(false);}}>3 Month</label>
                        <label className={processesTime == "last6mo" ? "active" : undefined} onClick={() => {setProcessesTime("last6mo");setClosedOrdersTimeSelectDisplay(false);}}>6 Month</label>
                        <label className={processesTime == "last1y" ? "active" : undefined} onClick={() => {setProcessesTime("last1y");setClosedOrdersTimeSelectDisplay(false);}}>1 Year</label>
                    </>}
                </div>
            </div>
            <div className={isDemoFutureOrderHistoryLoading != false || isDemoFutureProcessesLoading != false ? "section-tradeorderhistory-list loading" : "section-tradeorderhistory-list"}>
                {isDemoFutureOrderHistoryLoading != false || isDemoFutureProcessesLoading != false ?
                <Loading /> :
                <div className={userInfo && userInfo.future_permission && userInfo.future_permission == "True" ? "tables" : "tables with-verify-info"} ref={orderHistoryArea}>
                    {activeOption == "Positions" ? 
                    <div className="processesTable withExpandMenu">
                        <div className="total-profit">
                            <label className={totalProfit > 0 ? "success" : (totalProfit < 0 ? "danger" : undefined)}>{formatValue3(totalProfit, showPlacesUSDT)}</label>
                        </div>
                        {viewType == "pc" ?
                            <MaterialReactTable columns={columnsProcesses} data={tableDataProcesses} enablePagination={false} enableRowVirtualization={true} initialState={{columnVisibility: { timestamp: false }, sorting: [{id: 'timestamp', desc: true}]}} 
                            renderDetailPanel={({ row }) => (
                                <div className="submenu-values">
                                    <div className="submenu-values1">
                                        <div>
                                            <span>Date:</span>
                                            <label>{row.original.date}</label>
                                        </div>
                                        <div>
                                            <span>Leverage:</span>
                                            <label>{row.original.leverage}</label>
                                        </div>
                                        <div className="take_profit">
                                            <span>Take Profit:</span>
                                            <label>{row.original.take_profit}</label>
                                        </div>
                                        <div className="stop_loss">
                                            <span>Stop Loss:</span>
                                            <label>{row.original.stop_loss}</label>
                                        </div>
                                    </div>
                                    <div className="submenu-values2" data-order_id={row.original.order_id}><div className="empty-details"><div className="loading"></div><Loading /></div></div>
                                </div>
                            )}
                            />
                            : 
                            <div className="column3 mobile-table">
                                <MaterialReactTable columns={columnsProcessesMobile} data={tableDataProcessesMobile} enablePagination={false} enableRowVirtualization={true} initialState={{sorting: [{id: 'timestamp', desc: true}]}} 
                                renderDetailPanel={({ row }) => (
                                    <div className="submenu-values">
                                        <div className="submenu-values1">
                                            <div>
                                                <span>Date:</span>
                                                <label>{row.original.date}</label>
                                            </div>
                                            <div>
                                                <span>Leverage:</span>
                                                <label>{row.original.leverage}</label>
                                            </div>
                                            <div className="take_profit">
                                                <span>Take Profit:</span>
                                                <label>{row.original.take_profit}</label>
                                            </div>
                                            <div className="stop_loss">
                                                <span>Stop Loss:</span>
                                                <label>{row.original.stop_loss}</label>
                                            </div>
                                        </div>
                                        <div className="submenu-values2" data-order_id={row.original.order_id}><div className="empty-details"><div className="loading"></div><Loading /></div></div>
                                    </div>
                                )}
                                />
                            </div>}
                    </div> : undefined}
                    {activeOption == "Positions History" ? <div className="processesHistoryTable withExpandMenu column3">
                        <div className="total-profit">
                            <label className={totalProfitProcessesHistory > 0 ? "success" : (totalProfitProcessesHistory < 0 ? "danger" : undefined)}>{formatValue3(totalProfitProcessesHistory, showPlacesUSDT)}</label>
                        </div>
                        <MaterialReactTable columns={columnsProcessesHistory} data={tableDataProcessesHistory} enablePagination={false} enableRowVirtualization={true} initialState={{columnVisibility: { timestamp: false }, sorting: [{id: 'timestamp', desc: true}]}}
                        renderDetailPanel={({ row }) => (
                            <div className="submenu-values">
                                <div className="submenu-values1">
                                    <div>
                                        <span>Entry Date:</span>
                                        <label>{row.original.entry_date}</label>
                                    </div>
                                    <div>
                                        <span>Close Date:</span>
                                        <label>{row.original.result_date}</label>
                                    </div>
                                    <div>
                                        <span>Leverage:</span>
                                        <label>{row.original.leverage}</label>
                                    </div>
                                    <div className="take_profit">
                                        <span>Take Profit:</span>
                                        <label>{row.original.take_profit}</label>
                                    </div>
                                    <div className="stop_loss">
                                        <span>Stop Loss:</span>
                                        <label>{row.original.stop_loss}</label>
                                    </div>
                                </div>
                                <div className="submenu-values2" data-order_id={row.original.order_id}><div className="empty-details"><div className="loading"></div><Loading /></div></div>
                            </div>
                        )}
                        />
                    </div> : undefined}
                    {activeOption == "Open Orders" ? 
                        (viewTypeOpenOrdersSpecial == "pc" ? 
                            <div className="column9 openClosedOrders">
                                <MaterialReactTable columns={columnsOpen} data={tableDataOpen} enablePagination={false} initialState={{sorting: [{id: 'timestamp', desc: true}]}} />
                            </div> : 
                            <div className="column3 mobile-table openClosedOrders">
                                <MaterialReactTable columns={columnsOpenMobile} data={tableDataOpenMobile} enablePagination={false} initialState={{sorting: [{id: 'timestamp', desc: true}]}} />
                            </div>)
                    : undefined}
                    {activeOption == "Closed Orders" ? 
                        (viewType == "pc" ? 
                            <div className="column7 openClosedOrders">
                                <MaterialReactTable columns={columnsClosed} data={tableDataClosed} enablePagination={false} initialState={{sorting: [{id: 'timestamp', desc: true}]}} />
                            </div> : 
                            <div className="column3 mobile-table openClosedOrders">
                                <MaterialReactTable columns={columnsClosedMobile} data={tableDataClosedMobile} enablePagination={false} initialState={{sorting: [{id: 'timestamp', desc: true}]}} />
                            </div>)
                    : undefined}
                    {userInfo && userInfo.future_permission && userInfo.future_permission == "False" && <div className="verify-info"><span>You must verify to perform future transactions!</span><Link to="/verification">Verification</Link></div>}
                </div>
                }
            </div>
        </div>
    );
};

export default withLoadingData()(React.memo(SectionFutureDemoOrderHistory, isEqual));

import moment from "moment-timezone";
import * as d3 from "d3";
import { setDataWithLastValues, calcPriceAndPercentageDifference } from './calculationFunctions';

const dateFormat = d3.timeParse("%Y-%m-%d %H:%M");

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const timestampToDatetime = (timeType, timeCount, timestamp) => {
  switch (timeType) {
    case "minute":
    case "minutes":
      return (
        moment(timestamp)
          .minute(
            Math.floor(moment(timestamp).minute() / timeCount) * timeCount
          )
          .second(0)
          .format("YYYY-MM-DD HH:mm")
      );
    case "hour":
    case "hours":
      return (
        moment(timestamp)
          .hour(Math.floor(moment(timestamp).hour() / timeCount) * timeCount)
          .minute(0)
          .second(0)
          .format("YYYY-MM-DD HH:mm")
      );
    case "day":
    case "days":
      return moment(timestamp)
        .hour(9)
        .minute(0)
        .second(0)
        .format("YYYY-MM-DD HH:mm");
    case "week":
    case "weeks":
      return moment(timestamp)
        .startOf('isoWeek')
        .format("YYYY-MM-DD HH:mm");
    case "month":
    case "months":
      return moment(timestamp)
        .startOf('month')
        .format("YYYY-MM-DD HH:mm");
    case "year":
    case "years":
      return moment(timestamp)
        .startOf('year')
        .format("YYYY-MM-DD HH:mm");
    default:
      return undefined;
  }
};

const userInfoUtils = {
  init: (response, state) => {
    //console.log("userInfoUtils - init");
    
    const data = response.customer[0];
    
    //console.log(data);
    return data;
  },
  update: (data, state) => {
    //console.log("userInfoUtils - update");

    const userInfoData = state.kprice.userInfo.data;
    
    if(data.onCustomerUpdated.message_type == "Verification"){
      const newUserInfoData = { ...userInfoData };

      Object.keys(newUserInfoData).forEach(key => {
        newUserInfoData[key] = null;
      });

      data.onCustomerUpdated.extras.forEach(item => {
        newUserInfoData[item.name] = item.value;
      });

      //console.log(newUserInfoData);
      return newUserInfoData;
    }

    //console.log(userInfoData);
    return userInfoData;
  },
};

const notificationsUtils = {
  init: (response, state) => {
    //console.log("notificationsUtils - init");

    const data = response;
    data.notifications.forEach(notification => {
      notification.event_date = moment(notification.event_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
    });
    data.updated_log_id = null;
    data.updated_log_id_info = null;

    //console.log(data);
    return data;
  },
  more: (response, state) => {
    //console.log("notificationsUtils - more");

    const notificationsData = state.kprice.notifications.data;
    const newData = {...notificationsData};

    let newNotifications = response.notifications;
    newNotifications.forEach(notification => {
      notification.event_date = moment(notification.event_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
    });

    newData.notifications = [...newData.notifications, ...newNotifications];
    newData.last_evaluated_key = response.last_evaluated_key;
    newData.unread_notifications_length = response.unread_notifications_length;
    newData.updated_log_id = null;
    newData.updated_log_id_info = null;

    //console.log(newData);
    return newData;
  },
  update: (data, state) => {
    //console.log("notificationsUtils - update");

    const notificationsData = state.kprice.notifications.data;
    
    if(data.onCustomerUpdated.message_type == "Notification"){
      const newData = {...notificationsData};
      newData.updated_log_id = null;
      newData.updated_log_id_info = null;
      
      const { extras } = data.onCustomerUpdated;
      const newNotificationExtras = extras.reduce((acc, current) => {
        acc[current.name] = current.value;
        return acc;
      }, {});

      newData.unread_notifications_length = newNotificationExtras.unread_notifications_length;

      const newNotification = {
        log_id: newNotificationExtras.log_id,
        event_timestamp: newNotificationExtras.event_timestamp,
        event_date: moment(newNotificationExtras.event_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
        notification_type: newNotificationExtras.notification_type,
        state: newNotificationExtras.state,
        notification_deleted: newNotificationExtras.notification_deleted,
        ...(newNotificationExtras.notification_type === "Verification" ? 
        {
          notification_extras: {
            type: newNotificationExtras.type,
            new_status_level: newNotificationExtras.new_status_level,
          }
        } : newNotificationExtras.notification_type === "Welcome" ? {
          notification_extras: {
            masked_email: newNotificationExtras.masked_email,
          }
        } : newNotificationExtras.notification_type === "Withdraw" ? {
          notification_extras: {
            currency: newNotificationExtras.currency,
            count: newNotificationExtras.count,
            status: newNotificationExtras.status,
          }
        } : newNotificationExtras.notification_type === "Deposit" ? {
          notification_extras: {
            currency: newNotificationExtras.currency,
            count: newNotificationExtras.count,
            status: newNotificationExtras.status,
          }
        } : newNotificationExtras.notification_type === "Transfer" ? {
          notification_extras: {
            currency: newNotificationExtras.currency,
            count: newNotificationExtras.count,
            status: newNotificationExtras.status,
            transfer_type: newNotificationExtras.transfer_type,
          }
        } : {})
      };

      let found = false;
      for (let i = 0; i < newData.notifications.length; i++) {
        if (newData.notifications[i].log_id === newNotification.log_id) {
          found = true;
          const originalState = newData.notifications[i].state;
          const canUpdate = (originalState == "0") || (originalState == "1" && newNotification.state == "1") || (originalState == "2" && (newNotification.state == "1" || newNotification.state == "2"));
          
          if (canUpdate) {
            newData.notifications[i] = newNotification;
          }
        }
      }

      const canAdd = newData ? (newData.last_evaluated_key ? (Number(newData.last_evaluated_key.event_timestamp) < newNotification.event_timestamp ? true : false) : true) : true;

      if (!found && canAdd) {
        newData.updated_log_id = [newNotification.log_id];
        newData.updated_log_id_info = "update";
        newData.notifications.unshift(newNotification);
      }

      //console.log(newData);
      return newData;
    }
    else if(data.onCustomerUpdated.message_type == "NotificationMultipleUpdate"){
      const newData = {...notificationsData};
      newData.updated_log_id = null;
      newData.updated_log_id_info = null;

      newData.unread_notifications_length = 0;

      const updateType = data.onCustomerUpdated.extras.find(extra => extra.name === "update_type");

      if(updateType.value == "readed"){
        for (let i = 0; i < newData.notifications.length; i++) {
          if (newData.notifications[i].state != "1"){
            newData.notifications[i].state = "2";
          }
        }
      }
      else if(updateType.value == "deleted"){
        for (let i = 0; i < newData.notifications.length; i++) {
          newData.notifications[i].state = "1";
          newData.notifications[i].notification_deleted = "1";
        }
      }

      //console.log(newData);
      return newData;
    }
    
    //console.log(notificationsData);
    return notificationsData;
  },
  updateByApi: (data, state) => {
    //console.log("notificationsUtils - updateByApi");

    const notificationsData = state.kprice.notifications.data;

    if(data.type == "single"){
      const newData = {...notificationsData};
      newData.updated_log_id = null;
      newData.updated_log_id_info = null;

      newData.unread_notifications_length = data.unread_notifications_length;

      const newNotification = data.notification;
      newNotification.event_timestamp = newNotification.event_timestamp;
      newNotification.event_date = moment(newNotification.event_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");

      for (let i = 0; i < newData.notifications.length; i++) {
        if (newData.notifications[i].log_id === newNotification.log_id) {
          const originalState = newData.notifications[i].state;
          const canUpdate = (originalState == "0") || (originalState == "1" && newNotification.state == "1") || (originalState == "2" && (newNotification.state == "1" || newNotification.state == "2"));
          
          if (canUpdate) {
            newData.updated_log_id = [newNotification.log_id];
            newData.updated_log_id_info = newNotification.state == "1" ? "delete" : "update";
            newData.notifications[i] = newNotification;
          }
        }
      }

      //console.log(newData);
      return newData;
    }
    else if(data.type == "multiple"){
      const newData = {...notificationsData};
      newData.updated_log_id = null;
      newData.updated_log_id_info = null;

      newData.unread_notifications_length = data.unread_notifications_length;

      if(data.message == "All notifications marked as read."){
        let logIds = [];
        for (let i = 0; i < newData.notifications.length; i++) {
          if (newData.notifications[i].state == "0"){
            newData.notifications[i].state = "2";
            logIds.push(newData.notifications[i].log_id);
          }
        }
        if(logIds.length > 0){
          newData.updated_log_id = logIds;
          newData.updated_log_id_info = "update";
        }
      }
      else if(data.message == "All notifications marked as deleted."){
        for (let i = 0; i < newData.notifications.length; i++) {
          newData.notifications[i].state = "1";
          newData.notifications[i].notification_deleted = "1";
        }
      }

      //console.log(newData);
      return newData;
    }
    
    //console.log(notificationsData);
    return notificationsData;
  },
};

const productsDataUtils = {
  init: (products, state) => {
    //console.log("productsDataUtils - init");
    const productsNewData = products.products;
    const productsVariables = products.variables;
    const productsCurrencies = products.currencies;
    let spot_commission_rate = null;
    let currency_max_before_places = null;
    let future_commission_rate = null;
    const tempProductsData = {};
    const tempCurrenciesData = {};
    const tempProductsMiniTickerData = {};
    const tempProductsFastData = {};

    productsVariables.forEach((i) => {
      if(i.name == "spot_commission_rate"){
        spot_commission_rate = i.value;
      }
      else if(i.name == "currency_max_before_places"){
        currency_max_before_places = i.value;
      }
      else if(i.name == "future_commission_rate"){
        future_commission_rate = i.value;
      }
    });

    productsNewData.forEach((item) => {
      //fiyat farkını ve yüzdesini hesaplama işlemleri
      const [price_difference, percentage_difference] = calcPriceAndPercentageDifference(item["24h_o"], item["24h_c"]);

      tempProductsData[item.product_id] = {
        currentPrice: item.price,
        previousCurrentPrice: null,
        currentPriceTimestamp: Date.now(),
        currentPriceLastFirstTradeId: null,
        price_difference_24h: price_difference,
        percentage_difference_24h: percentage_difference,
        c_24h: item["24h_c"],
        h_24h: item["24h_h"],
        l_24h: item["24h_l"],
        o_24h: item["24h_o"],
        q_24h: item["24h_q"],
        v_24h: item["24h_v"],
        max_before_places: item.max_before_places,
        max_order_asking_price_places: item.max_order_asking_price_places,
        max_order_count_places: item.max_order_count_places,
        min_order_count: item.min_order_count,
        min_order_precision: item.min_order_precision,
        show_places : item.show_places,
        spot_commission_rate : spot_commission_rate,
        future_commission_rate : future_commission_rate,
        flags: 0,
        status_spot: item.status_spot ? item.status_spot : null,
        status_future: item.status_future ? item.status_future : null,
        future_leverages: item.future_leverages ? item.future_leverages : [],
        candles: [
        ],
        last24h: [
        ]
      };

      tempProductsMiniTickerData[item.product_id] = {
        price_difference_24h: price_difference,
        percentage_difference_24h: percentage_difference,
        c_24h: item["24h_c"],
        h_24h: item["24h_h"],
        l_24h: item["24h_l"],
        o_24h: item["24h_o"],
        q_24h: item["24h_q"],
        v_24h: item["24h_v"],
        currentPrice: item.price,
        previousCurrentPrice: null,
        currentPriceTimestamp: Date.now(),
        currentPriceLastFirstTradeId: null,
        flags: 0,
      };

      tempProductsFastData[item.product_id] = {
        price_difference_24h: price_difference,
        percentage_difference_24h: percentage_difference,
        c_24h: item["24h_c"],
        h_24h: item["24h_h"],
        l_24h: item["24h_l"],
        o_24h: item["24h_o"],
        q_24h: item["24h_q"],
        v_24h: item["24h_v"],
        currentPrice: item.price,
        previousCurrentPrice: null,
        currentPriceTimestamp: Date.now(),
        currentPriceLastFirstTradeId: null,
        flags: 0,
      };
    });

    productsCurrencies.forEach((item) => {
      tempCurrenciesData[item.currency] = {
        currency: item.currency,
        currency_name: item.currency_name,
        picture_url: item.picture_url,
        show_places: item.show_places
      };
    });

    //default selected product belirleniyor ve atanıyor
    const tempProductsDataKeys = tempProductsData ? Object.keys(tempProductsData) : [];
    let tempSelectedProduct = null;
    if (tempProductsDataKeys.includes("BTC-USDT")) {
      tempSelectedProduct = "BTC-USDT";
    } else {
      tempSelectedProduct = tempProductsDataKeys[0];
    }
    //console.log(currency_max_before_places);
    state.kprice.currencyMaxBeforePlaces = currency_max_before_places;
    state.kprice.selectedProduct = tempSelectedProduct;
    state.kprice.productsFast.data = tempProductsFastData;
    state.kprice.productsMiniTicker.data = tempProductsMiniTickerData;
    state.kprice.currencies.data = tempCurrenciesData;
    
    //console.log("default selectedProduct: " + tempSelectedProduct);
    //console.log(tempProductsData);
    //console.log(tempProductsMiniTickerData);
    //console.log(tempCurrenciesData);
    return tempProductsData;
  },
  update: (candle, state) => {
    //console.log(candle)
    //console.log("tradingview abonelikten bilgi geldi");
    const productsFastData = state.kprice.productsFast.data;
    const newData = { ...productsFastData };
    
    const product_id = candle.product_id ? candle.product_id : null;
    if(product_id){
      const current_price = candle.current_price ? candle.current_price : newData[product_id].currentPrice;
      const flags_value = candle.FLAGS ? candle.FLAGS : newData[product_id].flags;
      const last_MT_value = candle.last_MT ? candle.last_MT : newData[product_id].currentPriceTimestamp;
      const last_f_value = candle.last_f ? candle.last_f : newData[product_id].currentPriceLastFirstTradeId;
      candle.code = product_id;
      const last_trades_obj = candle.last_trades ? candle.last_trades : null;

      if(last_trades_obj.MT){
        last_trades_obj.time = moment(last_trades_obj.MT).tz(timeZone).format("HH:mm:ss");
      }

      const last_trades = [];
      last_trades.push(last_trades_obj);

      //last trades alanı başlangıç
      const lastTradesProductId = state.kprice.selectedProduct;
      if(lastTradesProductId == product_id){//gelen candle verisi ile selected product verisi aynı ise last trades bilgilerini güncelliyoruz (grafikte compare yapınca 1den fazla product için veri gelebiliyor, last trades sürekli sıfırlanıyor. bu yüzden engelledik.)
        const lastTradesData = state.kprice.lastTrades.data;
        let lastTradesDataNew = { ...lastTradesData };
        
        if(!lastTradesDataNew.product_id || lastTradesDataNew.product_id !== product_id){
          lastTradesDataNew = {
            product_id: product_id,
            last_trades: last_trades
          };
        }
        else if(Object.keys(lastTradesDataNew).length === 0 || !lastTradesDataNew.last_trades){
          lastTradesDataNew.last_trades = last_trades;
        }
        else if(Array.isArray(lastTradesDataNew.last_trades)){
          lastTradesDataNew.last_trades = [...last_trades, ...lastTradesDataNew.last_trades];
        }
        else{
          lastTradesDataNew.last_trades = last_trades;
        }

        if (lastTradesDataNew.last_trades.length > 1) {
          lastTradesDataNew.last_trades.sort((a, b) => {
            if (b.MT === a.MT) {
              return b.f - a.f;
            }
            return b.MT - a.MT;
          });
        }

        if (lastTradesDataNew.last_trades.length > 50) {
          lastTradesDataNew.last_trades = lastTradesDataNew.last_trades.slice(0, 50);
        }

        state.kprice.lastTrades.data = lastTradesDataNew;
      }
      //last trades alanı son
      
      if(newData[product_id].o_24h && current_price){
        const [price_difference, percentage_difference] = calcPriceAndPercentageDifference(newData[product_id].o_24h, current_price);
        newData[product_id].price_difference_24h = price_difference;
        newData[product_id].percentage_difference_24h = percentage_difference;
      }
      
      if(current_price && last_MT_value && flags_value && last_f_value){
        newData[product_id] = setDataWithLastValues(newData[product_id], current_price, last_MT_value, flags_value, last_f_value);
      }
      
      candle.trade_price = last_trades.p != undefined ? last_trades.p : null;
      
      const close = candle.trade_price;
      if(close){
        if(newData[product_id].h_24h < close){newData[product_id].h_24h = close;}
        if(newData[product_id].l_24h > close){newData[product_id].l_24h = close;}
      }
    }
    
    //console.log(newData);
    return newData;
  },
  miniTicker: (products, state) => {
    //console.log(products)
    const productsMiniTickerData = state.productsMiniTicker.data;
    const newProductsData = { ...productsMiniTickerData };

    Object.entries(products).forEach((itemArray) => {
      const item = itemArray && itemArray.length > 0 ? itemArray[1] : null;
      item.product_id = itemArray && itemArray.length > 0 ? itemArray[0] : null;
      
      if(item && item.product_id){
        const item_id = item.product_id;

        // Ürün verisi yoksa yeni bir veri oluştur
        if(!newProductsData[item_id]){
          newProductsData[item_id] = {
            price_difference_24h: null,
            percentage_difference_24h: null,
            c_24h: null,
            h_24h: null,
            l_24h: null,
            o_24h: null,
            q_24h: null,
            v_24h: null,
            currentPrice: null,
            currentPriceTimestamp: Date.now(),
            currentPriceLastFirstTradeId: null,
            previousCurrentPrice: null,
            flags: null,
          };
        }

        // Güncel değerlerin kaydedilmesi
        newProductsData[item_id].c_24h = item.c ? item.c : newProductsData[item_id].c_24h;
        newProductsData[item_id].h_24h = item.h ? item.h : newProductsData[item_id].h_24h;
        newProductsData[item_id].l_24h = item.l ? item.l : newProductsData[item_id].l_24h;
        newProductsData[item_id].o_24h = item.o ? item.o : newProductsData[item_id].o_24h;
        newProductsData[item_id].q_24h = item.q ? item.q : newProductsData[item_id].q_24h;
        newProductsData[item_id].v_24h = item.v ? item.v : newProductsData[item_id].v_24h;

        const last_MT_value = item.last_MT ? item.last_MT : newProductsData[item_id].currentPriceTimestamp;

        if(newProductsData[item_id].o_24h && newProductsData[item_id].c_24h){
          const [price_difference, percentage_difference] = calcPriceAndPercentageDifference(newProductsData[item_id].o_24h, newProductsData[item_id].c_24h);
          newProductsData[item_id].price_difference_24h = price_difference;
          newProductsData[item_id].percentage_difference_24h = percentage_difference;
        }
        
        // CurrentPrice ve currentPriceTimestamp değerlerinin güncellenmesi
        if(newProductsData[item_id].c_24h && last_MT_value){
          newProductsData[item_id] = setDataWithLastValues(newProductsData[item_id], newProductsData[item_id].c_24h, last_MT_value);
        }

        if(newProductsData[item_id].currentPrice && newProductsData[item_id].previousCurrentPrice){
          if(newProductsData[item_id].previousCurrentPrice < newProductsData[item_id].currentPrice){
            newProductsData[item_id].flags = 1;
          }
          else if(newProductsData[item_id].previousCurrentPrice > newProductsData[item_id].currentPrice){
            newProductsData[item_id].flags = 2;
          }
          else{
            newProductsData[item_id].flags = 0;
          }
        }
      }
    });
    
    //console.log(newProductsData);
    return newProductsData;
  },
  miniTickerFast: (products, state) => {
    //console.log(products)
    const productsFastData = state.kprice.productsFast.data;
    const newProductsData = { ...productsFastData };
    const lastEvents = products.onMiniTickerUpdated.last_events;

    lastEvents.forEach((item) => {
      if(item && item.product_id){
        const item_id = item.product_id;

        // Ürün verisi yoksa yeni bir veri oluştur
        if(!newProductsData[item_id]){
          newProductsData[item_id] = {
            price_difference_24h: null,
            percentage_difference_24h: null,
            c_24h: null,
            h_24h: null,
            l_24h: null,
            o_24h: null,
            q_24h: null,
            v_24h: null,
            currentPrice: null,
            currentPriceTimestamp: Date.now(),
            currentPriceLastFirstTradeId: null,
            previousCurrentPrice: null,
            flags: null,
          };
        }

        // Güncel değerlerin kaydedilmesi
        newProductsData[item_id].c_24h = item.c ? item.c : newProductsData[item_id].c_24h;
        newProductsData[item_id].h_24h = item.h ? item.h : newProductsData[item_id].h_24h;
        newProductsData[item_id].l_24h = item.l ? item.l : newProductsData[item_id].l_24h;
        newProductsData[item_id].o_24h = item.o ? item.o : newProductsData[item_id].o_24h;
        newProductsData[item_id].q_24h = item.q ? item.q : newProductsData[item_id].q_24h;
        newProductsData[item_id].v_24h = item.v ? item.v : newProductsData[item_id].v_24h;

        const last_MT_value = item.last_MT ? item.last_MT : newProductsData[item_id].currentPriceTimestamp;

        if(newProductsData[item_id].o_24h && newProductsData[item_id].c_24h){
          const [price_difference, percentage_difference] = calcPriceAndPercentageDifference(newProductsData[item_id].o_24h, newProductsData[item_id].c_24h);
          newProductsData[item_id].price_difference_24h = price_difference;
          newProductsData[item_id].percentage_difference_24h = percentage_difference;
        }
        
        // CurrentPrice ve currentPriceTimestamp değerlerinin güncellenmesi
        if(newProductsData[item_id].c_24h && last_MT_value){
          newProductsData[item_id] = setDataWithLastValues(newProductsData[item_id], newProductsData[item_id].c_24h, last_MT_value);
        }

        if(newProductsData[item_id].currentPrice && newProductsData[item_id].previousCurrentPrice){
          if(newProductsData[item_id].previousCurrentPrice < newProductsData[item_id].currentPrice){
            newProductsData[item_id].flags = 1;
          }
          else if(newProductsData[item_id].previousCurrentPrice > newProductsData[item_id].currentPrice){
            newProductsData[item_id].flags = 2;
          }
          else{
            newProductsData[item_id].flags = 0;
          }
        }
      }
    });
    
    //console.log(newProductsData);
    return newProductsData;
  },
  last24hdata: (products, state) => {
    //console.log("PRODUCT24H24H24H");
    //console.log(products);
    let productsStateData = state.kprice.products.data;
    let productsKeys = Object.keys(products.product_ids);

    productsKeys.forEach((item) => {
      const newvalues = products.product_ids[item];

      let data = [];

      for (let i = 0; i < newvalues.length; i++) {
        data.push({
          close: newvalues[i].close,
          timestamp: newvalues[i].timestamp,
        })
      }

      let newData = {
        ...productsStateData,
        [item]: {
          ...productsStateData[item],
          last24h: data
        },
      };

      productsStateData = newData;
    });

    //console.log(productsStateData);
    return productsStateData;
  },
  updateForProcesses: (products, state) => {
    //console.log("processes abonelikten bilgi geldi");
    //console.log(products);
    const productsFastData = state.productsFast.data;
    const newProduct = products;
    const newProductsData = { ...productsFastData };
    
    if(newProduct.product_id){
      const item_id = newProduct.product_id;

      //güncel currentPrice ve currentPriceTimestamp değerlerinin güncellenmesi
      const current_price_value = newProduct.current_price ? newProduct.current_price : newProductsData[item_id].currentPrice;
      const last_MT_value = newProduct.last_MT ? newProduct.last_MT : newProductsData[item_id].currentPriceTimestamp;
      const last_f_value = newProduct.last_f ? newProduct.last_f : newProductsData[item_id].currentPriceLastFirstTradeId;

      if(current_price_value && last_MT_value && last_f_value){
        newProductsData[item_id] = setDataWithLastValues(newProductsData[item_id], current_price_value, last_MT_value, null, last_f_value);
      }
    }
    
    //console.log(newProductsData);
    return newProductsData;
  },
  lastTrades: (data, state) => {
    //console.log("productsDataUtils - lastTrades");
    //console.log(data)
    let lastTrades = [];
    let productId = null;

    lastTrades = Object.keys(data).map(key => {
      const trade = data[key];
      productId = trade.product_id;

      return {
        MT: trade.timestamp,
        time: moment(trade.timestamp).tz(timeZone).format("HH:mm:ss"),
        f: trade.f,
        m: trade.m,
        p: trade.price,
        q: trade.quote_volume,
        v: trade.base_volume
      };
    });

    lastTrades.sort((a, b) => {
      if (b.MT === a.MT) {
        return b.f - a.f;
      }
      return b.MT - a.MT;
    });

    if (lastTrades.length > 50) {
      lastTrades = lastTrades.slice(0, 50);
    }

    const newData = {
      product_id : productId,
      last_trades : lastTrades
    };
    
    //console.log(newData);
    return newData;
  },
};

const orderHistoryUtils = {
  init: (response, state) => {
    //console.log("orderHistoryUtils - init");
    const openOrdersData = response.openOrders;
    const closedOrdersData = response.closedOrders;
    const data = {};
    data["openOrders"] = [];
    data["closedOrders"] = [];
    data["updateData"] = [];

    closedOrdersData.forEach((item) => {
      let total = "";
      if(item.total != undefined){
        total = item.total;
      }
      data["closedOrders"].push({
        order_id: item.order_id,
        customer_id: item.customer_id,
        result_timestamp: item.result_timestamp,
        result_date: moment(item.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
        product_id: item.product_id,
        asking_price: item.asking_price,
        processing_price: item.processing_price,
        count: item.count,
        realized_count: item.realized_count,
        currency_fee: item.currency_fee,
        to_currency_fee: item.to_currency_fee,
        type: item.type,
        state: item.state,
        total: total
      });
    });

    openOrdersData.forEach((item) => {
      let stoploss = "";
      let takeprofit = "";
      if(item.stop_loss != undefined){
        stoploss = item.stop_loss;
      }
      if(item.take_profit != undefined){
        takeprofit = item.take_profit;
      }
      data["openOrders"].push({
        order_id: item.order_id,
        customer_id: item.customer_id,
        entry_timestamp: item.entry_timestamp,
        entry_date: moment(item.entry_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
        product_id: item.product_id,
        asking_price: item.asking_price,
        count: item.count,
        type: item.type,
        state: item.state,
        stop_loss: stoploss,
        take_profit: takeprofit
      });
    });

    
    //console.log(data);
    return data;
  },
  update: (orders, state) => {
    //console.log("orderHistoryUtils - update");

    const orderHistoryData = state.kprice.orderHistory.data;

    if(orders.onCustomerUpdated.message_type == "UpdateSpotOrders"){
      const orderHistoryDataCopy = { ...orderHistoryData };
      const stateOpen = orderHistoryDataCopy.openOrders;
      const stateClosed = orderHistoryDataCopy.closedOrders;
      const stateUpdateData = orderHistoryDataCopy.updateData;
      const newOrder = orders.onCustomerUpdated.extras;
      const newStateData = {};
      const newData = {};
      let newDataUpdated = false;//state bilgileri güncellenmeli mi
      let newDataState = null;//yeni gelen bilginin state'i
      
      let existInOpen = false;//open içinde var mı
      let existInOpenIndex = null;//open içinde varsa index'i
      let existInOpenState = null;//open içinde varsa state'i
      
      let existInClosed = false;//closed içinde var mı
      let existInClosedIndex = null;//closed içinde varsa index'i
      let existInClosedState = null;//closed içinde varsa state'i

      for (let i = 0; i < newOrder.length; i++) {//yeni gelen order bilgisi temize çekiliyor
        if(newOrder[i].name == "state"){
          newDataState = newOrder[i].value;
        }
        newData[newOrder[i].name] = newOrder[i].value;
        if(newOrder[i].name == "entry_timestamp"){
          newData["entry_date"] = moment(newOrder[i].value).tz(timeZone).format("YYYY-MM-DD HH:mm");
        }
        else if(newOrder[i].name == "result_timestamp"){
          newData["result_date"] = moment(newOrder[i].value).tz(timeZone).format("YYYY-MM-DD HH:mm");
        }
      }

      for (let i = stateOpen.length - 1; i >= 0; i--) {//open içinde var mı
        if (stateOpen[i].order_id == newData.order_id) {
          existInOpen = true;
          existInOpenIndex = i;
          existInOpenState = stateOpen[i].state;
        }
      }

      for (let i = stateClosed.length - 1; i >= 0; i--) {//closed içinde var mı
        if (stateClosed[i].order_id == newData.order_id) {
          existInClosed = true;
          existInClosedIndex = i;
          existInClosedState = stateClosed[i].state;
        }
      }

      //silme işlemleri
      if(existInOpen){//open içinde var ise silme işlemi
        if(!(existInOpenState == "processing" && newDataState == "waiting")){//open içinde processing var ama yeni veri waiting olarak gelmiş ise silmiyoruz, bu durum dışındakiler silinecek
          stateOpen.splice(existInOpenIndex, 1);
          existInOpen = false;
          newDataUpdated = true;
        }
      }
      else if(existInClosed){//closed içinde var ise silme işlemi
        if(newDataState != "waiting" && newDataState != "processing"){//waiting ve processing gelmediyse siliyoruz
          stateClosed.splice(existInClosedIndex, 1);
          existInClosed = false;
          newDataUpdated = true;
        }
      }

      //ekleme işlemleri
      if(!existInOpen && !existInClosed && (newDataState == "waiting" || newDataState == "processing")){//hem open hem closed içinde yok ise ve waiting ya da processing geldiyse
        newDataUpdated = true;

        let stoploss = "";
        let takeprofit = "";
        if(newData.stop_loss != undefined){
          stoploss = newData.stop_loss;
        }
        if(newData.take_profit != undefined){
          takeprofit = newData.take_profit;
        }
        stateOpen.push({
          order_id: newData.order_id,
          customer_id: newData.customer_id,
          entry_timestamp: newData.entry_timestamp,
          entry_date: moment(newData.entry_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
          product_id: newData.product_id,
          asking_price: newData.asking_price,
          count: newData.count,
          type: newData.type,
          state: newData.state,
          stop_loss: stoploss,
          take_profit: takeprofit
        });
      }
      else if(!existInClosed && (newDataState == "approved" || newDataState == "denied")){
        newDataUpdated = true;

        let total = "";
        if(newData.total != undefined){
          total = newData.total;
        }
        stateClosed.push({
          order_id: newData.order_id,
          customer_id: newData.customer_id,
          result_timestamp: newData.result_timestamp,
          result_date: moment(newData.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
          product_id: newData.product_id,
          asking_price: newData.asking_price,
          processing_price: newData.processing_price,
          count: newData.count,
          realized_count: newData.realized_count,
          currency_fee: newData.currency_fee,
          to_currency_fee: newData.to_currency_fee,
          type: newData.type,
          state: newData.state,
          total: total
        });
      }

      newStateData["openOrders"] = stateOpen;
      newStateData["closedOrders"] = stateClosed;
      newStateData["updateData"] = newData;
      //console.log(newStateData);
      return newStateData;
    }

    //console.log(orderHistoryData);
    return orderHistoryData;
  },
};

const futureOrderHistoryUtils = {
  init: (response, state) => {
    //console.log("futureOrderHistoryUtils - init");
    const openOrdersData = response.openOrders;
    const closedOrdersData = response.closedOrders;
    const data = {};
    data["openOrders"] = [];
    data["closedOrders"] = [];
    data["updateData"] = [];

    closedOrdersData.forEach((item) => {
      let total = "";
      if(item.total != undefined){
        total = item.total;
      }
      data["closedOrders"].push({
        order_id: item.order_id,
        customer_id: item.customer_id,
        result_timestamp: item.result_timestamp,
        result_date: moment(item.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
        product_id: item.product_id,
        asking_price: item.asking_price,
        processing_price: item.processing_price,
        count: item.count,
        realized_count: item.realized_count,
        leverage: item.leverage,
        type: item.type,
        state: item.state,
        total: total
      });
    });

    openOrdersData.forEach((item) => {
      let stoploss = "";
      let takeprofit = "";
      if(item.stop_loss != undefined){
        stoploss = item.stop_loss;
      }
      if(item.take_profit != undefined){
        takeprofit = item.take_profit;
      }
      data["openOrders"].push({
        order_id: item.order_id,
        customer_id: item.customer_id,
        entry_timestamp: item.entry_timestamp,
        entry_date: moment(item.entry_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
        product_id: item.product_id,
        asking_price: item.asking_price,
        count: item.count,
        leverage: item.leverage,
        type: item.type,
        state: item.state,
        stop_loss: stoploss,
        take_profit: takeprofit
      });
    });

    
    //console.log(data);
    return data;
  },
  update: (orders, state) => {
    //console.log("futureOrderHistoryUtils - update");

    const futureOrderHistoryData = state.kprice.futureOrderHistory.data;
    
    if(orders.onCustomerUpdated.message_type == "UpdateFutureOrders"){
      const futureOrderHistoryDataCopy = { ...futureOrderHistoryData };
      const newStateData = {};
      const stateOpen = futureOrderHistoryDataCopy.openOrders;
      const stateClosed = futureOrderHistoryDataCopy.closedOrders;
      const stateUpdateData = futureOrderHistoryDataCopy.updateData;
      const newOrder = orders.onCustomerUpdated.extras;
      const newData = {};
      let newDataUpdated = false;//state bilgileri güncellenmeli mi
      let newDataState = null;//yeni gelen bilginin state'i
      
      let existInOpen = false;//open içinde var mı
      let existInOpenIndex = null;//open içinde varsa index'i
      let existInOpenState = null;//open içinde varsa state'i
      
      let existInClosed = false;//closed içinde var mı
      let existInClosedIndex = null;//closed içinde varsa index'i
      let existInClosedState = null;//closed içinde varsa state'i

      for (let i = 0; i < newOrder.length; i++) {//yeni gelen order bilgisi temize çekiliyor
        if(newOrder[i].name == "state"){
          newDataState = newOrder[i].value;
        }
        newData[newOrder[i].name] = newOrder[i].value;
        if(newOrder[i].name == "entry_timestamp"){
          newData["entry_date"] = moment(newOrder[i].value).tz(timeZone).format("YYYY-MM-DD HH:mm");
        }
        else if(newOrder[i].name == "result_timestamp"){
          newData["result_date"] = moment(newOrder[i].value).tz(timeZone).format("YYYY-MM-DD HH:mm");
        }
      }

      for (let i = stateOpen.length - 1; i >= 0; i--) {//open içinde var mı
        if (stateOpen[i].order_id == newData.order_id) {
          existInOpen = true;
          existInOpenIndex = i;
          existInOpenState = stateOpen[i].state;
        }
      }

      for (let i = stateClosed.length - 1; i >= 0; i--) {//closed içinde var mı
        if (stateClosed[i].order_id == newData.order_id) {
          existInClosed = true;
          existInClosedIndex = i;
          existInClosedState = stateClosed[i].state;
        }
      }

      //silme işlemleri
      if(existInOpen){//open içinde var ise silme işlemi
        if(!(existInOpenState == "processing" && newDataState == "waiting")){//open içinde processing var ama yeni veri waiting olarak gelmiş ise silmiyoruz, bu durum dışındakiler silinecek
          stateOpen.splice(existInOpenIndex, 1);
          existInOpen = false;
          newDataUpdated = true;
        }
      }
      else if(existInClosed){//closed içinde var ise silme işlemi
        if(newDataState != "waiting" && newDataState != "processing"){//waiting ve processing gelmediyse siliyoruz
          stateClosed.splice(existInClosedIndex, 1);
          existInClosed = false;
          newDataUpdated = true;
        }
      }

      //ekleme işlemleri
      if(!existInOpen && !existInClosed && (newDataState == "waiting" || newDataState == "processing")){//hem open hem closed içinde yok ise ve waiting ya da processing geldiyse
        newDataUpdated = true;

        let stoploss = "";
        let takeprofit = "";
        if(newData.stop_loss != undefined){
          stoploss = newData.stop_loss;
        }
        if(newData.take_profit != undefined){
          takeprofit = newData.take_profit;
        }
        stateOpen.push({
          order_id: newData.order_id,
          customer_id: newData.customer_id,
          entry_timestamp: newData.entry_timestamp,
          entry_date: moment(newData.entry_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
          product_id: newData.product_id,
          asking_price: newData.asking_price,
          count: newData.count,
          leverage: newData.leverage,
          type: newData.type,
          state: newData.state,
          stop_loss: stoploss,
          take_profit: takeprofit
        });
      }
      else if(!existInClosed && (newDataState == "approved" || newDataState == "denied")){
        newDataUpdated = true;

        let total = "";
        if(newData.total != undefined){
          total = newData.total;
        }
        stateClosed.push({
          order_id: newData.order_id,
          customer_id: newData.customer_id,
          result_timestamp: newData.result_timestamp,
          result_date: moment(newData.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
          product_id: newData.product_id,
          asking_price: newData.asking_price,
          processing_price: newData.processing_price,
          count: newData.count,
          realized_count: newData.realized_count,
          leverage: newData.leverage,
          type: newData.type,
          state: newData.state,
          total: total
        });
      }

      newStateData["openOrders"] = stateOpen;
      newStateData["closedOrders"] = stateClosed;
      newStateData["updateData"] = newData;
      //console.log(newStateData);
      return newStateData;
    }

    //console.log(futureOrderHistoryData);
    return futureOrderHistoryData;
  },
};

const futureProcessesUtils = {
  init: (data, state) => {
    //console.log("futureProcessesUtils - init");
    const newData = {};

    newData["activeProcesses"] = data.activeProcesses;
    newData["deactiveProcesses"] = data.deactiveProcesses;
    newData["updateData"] = [];

    //console.log(newData);
    return newData;
  },
  update: (data, state) => {
    //console.log("futureProcessesUtils - update ");
    
    const futureProcessesData = state.kprice.futureProcesses.data;

    if(data.onCustomerUpdated.message_type == "UpdateFutureProcesses"){
      const newFutureProcessesData = { ...futureProcessesData };
      const newStateData = {};
      const stateActive = newFutureProcessesData.activeProcesses;
      const stateDeactive = newFutureProcessesData.deactiveProcesses;
      const stateUpdateData = newFutureProcessesData.updateData;
      const newProcess = data.onCustomerUpdated.extras;
      const newData = {};
      let newDataUpdated = false;//state bilgileri güncellenmeli mi
      let newDataState = null;//yeni gelen bilginin state'i
      
      let existInActive = false;//Active içinde var mı
      let existInActiveIndex = null;//Active içinde varsa index'i
      
      let existInDeactive = false;//Deactive içinde var mı
      let existInDeactiveIndex = null;//Deactive içinde varsa index'i

      for (let i = 0; i < newProcess.length; i++) {//yeni gelen process bilgisi temize çekiliyor
        if(newProcess[i].name == "state"){
          newDataState = newProcess[i].value;
        }
        newData[newProcess[i].name] = newProcess[i].value;
        if(newProcess[i].name == "entry_timestamp"){
          newData["entry_date"] = moment(newProcess[i].value).tz(timeZone).format("YYYY-MM-DD HH:mm");
        }
        else if(newProcess[i].name == "result_timestamp"){
          newData["result_date"] = moment(newProcess[i].value).tz(timeZone).format("YYYY-MM-DD HH:mm");
        }
      }

      for (let i = stateActive.length - 1; i >= 0; i--) {//Active içinde var mı
        if (stateActive[i].order_id == newData.order_id) {
          existInActive = true;
          existInActiveIndex = i;
        }
      }

      for (let i = stateDeactive.length - 1; i >= 0; i--) {//Deactive içinde var mı
        if (stateDeactive[i].order_id == newData.order_id) {
          existInDeactive = true;
          existInDeactiveIndex = i;
        }
      }

      //silme işlemleri
      if(existInActive){//Active içinde var ise silme işlemi
        stateActive.splice(existInActiveIndex, 1);
        existInActive = false;
        newDataUpdated = true;
      }
      else if(existInDeactive){//Deactive içinde var ise silme işlemi
        if(newDataState != "active"){//Active gelmediyse siliyoruz (önce deactive gelip ekleniyor ve sonrasında active geliyor durumunu engellemek için)
          stateDeactive.splice(existInDeactiveIndex, 1);
          existInDeactive = false;
          newDataUpdated = true;
        }
      }

      //ekleme işlemleri
      if(!existInActive && !existInDeactive && (newDataState == "active")){//hem Active hem Deactive içinde yok ise ve active geldiyse
        newDataUpdated = true;

        let stoploss = "";
        let takeprofit = "";
        if(newData.stop_loss != undefined){
          stoploss = newData.stop_loss;
        }
        if(newData.take_profit != undefined){
          takeprofit = newData.take_profit;
        }
        stateActive.push({
          order_id: newData.order_id,
          customer_id: newData.customer_id,
          available_count: newData.available_count,
          available_surety: newData.available_surety,
          entry_count: newData.entry_count,
          entry_timestamp: newData.entry_timestamp,
          entry_date: moment(newData.entry_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
          entry_price: newData.entry_price,
          entry_surety: newData.entry_surety,
          future_type: newData.future_type,
          leverage: newData.leverage,
          product_id: newData.product_id,
          state: newData.state,
          stop_loss: stoploss,
          take_profit: takeprofit
        });
      }
      else if(!existInDeactive && newDataState == "deactive"){
        newDataUpdated = true;

        let stoploss = "";
        let takeprofit = "";
        if(newData.stop_loss != undefined){
          stoploss = newData.stop_loss;
        }
        if(newData.take_profit != undefined){
          takeprofit = newData.take_profit;
        }

        stateDeactive.push({
          order_id: newData.order_id,
          customer_id: newData.customer_id,
          available_count: newData.available_count,
          available_surety: newData.available_surety,
          entry_count: newData.entry_count,
          result_timestamp: newData.result_timestamp,
          result_date: moment(newData.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
          entry_price: newData.entry_price,
          entry_surety: newData.entry_surety,
          future_type: newData.future_type,
          leverage: newData.leverage,
          product_id: newData.product_id,
          state: newData.state,
          process_profit: newData.process_profit,
          stop_loss: stoploss,
          take_profit: takeprofit
        });
      }

      newStateData["activeProcesses"] = stateActive;
      newStateData["deactiveProcesses"] = stateDeactive;
      newStateData["updateData"] = newData;
      //console.log(newStateData);
      return newStateData;
    }

    //console.log(futureProcessesData);
    return futureProcessesData;
  },
};

const futureProfilesUtils = {
  init: (data, state) => {
    //console.log("futureProfilesUtils - init");
    //console.log(data);
    return data;
  },
  update: (data, state) => {
    //console.log("futureProfilesUtils - update ");

    const futureProfilesData = state.kprice.futureProfiles.data;

    if(data.onCustomerUpdated.message_type == "UpdateFutureProfiles"){
      const newFutureProfilesData = { ...futureProfilesData };
      const newProfiles = data.onCustomerUpdated.extras;

      for (let i = 0; i < newProfiles.length; i++) {
        newFutureProfilesData[newProfiles[i].name] = newProfiles[i].value;
      }

      //console.log(newFutureProfilesData);
      return newFutureProfilesData;
    }

    //console.log(futureProfilesData);
    return futureProfilesData;
  },
};

const demoFutureOrderHistoryUtils = {
  init: (response, state) => {
    //console.log("demoFutureOrderHistoryUtils - init");
    //console.log(response);
    const openOrdersData = response.openOrders;
    const closedOrdersData = response.closedOrders;
    const data = {};
    data["openOrders"] = [];
    data["closedOrders"] = [];
    data["updateData"] = [];

    closedOrdersData.forEach((item) => {
      let total = "";
      if(item.total != undefined){
        total = item.total;
      }
      data["closedOrders"].push({
        order_id: item.order_id,
        customer_id: item.customer_id,
        result_timestamp: item.result_timestamp,
        result_date: moment(item.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
        product_id: item.product_id,
        asking_price: item.asking_price,
        processing_price: item.processing_price,
        count: item.count,
        realized_count: item.realized_count,
        leverage: item.leverage,
        type: item.type,
        state: item.state,
        total: total
      });
    });

    openOrdersData.forEach((item) => {
      let stoploss = "";
      let takeprofit = "";
      if(item.stop_loss != undefined){
        stoploss = item.stop_loss;
      }
      if(item.take_profit != undefined){
        takeprofit = item.take_profit;
      }
      data["openOrders"].push({
        order_id: item.order_id,
        customer_id: item.customer_id,
        entry_timestamp: item.entry_timestamp,
        entry_date: moment(item.entry_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
        product_id: item.product_id,
        asking_price: item.asking_price,
        count: item.count,
        leverage: item.leverage,
        type: item.type,
        state: item.state,
        stop_loss: stoploss,
        take_profit: takeprofit
      });
    });

    
    //console.log(data);
    return data;
  },
  update: (orders, state) => {
    //console.log("demoFutureOrderHistoryUtils - update");
    //console.log(orders);

    const futureOrderHistoryData = state.kprice.demoFutureOrderHistory.data;
    
    if(orders.onCustomerUpdated.message_type == "UpdateFutureOrdersDemo"){
      const futureOrderHistoryDataCopy = { ...futureOrderHistoryData };
      const newStateData = {};
      const stateOpen = futureOrderHistoryDataCopy.openOrders;
      const stateClosed = futureOrderHistoryDataCopy.closedOrders;
      const stateUpdateData = futureOrderHistoryDataCopy.updateData;
      const newOrder = orders.onCustomerUpdated.extras;
      const newData = {};
      let newDataUpdated = false;//state bilgileri güncellenmeli mi
      let newDataState = null;//yeni gelen bilginin state'i
      
      let existInOpen = false;//open içinde var mı
      let existInOpenIndex = null;//open içinde varsa index'i
      let existInOpenState = null;//open içinde varsa state'i
      
      let existInClosed = false;//closed içinde var mı
      let existInClosedIndex = null;//closed içinde varsa index'i
      let existInClosedState = null;//closed içinde varsa state'i

      for (let i = 0; i < newOrder.length; i++) {//yeni gelen order bilgisi temize çekiliyor
        if(newOrder[i].name == "state"){
          newDataState = newOrder[i].value;
        }
        newData[newOrder[i].name] = newOrder[i].value;
        if(newOrder[i].name == "entry_timestamp"){
          newData["entry_date"] = moment(newOrder[i].value).tz(timeZone).format("YYYY-MM-DD HH:mm");
        }
        else if(newOrder[i].name == "result_timestamp"){
          newData["result_date"] = moment(newOrder[i].value).tz(timeZone).format("YYYY-MM-DD HH:mm");
        }
      }

      for (let i = stateOpen.length - 1; i >= 0; i--) {//open içinde var mı
        if (stateOpen[i].order_id == newData.order_id) {
          existInOpen = true;
          existInOpenIndex = i;
          existInOpenState = stateOpen[i].state;
        }
      }

      for (let i = stateClosed.length - 1; i >= 0; i--) {//closed içinde var mı
        if (stateClosed[i].order_id == newData.order_id) {
          existInClosed = true;
          existInClosedIndex = i;
          existInClosedState = stateClosed[i].state;
        }
      }

      //silme işlemleri
      if(existInOpen){//open içinde var ise silme işlemi
        if(!(existInOpenState == "processing" && newDataState == "waiting")){//open içinde processing var ama yeni veri waiting olarak gelmiş ise silmiyoruz, bu durum dışındakiler silinecek
          stateOpen.splice(existInOpenIndex, 1);
          existInOpen = false;
          newDataUpdated = true;
        }
      }
      else if(existInClosed){//closed içinde var ise silme işlemi
        if(newDataState != "waiting" && newDataState != "processing"){//waiting ve processing gelmediyse siliyoruz
          stateClosed.splice(existInClosedIndex, 1);
          existInClosed = false;
          newDataUpdated = true;
        }
      }

      //ekleme işlemleri
      if(!existInOpen && !existInClosed && (newDataState == "waiting" || newDataState == "processing")){//hem open hem closed içinde yok ise ve waiting ya da processing geldiyse
        newDataUpdated = true;

        let stoploss = "";
        let takeprofit = "";
        if(newData.stop_loss != undefined){
          stoploss = newData.stop_loss;
        }
        if(newData.take_profit != undefined){
          takeprofit = newData.take_profit;
        }
        stateOpen.push({
          order_id: newData.order_id,
          customer_id: newData.customer_id,
          entry_timestamp: newData.entry_timestamp,
          entry_date: moment(newData.entry_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
          product_id: newData.product_id,
          asking_price: newData.asking_price,
          count: newData.count,
          leverage: newData.leverage,
          type: newData.type,
          state: newData.state,
          stop_loss: stoploss,
          take_profit: takeprofit
        });
      }
      else if(!existInClosed && (newDataState == "approved" || newDataState == "denied")){
        newDataUpdated = true;

        let total = "";
        if(newData.total != undefined){
          total = newData.total;
        }
        stateClosed.push({
          order_id: newData.order_id,
          customer_id: newData.customer_id,
          result_timestamp: newData.result_timestamp,
          result_date: moment(newData.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
          product_id: newData.product_id,
          asking_price: newData.asking_price,
          processing_price: newData.processing_price,
          count: newData.count,
          realized_count: newData.realized_count,
          leverage: newData.leverage,
          type: newData.type,
          state: newData.state,
          total: total
        });
      }

      newStateData["openOrders"] = stateOpen;
      newStateData["closedOrders"] = stateClosed;
      newStateData["updateData"] = newData;
      //console.log(newStateData);
      return newStateData;
    }

    //console.log(futureOrderHistoryData);
    return futureOrderHistoryData;
  },
};

const demoFutureProcessesUtils = {
  init: (data, state) => {
    //console.log("demoFutureProcessesUtils - init");
    //console.log(data);
    const newData = {};

    newData["activeProcesses"] = data.activeProcesses;
    newData["deactiveProcesses"] = data.deactiveProcesses;
    newData["updateData"] = [];

    //console.log(newData);
    return newData;
  },
  update: (data, state) => {
    //console.log("demoFutureProcessesUtils - update ");
    //console.log(data);
    
    const futureProcessesData = state.kprice.demoFutureProcesses.data;

    if(data.onCustomerUpdated.message_type == "UpdateFutureProcessesDemo"){
      const newFutureProcessesData = { ...futureProcessesData };
      const newStateData = {};
      const stateActive = newFutureProcessesData.activeProcesses;
      const stateDeactive = newFutureProcessesData.deactiveProcesses;
      const stateUpdateData = newFutureProcessesData.updateData;
      const newProcess = data.onCustomerUpdated.extras;
      const newData = {};
      let newDataUpdated = false;//state bilgileri güncellenmeli mi
      let newDataState = null;//yeni gelen bilginin state'i
      
      let existInActive = false;//Active içinde var mı
      let existInActiveIndex = null;//Active içinde varsa index'i
      
      let existInDeactive = false;//Deactive içinde var mı
      let existInDeactiveIndex = null;//Deactive içinde varsa index'i

      for (let i = 0; i < newProcess.length; i++) {//yeni gelen process bilgisi temize çekiliyor
        if(newProcess[i].name == "state"){
          newDataState = newProcess[i].value;
        }
        newData[newProcess[i].name] = newProcess[i].value;
        if(newProcess[i].name == "entry_timestamp"){
          newData["entry_date"] = moment(newProcess[i].value).tz(timeZone).format("YYYY-MM-DD HH:mm");
        }
        else if(newProcess[i].name == "result_timestamp"){
          newData["result_date"] = moment(newProcess[i].value).tz(timeZone).format("YYYY-MM-DD HH:mm");
        }
      }

      for (let i = stateActive.length - 1; i >= 0; i--) {//Active içinde var mı
        if (stateActive[i].order_id == newData.order_id) {
          existInActive = true;
          existInActiveIndex = i;
        }
      }

      for (let i = stateDeactive.length - 1; i >= 0; i--) {//Deactive içinde var mı
        if (stateDeactive[i].order_id == newData.order_id) {
          existInDeactive = true;
          existInDeactiveIndex = i;
        }
      }

      //silme işlemleri
      if(existInActive){//Active içinde var ise silme işlemi
        stateActive.splice(existInActiveIndex, 1);
        existInActive = false;
        newDataUpdated = true;
      }
      else if(existInDeactive){//Deactive içinde var ise silme işlemi
        if(newDataState != "active"){//Active gelmediyse siliyoruz (önce deactive gelip ekleniyor ve sonrasında active geliyor durumunu engellemek için)
          stateDeactive.splice(existInDeactiveIndex, 1);
          existInDeactive = false;
          newDataUpdated = true;
        }
      }

      //ekleme işlemleri
      if(!existInActive && !existInDeactive && (newDataState == "active")){//hem Active hem Deactive içinde yok ise ve active geldiyse
        newDataUpdated = true;

        let stoploss = "";
        let takeprofit = "";
        if(newData.stop_loss != undefined){
          stoploss = newData.stop_loss;
        }
        if(newData.take_profit != undefined){
          takeprofit = newData.take_profit;
        }
        stateActive.push({
          order_id: newData.order_id,
          customer_id: newData.customer_id,
          available_count: newData.available_count,
          available_surety: newData.available_surety,
          entry_count: newData.entry_count,
          entry_timestamp: newData.entry_timestamp,
          entry_date: moment(newData.entry_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
          entry_price: newData.entry_price,
          entry_surety: newData.entry_surety,
          future_type: newData.future_type,
          leverage: newData.leverage,
          product_id: newData.product_id,
          state: newData.state,
          stop_loss: stoploss,
          take_profit: takeprofit
        });
      }
      else if(!existInDeactive && newDataState == "deactive"){
        newDataUpdated = true;

        let stoploss = "";
        let takeprofit = "";
        if(newData.stop_loss != undefined){
          stoploss = newData.stop_loss;
        }
        if(newData.take_profit != undefined){
          takeprofit = newData.take_profit;
        }

        stateDeactive.push({
          order_id: newData.order_id,
          customer_id: newData.customer_id,
          available_count: newData.available_count,
          available_surety: newData.available_surety,
          entry_count: newData.entry_count,
          result_timestamp: newData.result_timestamp,
          result_date: moment(newData.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm"),
          entry_price: newData.entry_price,
          entry_surety: newData.entry_surety,
          future_type: newData.future_type,
          leverage: newData.leverage,
          product_id: newData.product_id,
          state: newData.state,
          process_profit: newData.process_profit,
          stop_loss: stoploss,
          take_profit: takeprofit
        });
      }

      newStateData["activeProcesses"] = stateActive;
      newStateData["deactiveProcesses"] = stateDeactive;
      newStateData["updateData"] = newData;
      //console.log(newStateData);
      return newStateData;
    }

    //console.log(futureProcessesData);
    return futureProcessesData;
  },
};

const demoFutureProfilesUtils = {
  init: (data, state) => {
    //console.log("demoFutureProfilesUtils - init");
    //console.log(data);
    return data;
  },
  update: (data, state) => {
    //console.log("demoFutureProfilesUtils - update ");
    //console.log(data);

    const futureProfilesData = state.kprice.demoFutureProfiles.data;

    if(data.onCustomerUpdated.message_type == "UpdateFutureProfilesDemo"){
      const newFutureProfilesData = { ...futureProfilesData };
      const newProfiles = data.onCustomerUpdated.extras;

      for (let i = 0; i < newProfiles.length; i++) {
        newFutureProfilesData[newProfiles[i].name] = newProfiles[i].value;
      }

      //console.log(newFutureProfilesData);
      return newFutureProfilesData;
    }

    //console.log(futureProfilesData);
    return futureProfilesData;
  },
};

const demoFutureActiveCompetitionUtils = {
  init: (data, state) => {
    //console.log("demoFutureActiveCompetitionUtils - init");
    //console.log(data);
    return data;
  },
  update: (data, state) => {
    //console.log("demoFutureActiveCompetitionUtils - update");
    //console.log(data);

    const futureActiveCompetitionData = state.kprice.demoFutureActiveCompetition.data;

    if(data.onCustomerUpdated.message_type == "UpdateFutureActiveCompetitionDemo"){
      const newFutureActiveCompetitionData = { ...futureActiveCompetitionData };
      const newActiveCompetition = data.onCustomerUpdated.extras;

      for (let i = 0; i < newActiveCompetition.length; i++) {
        newFutureActiveCompetitionData[newActiveCompetition[i].name] = newActiveCompetition[i].value;
      }

      //console.log(newFutureActiveCompetitionData);
      return newFutureActiveCompetitionData;
    }

    //console.log(futureActiveCompetitionData);
    return futureActiveCompetitionData;
  },
};

const walletUtils = {
  init: (data, state) => {
    //console.log("walletUtils - init");

    const newData = {};
    data.data.forEach((item) => {
      newData[item.currency] = {
        currency: item.currency,
        count: item.total_count
      };
    });

    //console.log(newData)
    return newData;
  },
  update: (data, state) => {
    //console.log("walletUtils - update ");
    
    const walletData = state.kprice.wallet.data;

    if(data.onCustomerUpdated.message_type == "UpdateWallet"){
      const newWalletData = { ...walletData };

      const newValue = data.onCustomerUpdated.extras;
      let newCurrency = null;
      let newCount = null;
      for (let i = 0; i < newValue.length; i++) {
        if(newValue[i].name == "currency"){
          newCurrency = newValue[i].value;
        }
        else if(newValue[i].name == "total_count"){
          newCount = newValue[i].value;
        }
      }

      if(newWalletData[newCurrency]){
        newWalletData[newCurrency].count = newCount;
      }
      else{
        newWalletData[newCurrency] = {
          currency: newCurrency,
          count: newCount
        };
      }

      //console.log(newWalletData);
      return newWalletData;
    }

    //console.log(walletData);
    return walletData;
  },
};

const withdrawUtils = {
  init: (data, state) => {
    //console.log("withdrawUtils - init");

    const newData = data;

    //console.log(newData)
    return newData;
  }
};

const depositUtils = {
  init: (data, state) => {
    //console.log("depositUtils - init");

    const newData = data;

    //console.log(newData)
    return newData;
  }
};

const withdrawHistoryUtils = {
  init: (data, state) => {
    //console.log("withdrawHistoryUtils - init");

    const newData = data;
    newData.history.forEach(item => {
      if(item.event_timestamp){
        item.event_date = moment(item.event_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }
      if(item.result_timestamp){
        item.result_date = moment(item.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }
    });

    //console.log(newData)
    return newData;
  },
  more: (response, state) => {
    //console.log("withdrawHistoryUtils - more");

    const withdrawHistoryData = state.kprice.withdrawHistory.data;
    const newData = {...withdrawHistoryData};

    let newHistory = response.history;
    newHistory.forEach(item => {
      if(item.event_timestamp){
        item.event_date = moment(item.event_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }
      if(item.result_timestamp){
        item.result_date = moment(item.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }
    });

    newData.history = [...newData.history, ...newHistory];
    newData.last_evaluated_key = response.last_evaluated_key;

    //console.log(newData);
    return newData;
  },
  update: (data, state) => {
    //console.log("withdrawHistoryUtils - update");

    const withdrawHistoryData = state.kprice.withdrawHistory.data;
    
    if(data.onCustomerUpdated.message_type == "WithdrawHistory" && Object.keys(withdrawHistoryData).length > 0){
      const newData = {...withdrawHistoryData};
      
      const { extras } = data.onCustomerUpdated;
      const newHistoryExtras = extras.reduce((acc, current) => {
        acc[current.name] = current.value;
        return acc;
      }, {});

      if(newHistoryExtras.event_timestamp){
        newHistoryExtras.event_timestamp = Number(newHistoryExtras.event_timestamp);
        newHistoryExtras.event_date = moment(newHistoryExtras.event_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }
      if(newHistoryExtras.result_timestamp){
        newHistoryExtras.result_timestamp = Number(newHistoryExtras.result_timestamp);
        newHistoryExtras.result_date = moment(newHistoryExtras.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }

      let found = false;
      for (let i = 0; i < newData.history.length; i++) {
        if (newData.history[i].log_id === newHistoryExtras.log_id) {
          found = true;
          
          if (newHistoryExtras.status == "denied" || newHistoryExtras.status == "approved" || (newHistoryExtras.status == "pending" && newData.history[i].status == "pending")) {
            newData.history[i] = newHistoryExtras;
          }
        }
      }

      if (!found && newHistoryExtras.status == "pending") {
        let canAdd = false;

        if(newData && newData.history){
          if(newData.last_evaluated_key == null || (newData.last_evaluated_key && newData.last_evaluated_key.event_timestamp && Number(newData.last_evaluated_key.event_timestamp) < newHistoryExtras.event_timestamp)){
            canAdd = true;
          }
        }

        if(canAdd){
          newData.history.unshift(newHistoryExtras);
          newData.history.sort((a, b) => b.event_timestamp - a.event_timestamp);
        }
      }

      //console.log(newData);
      return newData;
    }
    
    //console.log(withdrawHistoryData);
    return withdrawHistoryData;
  },
};

const depositHistoryUtils = {
  init: (data, state) => {
    //console.log("depositHistoryUtils - init");

    const newData = data;
    newData.history.forEach(item => {
      if(item.event_timestamp){
        item.event_date = moment(item.event_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }
      if(item.result_timestamp){
        item.result_date = moment(item.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }
    });

    //console.log(newData)
    return newData;
  },
  more: (response, state) => {
    //console.log("depositHistoryUtils - more");

    const depositHistoryData = state.kprice.depositHistory.data;
    const newData = {...depositHistoryData};

    let newHistory = response.history;
    newHistory.forEach(item => {
      if(item.event_timestamp){
        item.event_date = moment(item.event_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }
      if(item.result_timestamp){
        item.result_date = moment(item.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }
    });

    newData.history = [...newData.history, ...newHistory];
    newData.last_evaluated_key = response.last_evaluated_key;

    //console.log(newData);
    return newData;
  },
  update: (data, state) => {
    //console.log("depositHistoryUtils - update");

    const depositHistoryData = state.kprice.depositHistory.data;
    
    if(data.onCustomerUpdated.message_type == "DepositHistory" && Object.keys(depositHistoryData).length > 0){
      const newData = {...depositHistoryData};
      
      const { extras } = data.onCustomerUpdated;
      const newHistoryExtras = extras.reduce((acc, current) => {
        acc[current.name] = current.value;
        return acc;
      }, {});

      if(newHistoryExtras.event_timestamp){
        newHistoryExtras.event_timestamp = Number(newHistoryExtras.event_timestamp);
        newHistoryExtras.event_date = moment(newHistoryExtras.event_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }
      if(newHistoryExtras.result_timestamp){
        newHistoryExtras.result_timestamp = Number(newHistoryExtras.result_timestamp);
        newHistoryExtras.result_date = moment(newHistoryExtras.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }

      let found = false;
      for (let i = 0; i < newData.history.length; i++) {
        if (newData.history[i].log_id === newHistoryExtras.log_id) {
          found = true;
          
          if (newHistoryExtras.status == "denied" || newHistoryExtras.status == "approved" || (newHistoryExtras.status == "pending" && newData.history[i].status == "pending")) {
            newData.history[i] = newHistoryExtras;
          }
        }
      }

      if (!found && newHistoryExtras.status == "pending") {
        let canAdd = false;

        if(newData && newData.history){
          if(newData.last_evaluated_key == null || (newData.last_evaluated_key && newData.last_evaluated_key.event_timestamp && Number(newData.last_evaluated_key.event_timestamp) < newHistoryExtras.event_timestamp)){
            canAdd = true;
          }
        }

        if(canAdd){
          newData.history.unshift(newHistoryExtras);
          newData.history.sort((a, b) => b.event_timestamp - a.event_timestamp);
        }
      }

      //console.log(newData);
      return newData;
    }
    
    //console.log(depositHistoryData);
    return depositHistoryData;
  },
};

const transferHistoryUtils = {
  init: (data, state) => {
    //console.log("transferHistoryUtils - init");

    const newData = data;
    newData.history.forEach(item => {
      if(item.event_timestamp){
        item.event_date = moment(item.event_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }
      if(item.result_timestamp){
        item.result_date = moment(item.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }
    });

    //console.log(newData)
    return newData;
  },
  more: (response, state) => {
    //console.log("transferHistoryUtils - more");

    const transferHistoryData = state.kprice.transferHistory.data;
    const newData = {...transferHistoryData};

    let newHistory = response.history;
    newHistory.forEach(item => {
      if(item.event_timestamp){
        item.event_date = moment(item.event_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }
      if(item.result_timestamp){
        item.result_date = moment(item.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }
    });

    newData.history = [...newData.history, ...newHistory];
    newData.last_evaluated_key = response.last_evaluated_key;

    //console.log(newData);
    return newData;
  },
  update: (data, state) => {
    //console.log("transferHistoryUtils - update");

    const transferHistoryData = state.kprice.transferHistory.data;
    
    if(data.onCustomerUpdated.message_type == "TransferHistory" && Object.keys(transferHistoryData).length > 0){
      const newData = {...transferHistoryData};
      
      const { extras } = data.onCustomerUpdated;
      const newHistoryExtras = extras.reduce((acc, current) => {
        acc[current.name] = current.value;
        return acc;
      }, {});

      if(newHistoryExtras.event_timestamp){
        newHistoryExtras.event_timestamp = Number(newHistoryExtras.event_timestamp);
        newHistoryExtras.event_date = moment(newHistoryExtras.event_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }
      if(newHistoryExtras.result_timestamp){
        newHistoryExtras.result_timestamp = Number(newHistoryExtras.result_timestamp);
        newHistoryExtras.result_date = moment(newHistoryExtras.result_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }

      let found = false;
      for (let i = 0; i < newData.history.length; i++) {
        if (newData.history[i].log_id === newHistoryExtras.log_id) {
          found = true;
          
          if (newHistoryExtras.status == "denied" || newHistoryExtras.status == "approved" || (newHistoryExtras.status == "pending" && newData.history[i].status == "pending")) {
            newData.history[i] = newHistoryExtras;
          }
        }
      }

      if (!found) {
        let canAdd = false;

        if(newData && newData.history){
          if(newData.last_evaluated_key == null || (newData.last_evaluated_key && newData.last_evaluated_key.event_timestamp && Number(newData.last_evaluated_key.event_timestamp) < newHistoryExtras.event_timestamp)){
            canAdd = true;
          }
        }

        if(canAdd){
          newData.history.unshift(newHistoryExtras);
          newData.history.sort((a, b) => b.event_timestamp - a.event_timestamp);
        }
      }

      //console.log(newData);
      return newData;
    }
    
    //console.log(transferHistoryData);
    return transferHistoryData;
  },
};

const favoritesUtils = {
  init: (data, state) => {
    //console.log("favoritesUtils - init");

    const newData = {};
    data.forEach((item) => {
      newData[item.product_id] = {timestamp: item.timestamp};
    });
    
    //console.log(newData);
    return newData;
  },
  updateByApi: (data, state) => {
    //console.log("favoritesUtils - updateByApi");

    const favoritesData = state.kprice.favorites.data;
    const newData = {...favoritesData};
    
    const item = data.newFavorite.item;
    const productId = item.product_id;
    const productState = item.state;

    if (productState === "favorited") {
      newData[productId] = {
        timestamp: item.timestamp
      };
    }
    else if (productState === "not favorited") {
      if (newData.hasOwnProperty(productId)) {
        delete newData[productId];
      }
    }

    //console.log(newData);
    return newData;
  },
};

const futureStatsUtils = {
  init: (data, state) => {
    //console.log("futureStatsUtils - init");
    //console.log(data);

    const newData = data;
    const sortedData = newData.sort((a, b) => a.rank - b.rank);

    //console.log(sortedData);
    return sortedData;
  },
};

const futureDemoStatsUtils = {
  init: (data, state) => {
    //console.log("futureDemoStatsUtils - init");
    //console.log(data);

    const newData = data;
    const sortedData = newData.sort((a, b) => a.rank - b.rank);

    //console.log(sortedData);
    return sortedData;
  },
};

export {
  timestampToDatetime,
  userInfoUtils,
  notificationsUtils,
  productsDataUtils,
  orderHistoryUtils,
  futureOrderHistoryUtils,
  futureProcessesUtils,
  futureProfilesUtils,
  demoFutureOrderHistoryUtils,
  demoFutureProcessesUtils,
  demoFutureProfilesUtils,
  demoFutureActiveCompetitionUtils,
  walletUtils,
  withdrawUtils,
  depositUtils,
  withdrawHistoryUtils,
  depositHistoryUtils,
  transferHistoryUtils,
  favoritesUtils,
  futureStatsUtils,
  futureDemoStatsUtils,
};
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import { onPageFutureDemoStatsOpen } from "../Reducer/kpriceReducer";
import withLoadingData from "../Container/withLoadingData";
import isEqual from "react-fast-compare";
import Loading from "../components/Global/Loading";
import "./page_dashboard.css";
import moment from "moment-timezone";

const FutureDemoRanking = ({ isDemoFutureStatsLoading }) => {

    const dispatch = useDispatch();
    
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const location = useLocation();
    const query = useQuery();
    const navigate = useNavigate();
    
    const stats = useSelector((state) => state.kprice.futureDemoStats.data);
    const [statsData, setStatsData] = useState(null);
    
    const [code, setCode] = useState(query.get("stats_type"));

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const registration_start_timestamp = 1735344000000;//yarışma başlangıç 1735344000000
    const validity_timestamp = 1737331200000;//yarışma bitiş 1737331200000

    const [competitionStartCountdownVisible, setCompetitionStartCountdownVisible] = useState(false);
    const [competitionEndCountdownVisible, setCompetitionEndCountdownVisible] = useState(false);

    const tableColumns = useMemo(() => [//tablo bilgileri
        { accessorKey: 'rank', header: 'Rank' },
        { accessorKey: 'item_customer_id', header: 'Customer ID' },
        { accessorKey: 'total_profit', header: 'Total Profit' },
        { accessorKey: 'total_assets', header: 'Total Assets' },
        { accessorKey: 'award', header: 'Award' },
    ], []);

    useEffect(() => {//stats güncellenince
        if(stats && stats.length > 0){
            let currentStats = stats.slice(0, 10);
            let myData = false;

            currentStats.map((item) => {
                myData = false;

                if(!item.rank){item.rank = "";}
                if(!item.item_customer_id){item.item_customer_id = "";}
                if(!item.total_profit){item.total_profit = "";}
                if(!item.total_assets){item.total_assets = "";}

                if(!String(item.item_customer_id).includes('*')){
                    myData = true;
                }

                item.award = "0 $";
                if(code == "FDC10000"){
                    if(item.rank == 1){
                        item.award = "4000 $";
                    }
                    else if(item.rank == 2){
                        item.award = "1700 $";
                    }
                    else if(item.rank == 3){
                        item.award = "800 $";
                    }
                    else if(item.rank == 4){
                        item.award = "500 $";
                    }
                    else if(item.rank == 5){
                        item.award = "300 $";
                    }
                    else if(item.rank >= 6){
                        item.award = "100 $";
                    }
                }

                if(myData){
                    item.rank = <div className="special-data">{item.rank}</div>;
                    item.item_customer_id = <div className="special-data">{item.item_customer_id}</div>;
                    item.total_profit = <div className="special-data">{item.total_profit}</div>;
                    item.total_assets = <div className="special-data">{item.total_assets}</div>;
                    item.award = <div className="special-data">{item.award}</div>;
                }
            })

            setStatsData(currentStats);
        }
        else{
            setStatsData([]);
        }
    }, [stats, code]);

    useEffect(() => {//link değişirse
        const newCode = query.get("stats_type");
        if(newCode != code){
            setCode(newCode);
        }
    }, [location]);

    useEffect(() => {
        if(code != "" && code != null){
            dispatch(onPageFutureDemoStatsOpen({ code }));
        }
        else{
            navigate(`/future-demo-ranking?stats_type=FDC10000`);
        }
    }, [code]);

    useEffect(() => {
        const currentTime = moment().tz(timeZone);
        
        const registrationStartTime = moment(registration_start_timestamp).tz(timeZone);
        if(currentTime.isBefore(registrationStartTime)){
            setCompetitionStartCountdownVisible(true);
        }
        else{
            setCompetitionStartCountdownVisible(false);
        }
        
        const validityEndTime = moment(validity_timestamp).tz(timeZone);
        if (currentTime.isBefore(validityEndTime)){
            setCompetitionEndCountdownVisible(true);
        }
        else{
            setCompetitionEndCountdownVisible(false);
        }
    }, [timeZone]);

    function calculateTimeLeftForStart() {
        const now = moment().tz(timeZone).valueOf();
        const difference = registration_start_timestamp - now;
    
        if(difference <= 0){
          return { days: "00", hours: "00", minutes: "00", seconds: "00" };
        }
    
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);
    
        return {
            days: String(days).padStart(2, "0"),
            hours: String(hours).padStart(2, "0"),
            minutes: String(minutes).padStart(2, "0"),
            seconds: String(seconds).padStart(2, "0")
        };
    }

    function calculateTimeLeftForEnd() {
        const now = moment().tz(timeZone).valueOf();
        const difference = validity_timestamp - now;
    
        if(difference <= 0){
            return { days: "00", hours: "00", minutes: "00", seconds: "00" };
        }
    
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);
    
        return {
            days: String(days).padStart(2, "0"),
            hours: String(hours).padStart(2, "0"),
            minutes: String(minutes).padStart(2, "0"),
            seconds: String(seconds).padStart(2, "0")
        };
    }

    const [timeLeftForStart, setTimeLeftForStart] = useState(calculateTimeLeftForStart());
    const [timeLeftForEnd, setTimeLeftForEnd] = useState(calculateTimeLeftForEnd());

    useEffect(() => {
        let newTimeLeft = null;
        let newTimeLeftClear = false;
        const timerStart = setInterval(() => {
            newTimeLeft = calculateTimeLeftForStart();
            setTimeLeftForStart(newTimeLeft);
            if(newTimeLeft && newTimeLeft.days == "00" && newTimeLeft.hours == "00" && newTimeLeft.minutes == "00" && newTimeLeft.seconds == "00"){
                newTimeLeftClear = true;
                setCompetitionStartCountdownVisible(false);
            }
        }, 1000);

        if(!competitionStartCountdownVisible || newTimeLeftClear){
            clearInterval(timerStart);
        }
    
        return () => clearInterval(timerStart);
    }, [competitionStartCountdownVisible]);

    useEffect(() => {
        let newTimeLeft = null;
        let newTimeLeftClear = false;
        const timerEnd = setInterval(() => {
            newTimeLeft = calculateTimeLeftForEnd();
            setTimeLeftForEnd(newTimeLeft);
            if(newTimeLeft && newTimeLeft.days == "00" && newTimeLeft.hours == "00" && newTimeLeft.minutes == "00" && newTimeLeft.seconds == "00"){
                newTimeLeftClear = true;
                setCompetitionEndCountdownVisible(false);
            }
        }, 1000);

        if(!competitionEndCountdownVisible || newTimeLeftClear){
            clearInterval(timerEnd);
        }
    
        return () => clearInterval(timerEnd);
    }, [competitionEndCountdownVisible]);
    
    return(
        <div id="main" className="main">
            <div className="dashboard-sections">
                <div className="single-section">
                    {(competitionStartCountdownVisible || competitionEndCountdownVisible) && 
                        <div className="allsections allsections-border section-ranking-countdown">
                            {competitionStartCountdownVisible && 
                                <div className="section-ranking-countdown-inner">
                                    <div className="title">Countdown to the Competition</div>
                                    <div className="countdown-field">
                                        <label>{timeLeftForStart.days}</label>
                                        <label>{timeLeftForStart.hours}</label>
                                        <label>{timeLeftForStart.minutes}</label>
                                        <label>{timeLeftForStart.seconds}</label>
                                    </div>
                                    <div className="countdown-field countdown-field-title">
                                        <label>DAYS</label>
                                        <label>HOURS</label>
                                        <label>MINUTES</label>
                                        <label>SECONDS</label>
                                    </div>
                                </div>
                            }
                            {competitionEndCountdownVisible && !competitionStartCountdownVisible && 
                                <div className="section-ranking-countdown-inner">
                                    <div className="title">Countdown to the End of the Competition</div>
                                    <div className="countdown-field">
                                        <label>{timeLeftForEnd.days}</label>
                                        <label>{timeLeftForEnd.hours}</label>
                                        <label>{timeLeftForEnd.minutes}</label>
                                        <label>{timeLeftForEnd.seconds}</label>
                                    </div>
                                    <div className="countdown-field countdown-field-title">
                                        <label>DAYS</label>
                                        <label>HOURS</label>
                                        <label>MINUTES</label>
                                        <label>SECONDS</label>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    <div className="allsections allsections-border section-ranking">
                        <div id="section-currencystatus" className="section-currencystatus">
                            <div className="note">
                                <i className="fa-solid fa-circle-info"></i>
                                <label>Ranking data is updated every 10 minutes.</label>
                            </div>
                            {(isDemoFutureStatsLoading != false || statsData == null) ? (
                                <div className="currencyStatusLoading"><Loading /></div>
                            ) : (
                                <div className={code == "FDC10000" ? "column4" : "column3"}>
                                    <MaterialReactTable 
                                        columns={tableColumns} 
                                        data={statsData} 
                                        enablePagination={false}
                                        enableRowVirtualization={true}
                                        enableColumnActions={false}
                                        enableSorting={false}
                                        initialState={{
                                            columnVisibility: { total_profit: code == "FDC10000" ? false : true, total_assets: code == "FDC10000" ? true : false, award: code == "FDC10000" ? true : false },
                                        }} 
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    
}

export default withLoadingData()(React.memo(FutureDemoRanking, isEqual));
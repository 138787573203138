import { eventChannel } from 'redux-saga';
import { startLoading, finishLoading } from "../Reducer/loadingReducer";
import { call, put, select, flush, delay, take} from "redux-saga/effects";
import { toast } from 'react-toastify';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

//belirli alanları Number'a dönüştürme fonksiyonları
const adjustData_candleProduct = (data) => {
  if (!data) {
    return data;
  }

  let updatedData = { ...data };

  const productNumberFields = ["current_price", "last_MT", "last_f"];
  if (updatedData.onProductUpdated) {
    productNumberFields.forEach((field) => {
      if (updatedData.onProductUpdated[field] !== null && updatedData.onProductUpdated[field] !== undefined) {
        updatedData.onProductUpdated[field] = Number(updatedData.onProductUpdated[field]);
      }
    });
  }

  if (updatedData.onProductUpdated && Array.isArray(updatedData.onProductUpdated.last_trades)) {
    updatedData.onProductUpdated.last_trades = updatedData.onProductUpdated.last_trades.map((trade) => {
      const tradeNumberFields = ["v", "p", "q", "m", "MT", "f"];
      tradeNumberFields.forEach((field) => {
        if (trade[field] !== null && trade[field] !== undefined) {
          trade[field] = Number(trade[field]);
        }
      });
      return trade;
    });
  }

  return updatedData;
};

const adjustData_miniTicker = (data) => {
  if (!(data && data.onMiniTickerUpdated && Array.isArray(data.onMiniTickerUpdated.last_events))) {
    return data;
  }

  const numberFields = ["c", "h", "l", "o", "q", "v", "last_MT"];

  const adjustedEvents = data.onMiniTickerUpdated.last_events.map((event) => {
    const adjustedEvent = { ...event };
    numberFields.forEach((field) => {
      if (adjustedEvent[field] !== null) {
        adjustedEvent[field] = Number(adjustedEvent[field]);
      }
    });

    return adjustedEvent;
  });

  return {
    ...data,
    onMiniTickerUpdated: {
      ...data.onMiniTickerUpdated,
      last_events: adjustedEvents
    }
  };
};

const adjustData_productForFutureProcesses = (data) => {
  if (!(data && data.onProductUpdated)) {
    return data;
  }

  const numberFields = ["current_price", "last_MT", "last_f"];

  const adjustedProduct = { ...data.onProductUpdated };
  numberFields.forEach((field) => {
    if (adjustedProduct[field] !== null && adjustedProduct[field] !== undefined) {
      adjustedProduct[field] = Number(adjustedProduct[field]);
    }
  });

  return {
    ...data,
    onProductUpdated: adjustedProduct
  };
};

const adjustData_customerUpdated = (data) => {
  if(data.onCustomerUpdated.message_type == "Notification" || data.onCustomerUpdated.message_type == "UpdateWallet" || data.onCustomerUpdated.message_type == "UpdateSpotOrders" || data.onCustomerUpdated.message_type == "UpdateFutureProfiles" || data.onCustomerUpdated.message_type == "UpdateFutureProcesses" || data.onCustomerUpdated.message_type == "UpdateFutureOrders" || data.onCustomerUpdated.message_type == "UpdateFutureProfilesDemo" || data.onCustomerUpdated.message_type == "UpdateFutureProcessesDemo" || data.onCustomerUpdated.message_type == "UpdateFutureOrdersDemo"){
    if (!(data && data.onCustomerUpdated && Array.isArray(data.onCustomerUpdated.extras))) {
      return data;
    }
    
    const numberFields = [
      'available_count', 'available_surety', 'entry_count', 
      'entry_price', 'entry_surety', 'leverage', 'process_profit', 
      'balance', 'total_surety', 'credit', 'credit_deposit', 
      "event_timestamp", "unread_notifications_length", "total_count", 
      "entry_timestamp", "result_timestamp", "asking_price", 
      "count", "processing_price", "realized_count", 
      "currency_fee", "to_currency_fee", "total", 
      "stop_loss", "take_profit"
    ];
  
    const adjustedExtras = data.onCustomerUpdated.extras.map((item) => {
      if (numberFields.includes(item.name) && item.value !== null) {
        return { ...item, value: Number(item.value) };
      }
      return item;
    });
  
    return {
      ...data,
      onCustomerUpdated: {
        ...data.onCustomerUpdated,
        extras: adjustedExtras,
      },
    };
  }
  else{
    return data;
  }
}

//abonelik sorguları
const subscriptions = {
  CONNECT_CANDLE_PRODUCT_UPDATED: {
    query: `
      subscription OnProductUpdated($product_id: String!) {
        onProductUpdated(product_id: $product_id) {
          product_id
          FLAGS
          current_price
          last_MT
          last_f
          last_trades {
            v
            q
            p
            m
            MT
            f
          }
        }
      }
    `,
    variables: (subscription_id) => ({ product_id: subscription_id }),
  },
  CONNECT_CUSTOMER_UPDATED: {
    query: `
      subscription OnCustomerUpdated($customer_id: String!) {
        onCustomerUpdated(customer_id: $customer_id) {
          customer_id
          message_type
          extras {
            name
            value
          }
        }
      }
    `,
    variables: (subscription_id) => ({ customer_id: subscription_id }),
  },
  CONNECT_MINI_TICKER_UPDATED: {
    query: `
      subscription OnMiniTickerUpdated {
        onMiniTickerUpdated {
          last_events {
            c
            h
            l
            last_MT
            o
            product_id
            q
            v
          }
        }
      }
    `,
    variables: () => {},
  },
  CONNECT_PRODUCT_UPDATED_FOR_PROCESSES: {
    query: `
      subscription OnProductUpdated($product_id: String!) {
        onProductUpdated(product_id: $product_id) {
          product_id
          FLAGS
          current_price
          last_MT
          last_f
          last_trades {
            v
            q
            p
            m
            MT
            f
          }
        }
      }
    `,
    variables: (subscription_id) => ({ product_id: subscription_id }),
  }
};

//abonelik sonlandırma fonksiyonlarının ayrıca tutulduğu obje
let unsubscribeFunctions = {
  candle: {},
  customer: null,
  miniTicker: null,
  productForFutureProcesses: {},
  productForDemoFutureProcesses: {}
};

//aboneliklerle ilgili bilgilerin toplandığı alan
const subscriptionConfig = {
  CONNECT_CANDLE_PRODUCT_UPDATED: {
    unsubscribeKey: 'candle',
    multipleSubscriptions: true,
    subscriptionIdFromPayload: (payload) => payload.product_id,
    adjustData: adjustData_candleProduct,
    errorTitle: "graphics"
  },
  CONNECT_CUSTOMER_UPDATED: {
    unsubscribeKey: 'customer',
    multipleSubscriptions: false,
    subscriptionIdFromPayload: (payload) => payload.customer_id,
    adjustData: adjustData_customerUpdated,
    errorTitle: "customer"
  },
  CONNECT_MINI_TICKER_UPDATED: {
    unsubscribeKey: 'miniTicker',
    multipleSubscriptions: false,
    subscriptionIdFromPayload: () => null,
    adjustData: adjustData_miniTicker,
    errorTitle: "mini ticker"
  },
  CONNECT_PRODUCT_UPDATED_FOR_PROCESSES: {
    unsubscribeKey: 'productForFutureProcesses',
    multipleSubscriptions: true,
    subscriptionIdFromPayload: (payload) => payload.product_id,
    adjustData: adjustData_productForFutureProcesses,
    errorTitle: "future processes products"
  },
  CONNECT_PRODUCT_UPDATED_FOR_DEMO_PROCESSES: {
    unsubscribeKey: 'productForDemoFutureProcesses',
    multipleSubscriptions: true,
    subscriptionIdFromPayload: (payload) => payload.product_id,
    adjustData: adjustData_productForFutureProcesses,
    errorTitle: "future demo processes products"
  }
};

//abonelik varsa sonlandırma fonksiyonu
const unsubscribeIfNeeded = (type, productId = null) => {
  const config = subscriptionConfig[type];
  if (!config || !config.unsubscribeKey) return;

  //candle için sadece belirli bir aboneliği sonlandırma
  if (config.unsubscribeKey === 'candle' && productId) {
    const unsubscribeFunction = unsubscribeFunctions.candle[productId];
    if (unsubscribeFunction) {
      //console.log(`${type} için ${productId} aboneliği sonlandırılıyor...`);
      unsubscribeFunction.unsubscribe();
      delete unsubscribeFunctions.candle[productId];
    }
  }
  else if (config.unsubscribeKey === 'productForFutureProcesses' && productId) {
    const unsubscribeFunction = unsubscribeFunctions.productForFutureProcesses[productId];
    if (unsubscribeFunction) {
      //console.log(`${type} için ${productId} aboneliği sonlandırılıyor...`);
      unsubscribeFunction.unsubscribe();
      delete unsubscribeFunctions.productForFutureProcesses[productId];
    }
  }
  else if (config.unsubscribeKey === 'productForDemoFutureProcesses' && productId) {
    const unsubscribeFunction = unsubscribeFunctions.productForDemoFutureProcesses[productId];
    if (unsubscribeFunction) {
      //console.log(`${type} için ${productId} aboneliği sonlandırılıyor...`);
      unsubscribeFunction.unsubscribe();
      delete unsubscribeFunctions.productForDemoFutureProcesses[productId];
    }
  }
  else {//diğer abonelik türleri için genel sonlandırma işlemi
    const unsubscribeFunction = unsubscribeFunctions[config.unsubscribeKey];
    if (config && config.unsubscribeKey && unsubscribeFunction && unsubscribeFunction.unsubscribe) {
      //console.log(`${type} aboneliği sonlandırılıyor...`);
      unsubscribeFunction.unsubscribe();
      unsubscribeFunctions[config.unsubscribeKey] = null;
    }
  }
};

//abonelik başlatma fonksiyonu
const createAppSyncSubscriptionThunk_old = (type, dataMaker) => {
  const SUCCESS = `${type}_SUCCESS`;
  const ERROR = `${type}_ERROR`;

  return (action = {}) => async (dispatch, getState) => {
    const { payload } = action;
    const subscriptionDef = subscriptions[type];
    if (!subscriptionDef) {
      //console.error(`${type} aboneliği tanımlı değil`);
      return;
    }

    const config = subscriptionConfig[type];
    const subscriptionId = config.subscriptionIdFromPayload(payload);
    unsubscribeIfNeeded(type, subscriptionId);

    try {
      const subscription = await API.graphql({
        query: subscriptionDef.query,
        variables: subscriptionDef.variables(subscriptionId),
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      //console.log(`${type} için abonelik başlatılıyor...`);

      const realTimeSubscription = subscription.subscribe({
        next: (eventData) => {
          if (eventData.value && eventData.value.data) {
            let updatedData = eventData.value.data;
            let updatedDataCopy = { ...updatedData };
            
            const adjustDataFunction = subscriptionConfig[type].adjustData;
            if (adjustDataFunction) {
              updatedDataCopy = adjustDataFunction(updatedDataCopy);
            }

            const state = getState();
            dispatch({ type: SUCCESS, payload: dataMaker(updatedDataCopy, state) });
          }
        },
        error: (error) => {
          toast.error(`There was a problem starting the ${subscriptionConfig[type].errorTitle} subscription!`);
          dispatch({ type: ERROR, payload: error });
        },
      });

      //abonelik kaydetme işlemi
      if (config && config.multipleSubscriptions) {
        unsubscribeFunctions[config.unsubscribeKey][subscriptionId] = realTimeSubscription;
      }
      else {
        unsubscribeFunctions[config.unsubscribeKey] = realTimeSubscription;
      }

      return realTimeSubscription;
    } catch (error) {
      toast.error("Something went wrong while loading the values!");
      dispatch({ type: ERROR, payload: error });
    }
  };
};

const createAppSyncSubscriptionThunk = (type, dataMakers) => {
  const SUCCESS = `${type}_SUCCESS`;
  const ERROR = `${type}_ERROR`;

  return (action = {}) => async (dispatch, getState) => {
    const { payload } = action;
    const subscriptionDef = subscriptions[type];
    if (!subscriptionDef) {
      //console.error(`${type} aboneliği tanımlı değil`);
      return;
    }

    const config = subscriptionConfig[type];
    const subscriptionId = config.subscriptionIdFromPayload(payload);
    unsubscribeIfNeeded(type, subscriptionId);

    try {
      const subscription = await API.graphql({
        query: subscriptionDef.query,
        variables: subscriptionDef.variables(subscriptionId),
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      //console.log(`${type} için abonelik başlatılıyor...`);

      const realTimeSubscription = subscription.subscribe({
        next: (eventData) => {
          if (eventData.value && eventData.value.data) {
            let updatedData = eventData.value.data;
            let updatedDataCopy = { ...updatedData };
            const messageType = updatedDataCopy && updatedDataCopy.onCustomerUpdated && updatedDataCopy.onCustomerUpdated.message_type ? updatedDataCopy.onCustomerUpdated.message_type : null;
            
            const adjustDataFunction = subscriptionConfig[type].adjustData;
            if (adjustDataFunction) {
              updatedDataCopy = adjustDataFunction(updatedDataCopy);
            }
  
            const currentState = getState();
            const dataMakersIsArray = Array.isArray(dataMakers);
            
            if(dataMakersIsArray){
              const relevantDataMaker = dataMakers.find(dataMaker => dataMaker.matchingType === messageType);
              if(relevantDataMaker) {
                const updateInfo = relevantDataMaker.dataMaker(updatedDataCopy, currentState);
                dispatch({ type: SUCCESS, payload: updateInfo.payload, key: updateInfo.key });
              }
            }
            else{
              dispatch({ type: SUCCESS, payload: dataMakers(updatedDataCopy, currentState) });
            }
          }
        },
        error: (error) => {
          //console.error(error);
          toast.error(`There was a problem starting the ${subscriptionConfig[type].errorTitle} subscription!`);
          dispatch({ type: ERROR, payload: error });
        },
      });

      //abonelik kaydetme işlemi
      if (config && config.multipleSubscriptions) {
        unsubscribeFunctions[config.unsubscribeKey][subscriptionId] = realTimeSubscription;
      }
      else {
        unsubscribeFunctions[config.unsubscribeKey] = realTimeSubscription;
      }

      return realTimeSubscription;
    } catch (error) {
      //console.error("Something went wrong while loading the values!");
      dispatch({ type: ERROR, payload: error });
    }
  };
};

const createAppSyncSubscriptionThunkIAM = (type, dataMakers) => {
  const SUCCESS = `${type}_SUCCESS`;
  const ERROR = `${type}_ERROR`;

  return (action = {}) => async (dispatch, getState) => {
    const { payload } = action;
    const subscriptionDef = subscriptions[type];
    if (!subscriptionDef) {
      //console.error(`${type} aboneliği tanımlı değil`);
      return;
    }

    const config = subscriptionConfig[type];
    const subscriptionId = config.subscriptionIdFromPayload(payload);
    unsubscribeIfNeeded(type, subscriptionId);

    try {
      const subscription = await API.graphql({
        query: subscriptionDef.query,
        variables: subscriptionDef.variables(subscriptionId),
        authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      //console.log(`${type} için abonelik başlatılıyor...`);

      const realTimeSubscription = subscription.subscribe({
        next: (eventData) => {
          if (eventData.value && eventData.value.data) {
            let updatedData = eventData.value.data;
            let updatedDataCopy = { ...updatedData };
            
            const adjustDataFunction = subscriptionConfig[type].adjustData;
            if (adjustDataFunction) {
              updatedDataCopy = adjustDataFunction(updatedDataCopy);
            }

            const currentState = getState();
            const dataMakersIsArray = Array.isArray(dataMakers);
            
            if(dataMakersIsArray){
              dataMakers.forEach(dataMaker => {
                const updateInfo = dataMaker(updatedDataCopy, currentState);
                dispatch({ type: SUCCESS, payload: updateInfo.payload, key: updateInfo.key });
              });
            }
            else{
              dispatch({ type: SUCCESS, payload: dataMakers(updatedDataCopy, currentState) });
            }
          }
        },
        error: (error) => {
          toast.error(`There was a problem starting the ${subscriptionConfig[type].errorTitle} subscription!`);
          dispatch({ type: ERROR, payload: error });
        },
      });

      //abonelik kaydetme işlemi
      if (config && config.multipleSubscriptions) {
        unsubscribeFunctions[config.unsubscribeKey][subscriptionId] = realTimeSubscription;
      }
      else {
        unsubscribeFunctions[config.unsubscribeKey] = realTimeSubscription;
      }

      return realTimeSubscription;
    } catch (error) {
      toast.error("Something went wrong while loading the values!");
      dispatch({ type: ERROR, payload: error });
    }
  };
};

const createRequestSaga = (type, apiOrData, dataMaker) => {
  const SUCCESS = `${type}_SUCCESS`;
  const ERROR = `${type}_ERROR`;

  //console.log("createRequestSaga");
  //console.log(type);

  return function* (action = {}) {
    yield put(startLoading(type));
    try {
      let data;
      const state = yield select();

      if(apiOrData.isDirectPayload){
        data = apiOrData(action);
      }
      else{
        const res = yield call(apiOrData, action.payload);
        data = res.data;
      }

      yield put({ type: SUCCESS, payload: dataMaker(data, state) });
      yield put(finishLoading(type));
    } catch (e) {
      yield put({ type: ERROR, payload: e });
      yield put(finishLoading(type));
      toast.error("Something went wrong while loading the information!");
      throw e;
    }
  };
};

const createChangeOptionSaga = (type) => {
  const SUCCESS = `${type}_SUCCESS`;

  return function* (action = {}) {
    yield put({ type: SUCCESS, payload: action.payload });
  };
};

const reducerUtils = {
  success: (state, payload, key) => {
    return {
      ...state,
      [key]: {
        data: payload,
        error: false,
      },
    };
  },
  error: (state, error, key) => ({
    ...state,
    [key]: {
      ...state[key],
      error: error,
    },
  }),
};

const requestActions = (type, key) => {
  const [SUCCESS, ERROR] = [`${type}_SUCCESS`, `${type}_ERROR`];
  return (state, action) => {
    switch (action.type) {
      case SUCCESS:
        return reducerUtils.success(state, action.payload, key);
      case ERROR:
        return reducerUtils.error(state, action.payload, key);
      default:
        return state;
    }
  };
};

const requestInitActions = (type, key) => {
  const [SUCCESS, ERROR] = [`${type}_SUCCESS`, `${type}_ERROR`];
  return (state, action) => {
    switch (action.type) {
      case SUCCESS:
        return {
          ...state,
          candleDay: {
            data: action.payload,
            error: false,
          },
          [key]: {
            data: action.payload,
            error: false,
          },
        };
      case ERROR:
        return {
          ...state,
          candleDay: {
            ...state.candleDay,
            error: action.payload,
          },
          [key]: {
            ...state[key],
            error: action.payload,
          },
        };
      default:
        return state;
    }
  };
};

const changeOptionActions = (type, key) => {
  const SUCCESS = `${type}_SUCCESS`;
  return (state, action) => {
    switch (action.type) {
      case SUCCESS:
        return {
          ...state,
          [key]: action.payload,
        };
      default:
        return state;
    }
  };
};

export {
  createRequestSaga,
  createChangeOptionSaga,
  requestActions,
  requestInitActions,
  changeOptionActions,
  createAppSyncSubscriptionThunk,
  createAppSyncSubscriptionThunkIAM,
  unsubscribeFunctions,
  subscriptions,
  subscriptionConfig
};
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import './pages_company.css';
import { AuthContext } from '../components/AuthContext/AuthContext';
import { Link } from 'react-router-dom';
import { onDemoFutureActiveCompetitionUpdate } from "../Reducer/kpriceReducer";
import axios from 'axios';
import { Auth } from 'aws-amplify';
import Decimal from 'decimal.js';
import { toast } from 'react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import Loading from "../components/Global/Loading";

const FutureDemoCompetition10000 = () => {

    const { isAuthenticated } = useContext(AuthContext);
    
    const competitionCode = "FDC10000";

    const [checkTerms, setCheckTerms] = useState(false);//koşulların kabul edildiğinin işaretlenmesi
    const [submitLoading, setSubmitLoading] = useState(false);
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userInfo = useSelector((state) => state.kprice.userInfo.data);
    const futureProcesses = useSelector((state) => state.kprice.demoFutureProcesses.data);
    const futureProfiles = useSelector((state) => state.kprice.demoFutureProfiles.data);
    const demoFutureActiveCompetition = useSelector((state) => state.kprice.demoFutureActiveCompetition.data);

    const [activeFutureProcessesCount, setActiveFutureProcessesCount] = useState(0);
    const [demoFutureCompetitionCode, setDemoFutureCompetitionCode] = useState("");
    const [balance, setBalance] = useState(0);
    const [totalSurety, setTotalSurety] = useState(0);
    const [credit, setCredit] = useState(0);

    useEffect(() => {
        setActiveFutureProcessesCount(futureProcesses && futureProcesses.activeProcesses ? futureProcesses.activeProcesses.length : 0);
    }, [futureProcesses]);

    useEffect(() => {
        setDemoFutureCompetitionCode(demoFutureActiveCompetition && demoFutureActiveCompetition.active_competition ? demoFutureActiveCompetition.active_competition : "");
    }, [demoFutureActiveCompetition]);

    useEffect(() => {
        if(futureProcesses && futureProcesses.activeProcesses && futureProfiles){
            let tempTotalBalance = futureProfiles.balance ? futureProfiles.balance : 0;
            let tempTotalSurety = 0;
            let tempCredit = futureProfiles.credit ? futureProfiles.credit : 0;
            
            futureProcesses.activeProcesses.map((item, index) => {
                tempTotalSurety = new Decimal(tempTotalSurety).plus(new Decimal(item.available_surety));
            });

            setTotalSurety(tempTotalSurety);
            setBalance(tempTotalBalance);
            setCredit(tempCredit);
        }
    }, [futureProcesses, futureProfiles]);

    const joinCompetition = async () => {
        if(activeFutureProcessesCount == 0 && balance == 0 && totalSurety == 0 && credit == 0 && demoFutureCompetitionCode == "" && userInfo && userInfo.future_permission && userInfo.future_permission == "True"){
            setSubmitLoading(true);

            let updateData = {
                update_type: "join_competition",
                competition_code: competitionCode,
            }
            
            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            axios.post('https://api.kpricemarket.com/demo/futuredemo', 
                JSON.stringify(updateData),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {
                if(response.status == "200"){
                    if(response && response.data && response.data.data){
                        if(response.data.data.active_competition){
                            const newActiveCompetition = response.data.data.active_competition;
                            dispatch(onDemoFutureActiveCompetitionUpdate({ newActiveCompetition }));
                        }
                    }
                    navigate('/future-demo');
                    toast.success("You have successfully joined the competition!");
                }
                else{
                    toast.error(`There was a problem join the competition!${response.data.message ? ` (${response.data.message})` : ""}`);
                }
                setSubmitLoading(false);
            })
            .catch(function (error) {
                toast.error(`There was a problem join the competition!${error.response.data.error ? ` (${error.response.data.error})` : ""}`);
                setSubmitLoading(false);
            });
        }
    }
    
    return(
        <div id="main" className="main">
            <div className="allsections allsections-border scrollbarhide pages-company">
                <label className="title">Competition Rules</label>
                <label className="text">No purchase or payment is required to participate or win, this is a contest of skill. No real money will be exchanged.</label>
                <label className="text">The contest starts on December 28, 2024, 00.00 UTC and ends on January 20, 2025, 00.00 UTC.</label>
                <label className="text">Registration for the contest will start on December 28, 2024, 00.00 UTC and will continue until January 15, 2025, 00.00 UTC.</label>
                <label className="text">KYC2 Authentication is required to participate in the contest.</label>
                <label className="text">The user who successfully registers for the contest will receive access to a separate Demo account (Demo trading contest) account. A demo amount of 10,000 USDT will be credited to this account as part of the contest. Within the scope of the contest, only demo trades will be considered as trading activities performed on the contest account. The Contest only allows demo trades. No real money or cryptocurrency will be used for transactions during the Contest.</label>
                <label className="text">The Contest is an individual Profit/Loss (PnL) competition. Users with the highest individual Balance will be eligible for a prize. The ranking can be followed on the competition page.</label>
                <label className="title subtitle">Awards</label>
                <label className="text inner-text"><b>1.</b> 4000 $</label>
                <label className="text inner-text"><b>2.</b> 1700 $</label>
                <label className="text inner-text"><b>3.</b> 800 $</label>
                <label className="text inner-text"><b>4.</b> 500 $</label>
                <label className="text inner-text"><b>5.</b> 300 $</label>
                <label className="text inner-text"><b>6. to 10.</b> 100 $</label>
            </div>
            <div className="allsections allsections-border scrollbarhide pages-company">
                <label className="title one-title">Terms and Conditions of the Competition</label>
                <label className="text"><i className="fa-solid fa-circle"></i>The competition amount provided by Kprice is not a withdrawable amount. The ranking and prizes won on the Contest ranking page do not reflect the final results. The prizes will be credited to the winning accounts within seven (7) days after the close of the contest.The total approximate value of the cash prizes is $7000. The prizes will be distributed as 50% withdrawable USDT and 50% Future trading bonus. Users are required to perform KYC3 authentication in order to claim the contest prizes.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>Kprice reserves the right to disqualify any user who participates using more than one account that engages in any improper, dishonest or abusive activity during the contest.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>All users are required to strictly abide by the Kprice User agreement. Any breach of the Kprice Terms of Use will disqualify the user from using the relevant competition.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>Kprice has the sole discretion to decide and determine whether a user qualifies for any competition and reserves the right to change these Terms and Conditions from time to time.</label>
                {isAuthenticated && <div className="contract">
                    <label>
                        <input type="checkbox" onChange={(e) => setCheckTerms(e.target.checked)} />
                        <span className="checkmark"></span>
                        <span className="text">I have read and agree the competition terms</span>
                    </label>
                </div>}
                {isAuthenticated && <div className="message-field">
                    {userInfo && userInfo.future_permission && userInfo.future_permission == "True" ? 
                        (demoFutureCompetitionCode != "" ? 
                            <label className="danger">You are already in an active competition ({demoFutureCompetitionCode}). Please reset demo account.</label>
                            : 
                            (activeFutureProcessesCount > 0 ? 
                                <label className="danger">Active process detected. Please close all processes.</label>
                                : 
                                ((balance != 0 || totalSurety != 0 || credit != 0) ? 
                                    <label className="danger">Balance, Total Surety and Credit must be zero. Please reset the demo account.</label>
                                    : 
                                    <label className="success">You can join the {competitionCode} competition.</label>
                                )
                            )
                        )
                        : 
                        <label className="danger">You must verify to join the competition!</label>
                    }
                </div>}
                <div className="button">
                    <button className={isAuthenticated ? "join" : "join not-authenticated"} disabled={isAuthenticated && userInfo && userInfo.future_permission && userInfo.future_permission == "True" && (!checkTerms || submitLoading || activeFutureProcessesCount > 0 || balance != 0 || totalSurety != 0 || credit != 0 || demoFutureCompetitionCode != "")} onClick={() => {if(isAuthenticated && userInfo && userInfo.future_permission && userInfo.future_permission == "True" && activeFutureProcessesCount == 0 && balance == 0 && totalSurety == 0 && credit == 0 && demoFutureCompetitionCode == "" && !submitLoading){joinCompetition()}}}>
                        {isAuthenticated ? 
                            (userInfo && userInfo.future_permission && userInfo.future_permission == "True" ? 
                                (submitLoading ? 
                                    <div className="loading"><Loading /></div>
                                    : 
                                    "Join the Competition"
                                )
                                : 
                                <Link to="/verification/steps">Go to Verification</Link>
                            ) 
                            : 
                            <><Link to="/login">Login</Link>or<Link to="/register">Register</Link></>
                        }
                    </button>
                </div>
            </div>
        </div>
    )

}

export default FutureDemoCompetition10000;
import React, { useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext/AuthContext';
import "./page_dashboard.css";

const Footer = ({ page }) => {

    const { isAuthenticated, user, signOut } = useContext(AuthContext);
    const selectedProduct = useSelector((state) => state.kprice.selectedProduct);

    useEffect(() => {
        if(document.getElementById("main") && document.getElementById("footer")){
            document.getElementById("main").style.display = "none";
            document.getElementById("footer").style.display = "none";
        }
        
        setTimeout(() => {
            if(document.getElementById("main") && document.getElementById("footer")){
                document.getElementById("main").style.display = "block";
                document.getElementById("footer").style.display = "flex";
            }
        }, 1);
    }, []);
    
    return(
        <div id="footer" className="footer">
            {page == "Dashboard" && 
                <>
                    <div className="top">
                        <div className="item">
                            <div className="title">
                                About Us
                            </div>
                            <div className="links">
                                <Link to="/about-us">About Us</Link>
                                <Link to="/terms">Terms of Use</Link>
                                <Link to="/privacy">Privacy Policy</Link>
                                <Link to="/risk-warning">Risk Warning</Link>
                                <Link to="/reference-rules">Reference Rules</Link>
                                <Link to="/fee">Fee</Link>
                                <Link to="/campaign50">Campaign</Link>
                                <Link to="/future-demo-competition-10000">2025 Competition</Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">
                                Service
                            </div>
                            <div className="links">
                                <Link to={`/trade?market=${selectedProduct}`}>Trade</Link>
                                {isAuthenticated && <>
                                    <Link to="/future">Future</Link>
                                    <Link to="/future-demo">Future Demo</Link>
                                    <Link to="/deposit">Deposit</Link>
                                    <Link to="/withdraw">Withdraw</Link>
                                </>}
                                <Link to="/support">Support</Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">
                                Actions
                            </div>
                            <div className="links">
                                {isAuthenticated ? <>
                                    <Link to="/verification">Verification</Link>
                                    <Link to="/change-password">Change Password</Link>
                                    <Link to="/profile/reference">Invite</Link>
                                </>
                                :
                                <>
                                    <Link to="/login">Login</Link>
                                    <Link to="/register">Register</Link>
                                </>}
                            </div>
                        </div>
                        <div className="item">
                            <div className="inner-item">
                                <div className="title">
                                    Social
                                </div>
                                <div className="links social">
                                    <a href="https://x.com/kpricemarket?s=11&t=t66tkr41yo2lM7_BmXsE2Q" title="X" target="_blank"><i className="fa-brands fa-x-twitter"></i></a>
                                    <a href="https://www.instagram.com/kpricemarket?igsh=Z3Q1N2tsdWNycjJy" title="Instagram" target="_blank"><i className="fa-brands fa-instagram"></i></a>
                                    <a href="https://youtube.com/@kpriceacademy?si=nZqoF8FgXm7-lT0L" title="YouTube" target="_blank"><i className="fa-brands fa-youtube"></i></a>
                                </div>
                            </div>
                            <div className="inner-item contact">
                                <div className="links">
                                    <a href="/" title="Email" target="_blank"><i className="fa-solid fa-envelope"></i>support@kpricemarket.com</a>
                                    {false && <>
                                        <a href="/" title="Phone" target="_blank"><i className="fa-solid fa-phone"></i>+00 111 222 33 44</a>
                                        <a href="/" title="WhatsApp" target="_blank"><i className="fa-brands fa-whatsapp"></i>+00 111 222 33 44</a>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-border"></div>
                </>
            }
            <div className={`bottom${page != "Dashboard" ? " no-top" : ""}`}>
                KPRICEMARKET © 2024
            </div>
        </div>
    )

}

export default Footer;